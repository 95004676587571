import React, { useState } from 'react';
import isAlpha from 'validator/lib/isAlpha';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import BotService from "../../bot-services/BotService";
import SearchSelect from '../../../../custom-components/search-select/SearchSelect';
import MultiSelect from '../../../../custom-components/multi-select/MultiSelect';
// import MultiEmail from "../../../../custom-components/multi-email/MultiEmail";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import Spinner from "../../../../custom-components/spinner/Spinner";
import SkillService from '../../../skill-module/skill-services/SkillService';
import EmailChip from '../../../../custom-components/email-chip/EmailChip';

import "./BotEditComponent.css";

import { useEffect } from "react";
function BotEdit({ bot, onCloseClicked, getBots, username }) {
    const [botForm, setBotForm] = useState({
        "id": "",
        "botName": "",
        "businessUnit": {},
        "botDescription": "",
        "businessBenefit": "",
        "businessJustification": "",
        "devMembers": [],
        "botSkills": [],
    })
    useEffect(() => {
        setSubmitBtnEnable(false);
        if (validateBotUpdationForm("all", botForm)) {
            setSubmitBtnEnable(true);
        }

    })
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false)
    const [isDataLoading, setDataLoading] = useState(false);
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [isDataLoadingDevMembers, setDataLoadingDevMembers] = useState(false);
    const [devMembersEmails, setDevMembersEmails] = useState([]);
    const closeEditBotPanel = () => {
        console.log("Child Called");
        // calling parent function to do changes
        onCloseClicked();
        getBots();

    }
    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,_'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }
    const hasEmpty = (element) => {
        console.log(element)
        return (
            (element.botName != "" && element.botName.length > 0) &&
            (element.botDescription != "" && element.botDescription.length > 0) &&
            (element.businessBenefit != "" && element.businessBenefit.length > 0) &&
            (element.businessJustification != "" && element.businessJustification.length > 0) &&
            (element.devMembers != "" && element.devMembers.length > 0)
        );
    };

    const validateEmail = async (newEmail) => {
        // console.log(newEmail);
        // setShowValidationErrorMessage(false);
        // setDataLoading(true);
        // setShowMessageBanner(false);
        // let validationResponse = await BotService.validateUsers({
        //     "users": [newEmail]
        // })
        // if (validationResponse.status === 200) {
        //     let data = await validationResponse.json();
        //     if (data["status"] === "success") {
        //         if (data["invalid_users"].indexOf(newEmail) === -1) {
        //             setDataLoading(false);
        //             return true;
        //         }
        //         else {
        //             setDataValidationErrorMessage('Invalid dev member(s) ' + data['invalid_users'].join(', ') + '. Please re-enter and try again. ');
        //         }

        //     }
        //     else {
        //         setDataValidationErrorMessage("Something went wrong while validating the dev members. Please try again later!");
        //     }

        // }
        // else {
        //     setDataValidationErrorMessage("Something went wrong while validating the dev members. Please try again later!");
        // }
        // setShowValidationErrorMessage(true);
        // setDataLoading(false);
        // return false;
        setShowValidationErrorMessage(false);
        setDataLoadingDevMembers(true);
        setShowMessageBanner(false);
        let validationResponse = await BotService.validateUsers({
            "users": [newEmail]
        })
        if (validationResponse.status === 200) {
            let data = await validationResponse.json();
            if (data["status"] === "success") {
                if (data["invalid_users"].indexOf(newEmail) === -1) {
                    setDataLoadingDevMembers(false);
                    return true;
                }
                else {
                    setDataValidationErrorMessage('The specified bot owner(s) ' + data['invalid_users'].join(', ') + '  is invalid. Please re-enter the information and attempt again.');
                }
            }
            else {
                setDataValidationErrorMessage("Something went wrong while validating the bot owners. Please try again later!");
            }
        }
        else {
            setDataValidationErrorMessage("Something went wrong while validating the bot owners. Please try again later!");
        }
        setShowValidationErrorMessage(true);
        setSubmitBtnEnable(false);
        setDataLoadingDevMembers(false);
        return false;

    }


    const updateDevEmails = (emails) => {
        console.log("Emails", emails);
        console.log("Dev memebers", botForm['devMembers']);
        setShowValidationErrorMessage(false);
        setDataLoading(true);
        let dev_list = botForm['devMembers']
        let difference = emails.filter(x => !dev_list.includes(x)) + dev_list.filter(x => !emails.includes(x));
        console.log("difference", difference.length);
        if (difference.length !== 0) {

            inputHandlerChange(emails, "devMembers");
        }
        setDataLoading(false);
    }

    const inputHandlerChange = (e, type) => {
        setShowValidationErrorMessage(false);
        var validataTxt = "";
        let botFormTemp = botForm;
        if (type == "devMembers") {
            botFormTemp = { ...botForm, "devMembers": e };
            setDevMembersEmails(e)
            // console.log(e)
            // validateBotUpdationForm(type)
        }
        else {
            if (type === "botName") {
                var charATT = e.target.value.charAt(e.target.value.length - 1)
                if (e.target.value.length === 1 && (!isNaN(e.target.value) || e.target.value === "_")) {
                    validataTxt = e.target.value.replace(e.target.value, "")
                } else {
                    if (charATT === "_") {
                        // setBotForm({ ...botForm, "botName": e.target.value.toLowerCase() })
                        botFormTemp = { ...botForm, "botName": e.target.value.toLowerCase() }
                    } else {
                        validataTxt = e.target.value.replace(/[^\w\s]/gi, "")
                        botFormTemp = { ...botForm, "botName": validataTxt }
                        // setBotForm({ ...botForm, "botName": validataTxt })
                    }
                }
            }
            else if (type === "botDescription") {
                botFormTemp = { ...botForm, "botDescription": e.target.value }
                // setBotForm({ ...botForm, "botDescription": e.target.value })
            }
            else if (type === "businessBenefit") {
                botFormTemp = { ...botForm, "businessBenefit": e.target.value }
                // setBotForm({ ...botForm, "businessBenefit": e.target.value })
            }
            else {
                botFormTemp = { ...botForm, "businessJustification": e.target.value }
                // setBotForm({ ...botForm, "businessJustification": e.target.value })
            }
        }
        // let newdata = [...botDetails];
        // newdata[0][type] = botForm[type]
        // setBotDetails(newdata)
        // const result = botDetails.some(hasEmpty)

        setBotForm(botFormTemp)
        let empty = hasEmpty(botFormTemp)
        let valid = false;
        if (!empty) {
            valid = validateBotUpdationForm(type, botFormTemp)
        }
        else {
            valid = validateBotUpdationForm("all", botFormTemp)
        }

        const result = empty && valid
        // setShowValidationErrorMessage(!result);
        console.log("Result", result)
        setSubmitBtnEnable(result);
    }
    // const validateBotUpdationForm = (botForm) => {
    //     if (botForm.botName === undefined || botForm.botName.length < 5) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('Bot name can not be empty or less than 5 characters');
    //         return false;

    //     }
    //     if (!isAlpha(botForm.botName.split("@")[0], 'en-US', { "ignore": "_" })) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('Special characters other than _ is not allowed in bot name');
    //         return false;
    //     }
    //     if (botForm.botDescription === undefined || botForm.botDescription.length < 10) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('Bot description can not be empty or less than 10 characters');
    //         return false;

    //     }
    //     if (!validateAlphaNumericString(botForm.botDescription)) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('Special characters other than _ @ . " \' & is not allowed in bot description');
    //         return false;
    //     }
    //     if (botForm.botUsageJustification === undefined || botForm.botUsageJustification.length < 10) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('Plan on using bot field can not be empty');
    //         return false;

    //     }
    //     if (!validateAlphaNumericString(botForm.botUsageJustification)) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('Special characters other than _ @ . " \' & is not allowed');
    //         return false;
    //     }
    //     if (botForm.botBusinessJustification === undefined || botForm.botBusinessJustification.length < 10) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('Business Justification can not be empty or less than 10 characters');
    //         return false;

    //     }
    //     if (!validateAlphaNumericString(botForm.botBusinessJustification)) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('Special characters other than _ @ . " \' & is not allowed in bot justification');
    //         return false;
    //     }
    //     if (botForm.botDevMembers === undefined || botForm.botDevMembers.length < 1) {
    //         setShowMessageBanner(true);
    //         setBannerMessageType('error');
    //         setBannerMessage('It is mandatory to add atleast one dev member to a bot (Ex: dev@cisco.com)');
    //         return false;

    //     }
    //     return true;
    // }

    const validateBotUpdationForm = (key, botFormTemp) => {
        if (key == "botName" || key == "all") {
            if (botFormTemp.botName === undefined || botFormTemp.botName.length < 3) {
                console.log("1")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Bot name should be of minimum 3 characters.');
                return false;

            }

            if (!isAlphanumeric(botFormTemp.botName, 'en-US', { "ignore": "_" })) {
                console.log("2")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in bot name.');
                return false;
            }
        }
        if (key == "botDescription" || key == "all") {
            if (botFormTemp.botDescription === undefined || botFormTemp.botDescription.length < 20) {
                console.log("3")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Bot description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(botFormTemp.botDescription)) {
                console.log("4")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in bot description.');
                return false;
            }
        }

        if (key == "businessBenefit" || key == "all") {
            if (botFormTemp.businessBenefit === undefined || botFormTemp.businessBenefit.length < 10) {
                console.log("5")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('\'What is your intended use for the bot field\' should be greater than 10 characters');
                return false;

            }
            if (!validateAlphaNumericString(botFormTemp.businessBenefit)) {
                console.log("6")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in plan on using bot');
                return false;
            }
        }
        if (key == "businessJustification" || key == "all") {
            if (botFormTemp.businessJustification === undefined || botFormTemp.businessJustification.length < 10) {
                console.log("7")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Bot description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(botFormTemp.businessJustification)) {
                console.log("8")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in bot justification');
                return false;
            }
        }
        if (key == "devMembers" || key == "all") {
            if (botFormTemp.devMembers === undefined || botFormTemp.devMembers.length == 0) {
                console.log("9")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('It is mandatory to add atleast one bot owner to a bot (e.g: dev@cisco.com)');
                return false;
            }
            if (botFormTemp.devMembers.length == 1 && botFormTemp.devMembers[0] == username) {
                console.log("10")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('You need to add a different email apart from your own email.');
                return false;
            }
        }
        return true;
    }
    const updateChanges = () => {
        if (!validateBotUpdationForm('all', botForm)) {
            return;
        }

        setDataLoading(true);
        BotService.updateBot({
            "_id": botForm.id,
            "display_name": botForm.botName,
            "description": botForm.botDescription,
            "business_unit": botForm.businessUnit.value,
            "business_benefit": botForm.businessBenefit,
            "business_justification": botForm.businessJustification,
            "dev_members": botForm.devMembers,
            "skills": botForm.botSkills
        }).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                console.log(res);
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage("Updated data successfully!");
                setShowMessageBanner(true);
                getBots();

            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
            //onCloseClicked();
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to update data! Please try again.");
            setShowMessageBanner(true);
            //onCloseClicked();
        })
        // calling parent function to do changes

    }


    const [skills, setSkills] = useState([])
    useEffect(() => {
        getSkills();
    }, [])
    const getSkills = () => {
        SkillService.listSkills().then(res => res.json()).then(res => {
            if (res) {
                var vals = res.skills.map((data) => {
                    return (
                        { "id": data._id, "name": data.name }
                    )
                })
                setSkills(vals)
            } else {
                console.log("error")
            }

        })
    }
    console.log("skills: ", skills);
    const [bu_options, setBUOptions] = useState([])
    // const bu_options = [
    //     { label: 'IT', value: 'it' },
    //     { label: 'Customer Experience', value: 'cx' },
    //     { label: 'Sales', value: 'sales' },
    //     { label: 'Marketing', value: 'marketing' },
    //     { label: 'Engineering', value: 'eng' },
    // ];

    const [selectedSkills, setSkillsSelected] = useState([]);


    function onSelect(selectedList, selectedItem) {
        console.log(selectedList);
        console.log(selectedItem);
    }

    function onRemove(selectedList, removedItem) {
        console.log(selectedList)
        console.log(removedItem)
    }
    const [layoutType, setLayoutType] = useState(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');

    const handleResize = (() => {
        setLayoutType(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');

    })
    const getBusinessOrg = () => {
        setDataLoading(true);
        BotService.getBusinessOrg().then(res => res.json()).then(res => {
            if (res["status"] == 'success') {
                setBUOptions(res['bu_org'])
                setDataLoading(false);
            }
            else {
                console.log("Failed to retrieve Business Orgs List!");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to retrieve Business Orgs List!");
            setShowMessageBanner(true);
        })
    }

    const clearForm = () => {
        setBotForm({
            "id": "",
            "botName": "",
            "businessUnit": {},
            "botDescription": "",
            "businessBenefit": "",
            "businessJustification": "",
            "devMembers": [],
            "botSkills": [],
        });
        setSubmitBtnEnable(false)
        setShowMessageBanner(false)
    }

    function setBotData(bot) {
        console.log(bot)
        console.log(document.getElementById("bot-name"));
        document.getElementById("bot-name").value = bot["display_name"] + "@webex.bot";
        // setBotForm({
        //     "botName":bot["bot_name"],
        //     "botBU":bot[""],
        //     "botDescription":bot["description"],
        //     "botUsageJustification":bot["business_benefit"],
        //     "botBusinessJustification":bot["business_justification"],
        //     "botDevMembers":bot["dev_members"],
        //     "botSkill":bot["skill"],
        // })
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        getBusinessOrg();

        if (bot !== undefined) {
            setBotForm({
                "id": bot._id,
                "botName": bot.display_name,
                "botDescription": bot.description,
                "businessBenefit": bot.business_benefit,
                "businessJustification": bot.business_justification,
                "devMembers": bot.dev_members,
                "botSkills": bot.skills,
                "businessUnit": { 'label': bot.business_unit, 'value': bot.business_unit }

            })
        }


    }, [bot])


    return <>{bot && <div>

        {
            isDataLoading && (<Spinner></Spinner>)
        }

        <div className="panel" style={{ maxHeight: '78vh', overflowY: 'auto' }}>
            <h5>Edit Bot

                <div className="panel-close-button">
                    <span className="icon-close" onClick={() => { closeEditBotPanel(); clearForm(); }} style={{ marginTop: '7px', marginRight: '5px' }}></span>
                </div>
            </h5>


            <hr />
            {
                showMessageBanner && <MessageBanner className="base-margin-top base-margin-bottom" message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
            }
            {
                showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
            }

            <div style={{ maxHeight: '58vh', overflowY: 'auto', overflowX: 'hidden' }}>

                <form>
                    <div className={(layoutType === "horizontal" ? "base-padding" : "") + 'center'}>
                        {
                            layoutType === "horizontal" ? (
                                <div className="row" >
                                    <div className="col">
                                        <div className="form-group base-margin">
                                            <div className="form-group__text">
                                                <input id="bot-name" type="text" autoFocus name="bot_name" value={botForm.botName} onChange={(e) => { inputHandlerChange(e, "botName") }} />
                                                <label htmlFor="input-type-number">Bot Username <span className='required'>*</span></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group dropdown base-margin">
                                            <div className="form-group">
                                                <label htmlFor="select-type-basic">Business Unit / Org </label>
                                                <SearchSelect
                                                    defaultValue={botForm.businessUnit}
                                                    value={botForm.businessUnit}
                                                    options={bu_options}
                                                    onValueChange={(data) => { setBotForm({ ...botForm, "businessUnit": data }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="row base-margin">
                                        <div className="col form-group">
                                            <div className="form-group__text">
                                                <input id="bot-name" type="text" autoFocus name="bot_name" value={botForm.botName} onChange={(e) => { inputHandlerChange(e, "botName") }} />
                                                <label htmlFor="input-type-number">Bot Username <span className='required'>*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row base-margin">
                                        <div className="col form-group dropdown">
                                            <div className="form-group">
                                                <label htmlFor="select-type-basic">Business Unit / Org </label>
                                                <SearchSelect
                                                    defaultValue={botForm.businessUnit}
                                                    value={botForm.businessUnit}
                                                    options={bu_options}
                                                    onValueChange={(data) => { setBotForm({ ...botForm, "businessUnit": data }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        }


                        <div className="row base-margin">
                            <div className="form-group col-12">
                                <div className="form-group__text">
                                    <textarea id="textarea-state-default" rows="6" name="bot_description" value={botForm.botDescription} onChange={(e) => { inputHandlerChange(e, "botDescription") }}></textarea>
                                    <label htmlFor="input-type-number">Bot Description <span className='required'>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="row base-margin">
                            <div className="form-group base-margin-bottom col-12">
                                <div className="form-group__text">
                                    <textarea id="textarea-state-default" rows="3" name="plan_on_use" value={botForm.businessBenefit} onChange={(e) => { inputHandlerChange(e, "businessBenefit") }}></textarea>
                                    <label htmlFor="input-type-number">What is your intended use for the bot? <span className='required'>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="row base-margin">
                            <div className="form-group base-margin-bottom col-12">
                                <div className="form-group__text">
                                    <textarea id="textarea-state-default" rows="3" name="business_justification" value={botForm.businessJustification} onChange={(e) => { inputHandlerChange(e, "businessJustification") }}></textarea>
                                    <label htmlFor="input-type-number">Business Justification <span className='required'>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="base-margin">
                            <label htmlFor="input-type-number">Bot Owners <span className='required'>* </span><b class="info">  (e.g: xyz@cisco.com)</b>&nbsp;
                                <span data-balloon-length="medium" data-balloon="Minimum two (or more) users should be configured as Bot Owners. Bot Owners would have access to the Bot and the associated Skills." data-balloon-pos="down"><span class="icon-info-outline"></span></span>
                                {isDataLoadingDevMembers && <div class="loading-dots" aria-label="Loading, please wait...">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>}</label>
                            <EmailChip email_list={botForm.devMembers} placeholder={"Please enter your Cisco Email ID and hit enter"} onVerifyEmail={validateEmail} onDataChanged={(emails) => { updateDevEmails(emails) }} />

                            {/* <label htmlFor="input-type-number">Dev Members <span className='required'>*</span></label>
                                    <EmailChip email_list={botForm.devMembers} placeholder="Please enter Email ID and hit enter" onDataChanged={(data) => { updateDevEmails(data) }} onVerifyEmail={validateEmail} /> */}
                        </div>
                        <div className="base-margin">
                            <div style={{ display: skills.length == 0 ? 'inherit' : "none" }} className="alert alert--info">
                                <span>It seems like you don't have access to/created any skill.</span>
                                <br />
                            </div>
                            <div style={{ display: skills.length == 0 ? 'inherit' : "none" }} className="alert alert--info">
                                <span>Select either Skills from the side bar menu to create a new skill or select <b>Skill library</b> to view/reuse from an existing list of skills.</span>
                            </div>
                            <label htmlFor="input-type-number">Skills</label>
                            <MultiSelect
                                option={skills}
                                placeholder="Select Skills"
                                selectedValues={botForm.botSkills}
                                onValueChange={(data) => { setBotForm({ ...botForm, "botSkills": data }) }}
                            />
                        </div>
                        <div className="base-margin">
                            <label className="switch">
                                <input type="checkbox" />
                                <span className='switch__label'>Enable Translation</span>
                                <span className="switch__input"></span>
                            </label>
                        </div>
                    </div>
                </form>
            </div>
            <hr className='base-margin' />
            <div >
                <button className="btn btn--secondary" onClick={() => updateChanges()} disabled={!submitBtnEnable} style={{ float: 'right', marginRight: '22px' }}>
                    Update
                </button>
            </div>
        </div>

    </div >

    }
        {bot == undefined && <div>Not defined</div>

        }
    </>

}
export default BotEdit;