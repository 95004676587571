import '../dialog-view-component/DialogViewComponent.css';
import CheckUncheck from '../DialogScreen/Nodes/ActionNode/Checklist';
import GlobalConditionNode from '../DialogScreen/Nodes/ConditionNode/ConditionStructure';
import Joyride, { STATUS } from 'react-joyride';
import { v4 as uuid } from 'uuid'
import React from 'react';
import { useCallback, useRef, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import Spinner from '../../../../custom-components/spinner/Spinner';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import ReactFlow, {
    addEdge,
    Background,
    Controls,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useReactFlow,
    useViewport,
    MiniMap,
    getIncomers,
    getOutgoers,
    getConnectedEdges,
    defaultViewport,
    applyEdgeChanges, applyNodeChanges,
    MarkerType
} from 'react-flow-renderer';

import ResponseNode from '../DialogScreen/Nodes/ResponseNode/ResponseNode'
import ActionNode from '../DialogScreen/Nodes/ActionNode/ActionNode';
import ClearContextNode from '../DialogScreen/Nodes/ClearContextNode/ClearContextNode';
import ConditionNode from '../DialogScreen/Nodes/ConditionNode/ConditionNode';
// import DragActionsFrom from '../DialogScreen/DragBars/DragActionsFrom/DragActionsFrom';
import DragNodesFrom from '../DialogScreen/DragBars/DragNodesFrom/DragNodesFrom';
// import DragSlotsFrom from '../DialogScreen/DragBars/DragSlotsFrom/DragSlotsFrom';
// import DragResponseFrom from '../DialogScreen/DragBars/DragResponseFrom/DragResponseFrom';
import JumpToNode from '../DialogScreen/Nodes/JumpToNode/JumpToNode';
import RuleNode from '../DialogScreen/Nodes/RuleNode/RuleNode';
import Wrapper from '../../../../common-components/wrapper/Wrapper';
import SplitScreenWrapper from '../../../../custom-components/split-screen-wrapper/SplitScreenWrapper';
import RuleNodeDetails from '../DialogScreen/Nodes/RuleNode/RuleNodeDetails';
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import ActionNodeDetails from '../DialogScreen/Nodes/ActionNode/ActionNodeDetails';
import SearchSelect from '../../../../custom-components/search-select/SearchSelect'
import JumpToNodeDetails from '../DialogScreen/Nodes/JumpToNode/JumpToNodeDetails';
import ClearContextNodeDetails from '../DialogScreen/Nodes/ClearContextNode/ClearContextNodeDetails';
import ResponseNodeDetails from '../DialogScreen/Nodes/ResponseNode/ResponseNodeDetails';
import ConditionNodeDetails from '../DialogScreen/Nodes/ConditionNode/ConditionNodeDetails';
import SkillService from '../../../skill-module/skill-services/SkillService';
import IntentService from '../../../intent-module/intent-service/IntentService';
import Edges from '../DialogScreen/Edges/Edges';
import EntityService from '../../../entity-module/entity-services/EntityService';
import DialogService from '../../dialog-service/DialogService';

// let initialId = 0;
const getId = () => uuid();
let expressionId = 0
const getExpressionId = () => expressionId++
let nodeType = '';
let nodeTargetType = '';
let eventNode;
let noOfNodes = 0;
let rules = [];
const nodeTypes = { RuleNode, ActionNode, ConditionNode, JumpToNode, ClearContextNode, ResponseNode };
const edgeType = { Edges }
export let deletedNodes = []
export const setDeletedNodes = (data) => {
    deletedNodes = [...deletedNodes, data]
}

const DialogView = () => {
    console.log(deletedNodes)
    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);
    // const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [nodesCopy, setNodesCopy] = useState([])
    // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [edgesCopy, setEdgesCopy] = useState([])
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    // const [nodesValidation, setNodesValidation] = useState(false)
    const [showSlots, setShowSlots] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [showRuleModal, setShowRuleModal] = useState(false);
    const [showActionModal, setShowActionModal] = useState(false)
    const [showClearContextModal, setShowClearContextModal] = useState(false)
    const [showConditionModal, setShowConditionModal] = useState(false)
    const [showJumpToModal, setShowJumpToModal] = useState(false)
    const [showResponseModal, setShowResponseModal] = useState(false)
    const [currentNode, setCurrentNode] = useState();
    const [newNodeInfo, setNewNodeInfo] = useState({})
    const [expression, setExpression] = useState('')
    const [custom, setCustom] = useState('')
    const [expressions, setExpressions] = useState([])
    const [checkedAll, setCheckedAll] = useState(false)
    const [showNodeTarget, setNodeTarget] = useState(false)
    const [showWarning, setWarningModal] = useState(false)
    const [showWarningReload, setWarningReloadModal] = useState(false)
    const [showRuleWarning, setRuleWarningModal] = useState(false)
    const [message, setMessages] = useState('')
    const [skill, setSkill] = useState({ label: 'Select a Skill', value: 'default' })
    const [rule, setRule] = useState({ label: 'Select a Rule', value: 'default' })
    const [showCreateSkill, setShowCreateSkill] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [skills, setSkills] = useState([])
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [showGuidance, setShowGuidance] = useState(false)
    const [isDataLoading, setDataLoading] = useState(true);
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showExpressionValidationErrorMessage, setShowExpressionValidationErrorMessage] = useState(false);
    const [dataExpressionValidationErrorMessage, setDataExpressionValidationErrorMessage] = useState('');
    const [intentList, setIntents] = useState([])
    const [entityList, setEntities] = useState([])
    const [ruleAction, setRuleAction] = useState([{ name: "", description: "" }]);
    const [actionData, setActionData] = useState([{ name: "", description: "" }]);
    const [skillAction, setSkillAction] = useState([{ name: "", description: "" }]);
    const [skillSubmitBtnEnable, setSkillSubmitBtnEnable] = useState(false)
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false)
    // const [rules, setRules] = useState([]);
    const setRules = (data) => {
        rules = data
    }
    const [actions, setActions] = useState([]);
    const [showExitPrompt, setShowExitPrompt] = useState(false);
    const [entityAndContextVariableList, setEntityAndContextVariableList] = useState([])
    const defaultViewport = useRef({ x: 0, y: 0, zoom: 1 })
    const [closeSplitScreen, setCloseSplitScreen] = useState(false)
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const onNodesChange = useCallback((changes) => {
        setNodes((nds) => applyNodeChanges(changes, nds))
        console.log("changes", changes)
        console.log("rule id ", rules.findIndex(data => data.value === changes[0].id))
        let index = rules.findIndex(data => data.value === changes[0].id)
        console.log("rule id ", index)
        console.log("rules", rules)
        if (changes[0].type == 'remove' && index != -1) {
            console.log('rule is detected')
            getRuleList({ label: localStorage.getItem('skill_name'), value: localStorage.getItem('skill_value') })
        }
    }, []);
    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h6>Welcome to the <b>Dialog Screen</b>. Here you can easily create your conversational dialog flow.</h6>,
                locale: { skip: <span aria-label="skip">Skip</span> },
                placement: 'center',
                target: '.pageTour1',
            },
            {
                content: <h6>Here, you can select from a list of skills and rules to view the dialog flow. We can also create a new skill by entering the skill name and description.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour2',
            }
        ]
    });

    console.log(nodes)
    console.log(edges)
    useEffect(() => {
        getSkills(1);
    }, [])

    useEffect(() => {
        window.addEventListener("beforeunload", (event) => {
            if (window.location.pathname == '/dialog') {
                localStorage.removeItem('rule_name');
                localStorage.removeItem('rule_value');
                event.preventDefault();
                event.returnValue = "";
                setWarningReloadModal(true);
                setMessages("This event will loss your changes. Make sure you save changes before you route/close the tab.")
                return '';
            }
            if (showExitPrompt && window.location.pathname == '/dialog') {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                    e.returnValue = ''
                }
                return '';
            }
        });


    }, []);
    useEffect(() => { onLoad() })
    const onLoad = () => { setTimeout(() => reactFlowInstance.fitView(), 0) }

    const getSkills = (index) => {
        SkillService.listSkills().then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                if (res.skills.length > 0) {
                    var vals = res.skills.map((data) => {
                        return (
                            { "label": data.name, "value": data._id }
                        )
                    })
                    vals.unshift({ label: 'Create Skill', value: 'newSkill' })
                    setSkills(vals)
                    if (localStorage.getItem("skill_name") && localStorage.getItem("skill_value")) {
                        setSkill({ label: localStorage.getItem("skill_name"), value: localStorage.getItem("skill_value") })
                        getRuleList({ label: localStorage.getItem('skill_name'), value: localStorage.getItem('skill_value') })
                        getIntentList({ label: localStorage.getItem("skill_name"), value: localStorage.getItem("skill_value") })
                        getEntityList({ label: localStorage.getItem("skill_name"), value: localStorage.getItem("skill_value") })
                        getActions({ label: localStorage.getItem("skill_name"), value: localStorage.getItem("skill_value") })
                        getEntitiesAndContextVariables({ label: localStorage.getItem("skill_name"), value: localStorage.getItem("skill_value") })
                        localStorage.removeItem('intent_name')
                        localStorage.removeItem('intent_value')
                    }
                    else {
                        setSkill(vals[index])
                        getRuleList(vals[index])
                        getIntentList(vals[index])
                        getEntityList(vals[index])
                        getActions(vals[index])
                        getEntitiesAndContextVariables(vals[index])
                        localStorage.removeItem('intent_name')
                        localStorage.removeItem('intent_value')
                    }


                    setShowGuidance(false);
                    setDataLoading(false);


                }
                else {
                    var vals = [{ label: 'Create Skill', value: 'newSkill' }]
                    setSkills(vals)
                    setSkill({ label: 'Select a Skill', value: 'default' })
                    localStorage.removeItem('intent_name')
                    localStorage.removeItem('intent_value')
                    setDataLoading(false);
                    setShowGuidance(true);
                }
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve Skills!");
            setShowMessageBanner(true);
        })
    }
    const getEntitiesAndContextVariables = (skill) => {
        setDataLoading(true);
        DialogService.getEntitiesAndContextVariables(skill.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                console.log(res['entities'])
                console.log(res['context_variables'])
                console.log(res['context_variables'].concat(res['entities']))
                let temp = res['context_variables'].concat(res['entities'])
                let final_list = []
                for (let i of temp) {
                    final_list.push({
                        id: final_list.length,
                        name: i,
                        isChecked: false
                    })
                }
                console.log("final_list", final_list)
                setEntityAndContextVariableList(final_list)
                setDataLoading(false);
            }
            else {

                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to retrieve Entities and Context Variables!");
            setShowMessageBanner(true);
        })
    }

    const skillInputHandlerChange = (event, type) => {
        setShowValidationErrorMessage(false);
        let newdata = skillAction[0];
        newdata[type] = event.target.value
        setSkillAction([newdata])
        const empty = hasEmpty(newdata);
        let valid = validateSkillCreationForm(type, newdata)

        const result = empty && valid

        setSkillSubmitBtnEnable(result);
    }
    const getActions = (skill) => {
        setDataLoading(true);
        DialogService.getActions(skill.value).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                let temp = []
                for (let a of res['actions']) {
                    temp.push({ label: a.name, value: a.id })
                }
                console.log(temp)
                setActions(temp)
                setDataLoading(false);
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage('Something went wrong. Please try again later!');
            setShowMessageBanner(true);
        })
    }
    const hasEmpty = (element) => {
        return (
            (element.name != "" && element.name.length > 1) &&
            (element.description != "" && element.description.length > 19)
        );
    };
    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }
    const validateSkillCreationForm = (key, skillData) => {
        if (key == "name" || key == "all") {
            if (skillData.name === '' || skillData.name.length < 3) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Skill name should be of minimum 3 characters.');
                return false;
            }

            if (!isAlphanumeric(skillData.name, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in skill name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (skillData.description === '' || skillData.description.length < 20) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Skill description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(skillData.description)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in skill description.');
                return false;
            }
        }
        return true;
    }


    const skillCreation = () => {
        return <Modal size="md" centered show={showCreateSkill} onHide={() => { setShowCreateSkill(false) }} animation={false} >
            <Modal.Header >
                <h5>Create a Skill</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowCreateSkill(false); setShowValidationErrorMessage(false); }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }
                <form >
                    <div className='center base-padding'>
                        <div className='warningMessage txtAlignRight'>Please fill the (*) mandatory fields</div>
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                <input id="input-type-number" type="text" autoFocus name="skill_name" onChange={event => { skillInputHandlerChange(event, "name") }} />
                                <label htmlFor="input-type-number">Skill Name <span className='required'>*</span></label>
                            </div>
                        </div>
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                <textarea id="textarea-state-default" rows={6} name="skill_description" onChange={event => { skillInputHandlerChange(event, "description") }}></textarea>
                                <label className='positionRelative' htmlFor="input-type-number">Skill Description <span className='required'>*</span></label>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary" onClick={() => { setShowCreateSkill(false); setShowValidationErrorMessage(false); }}>
                    Close
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" disabled={!skillSubmitBtnEnable} onClick={() => { setShowCreateSkill(false); addSkill(); }}>
                    Create
                    {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>
    }

    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setState({ run: false });
        }
    };

    const inputHandlerChange = (event, type) => {
        setShowValidationErrorMessage(false);
        let newdata = ruleAction[0];
        newdata[type] = event.target.value
        setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy[type] = event.target.value; return objCopy })
        const empty = hasEmpty(newdata);
        let valid = validateRuleCreationForm(type, newdata)

        const result = empty && valid

        setSubmitBtnEnable(result);
    }
    const inputHandlerNameChange = (event) => {
        setShowValidationErrorMessage(false);

        let temp = event.target.value
        const validName = () => {
            if (temp === '' || temp.length < 5) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Name should be greater than 5 characters.');
                return false;
            }

            if (!isAlphanumeric(temp, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in name.');
                return false;
            }
        }

        let valid = validName();
        console.log('temp', temp)
        setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = temp; return objCopy })
        const empty = temp != '' && temp.length > 1;
        const result = empty && valid
        console.log(result)
        setSubmitBtnEnable(result);
    }

    const validateRuleCreationForm = (key, ruleData) => {
        if (key == "name" || key == "all") {
            if (ruleData.name === '' || ruleData.name.length < 5) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Rule name should be greater than 5 characters.');
                return false;
            }

            if (!isAlphanumeric(ruleData.name, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in rule name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (ruleData.description === '' || ruleData.description.length < 20) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Rule description should be greater than 20 characters.');
                return false;
            }
            if (!validateAlphaNumericString(ruleData.description)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in rule description.');
                return false;
            }
        }
        return true;
    }

    const inputHandlerActionChange = (event, type) => {
        setShowValidationErrorMessage(false);
        let newdata = actionData[0];
        newdata[type] = event.target.value
        setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy[type] = event.target.value; return objCopy })
        const empty = hasEmpty(newdata);
        let valid = validateActionCreationForm(type, newdata)

        const result = empty && valid

        setSubmitBtnEnable(result);
    }
    const validateActionCreationForm = (key, actionData) => {
        if (key == "name" || key == "all") {
            if (actionData.name === '' || actionData.name.length < 5) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Action name should be greater than 5 characters.');
                return false;
            }

            if (!isAlphanumeric(actionData.name, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in action name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (actionData.description === '' || actionData.description.length < 20) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Action description should be greater than 20 characters.');
                return false;
            }
            if (!validateAlphaNumericString(actionData.description)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in action description.');
                return false;
            }
        }
        return true;
    }

    const setNodeType = (type) => {
        nodeType = type;
    }
    // const setNodeTargetType = (type) => {
    //     nodeTargetType = type;
    // }

    const onConnect = useCallback(
        (params) =>
            setEdges(eds => addEdge({
                ...params, markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 15,
                    height: 15,
                    color: 'grey',
                }, label: params.sourceHandle == 'conditionSourceHandle' ? 'True' : params.sourceHandle == 'conditionSourceHandleFalse' ? 'False' : ''
            }, eds)), []
    );

    const onConnectStart = useCallback((_, { nodeId, handleId }) => {
        connectingNodeId.current = nodeId;
        if (handleId == 'ruleHandle') {
            setNodeType('rule')
        }
        else if (handleId == 'actionSourceHandle') {
            setNodeType('action')
        }
        else if (handleId == 'conditionSourceHandle') {
            console.log('inside')
            setNodeType('conditionT')
        }
        else if (handleId == 'conditionSourceHandleFalse') {
            setNodeType('conditionF')
        }
        else if (handleId == 'clearContextSourceHandle') {
            setNodeType('clear')
        }
        else {
            setNodeType('response')
        }
    }, []);

    const onConnectEnd = useCallback((event) => {
        const targetIsPane = event.target.classList.contains('react-flow__pane');
        console.log("event", event)
        eventNode = event
        if (targetIsPane) {
            setNodeTarget(true);
            // positionX = event.layerX;
            // positionY = event.layerY;
            // console.log(positionX)
            // console.log(positionY)
        }
    }, []);

    // const deleteNode = (id) => {
    //     const connectedEdges = getConnectedEdges([node], edges);
    //     const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
    //     let targetId = ''
    //     let getedges = {}

    //     for (let i of connectedEdges) {
    //         if (i.target == id) {
    //             getedges = i;
    //         }
    //         if (i.source == id) {
    //             targetId = i['target'];
    //         }
    //     }
    //     getedges['target'] = targetId



    //     const createdEdges = [getedges]
    //     const finalList = [...remainingEdges, ...createdEdges];
    //     if (finalList.length == 1 && finalList[0].target == '') {
    //         setEdges([])
    //     }

    //     setEdges(finalList);
    // }
    const onNodesDelete = useCallback(
        (deleted) => {
            deletedNodes = [...deletedNodes, ...deleted]
            setEdges(
                deleted.reduce((acc, node) => {
                    console.log("acc", acc)
                    console.log("node", node.id)
                    // const incomers = getIncomers(node, nodes, edges);
                    // const outgoers = getOutgoers(node, nodes, edges);
                    const connectedEdges = getConnectedEdges([node], edges);
                    // console.log("incomers", incomers)
                    // console.log(outgoers)
                    console.log(connectedEdges)
                    const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
                    // console.log(remainingEdges)

                    let targetId = ''
                    let getedges = {}

                    for (let i of connectedEdges) {
                        if (i.target == node.id) {
                            getedges = i;
                        }
                        if (i.source == node.id) {
                            targetId = i['target'];
                        }
                    }
                    getedges['target'] = targetId


                    // for (let i of remainingEdges) {
                    //     if (connectedEdges)
                    // }
                    // const createdEdges = incomers.flatMap(({ id: source }) =>
                    //     outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
                    // );
                    const createdEdges = [getedges]
                    const finalList = [...remainingEdges, ...createdEdges];
                    if (finalList.length == 1 && finalList[0].target == '') {
                        return []
                    }

                    return finalList;
                }, edges)
            );
        },
        [nodes, edges]
    );
    // const deleteEdges = (id) => {
    //     let connectedEdges = []
    //     for (let i of edges) {
    //         if (i.target == id || i.source == id) {
    //             connectedEdges.push(i)
    //         }
    //     }

    //     const remainingEdges = edges.filter((edge) => !connectedEdges.includes(edge));
    //     const remainingNodes = nodes.filter((node) => node.id != id)

    //     let targetId = ''
    //     let getedges = {}

    //     for (let i of connectedEdges) {
    //         if (i.target == id) {
    //             getedges = i;
    //         }
    //         if (i.source == id) {
    //             targetId = i['target'];
    //         }
    //     }
    //     getedges['target'] = targetId



    //     const createdEdges = [getedges]
    //     const finalList = [...remainingEdges, ...createdEdges];
    //     setEdges(finalList)
    //     setNodes(remainingNodes)
    // }

    // const DeleteNodes = (skillId, ruleId, nodeId) => {
    //     setDataLoading(true);
    //     DialogService.deleteNodeById(skillId.value, ruleId.value, nodeId).then(res => res.json()).then(res => {
    //         if (res.status == 'success') {
    //             setBannerMessage(res['details']);
    //             setDataLoading(false);
    //             if (nodeId == ruleId) {
    //                 setEdges([])
    //                 setNodes([])
    //             }
    //             else {
    //                 deleteEdges(nodeId)
    //             }
    //         }
    //         else {
    //             setDataLoading(false);
    //             setBannerMessageType('error');
    //             setBannerMessage(res['details']);
    //             setShowMessageBanner(true);
    //         }
    //     }).catch(err => {
    //         setDataLoading(false);
    //         setBannerMessageType('error');
    //         setBannerMessage('Something went wrong. Please try again later!');
    //         setShowMessageBanner(true);
    //     })
    // }

    const createNode = (event) => {
        const id = getId();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

        const positionValue = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left - 102,
            y: event.clientY - reactFlowBounds.top
        });

        if (nodeType == 'conditionT') {
            if (nodeTargetType == 'action') {
                console.log()
                const newNode = {
                    id,
                    type: "ActionNode",
                    position: positionValue,
                    data: {
                        // makeAPI: function () { this.augment = { type: 'API', method: 'GET', URL: '', headers: {}, payload: {}, script: '', auth_type: '', client_id: '', client_secret: '', oauth_url: '', grant_type: '' } },
                        // makeDB: function () { this.augment = { type: 'DB', db: null, query: '', script: '' } },
                        // makeLinkTo: function () { this.augment = { type: 'LinkTo', actionName: '' } },
                        // modifyAugment: function (key, value) { this.augment[key] = value },
                        name: '',
                        description: '',
                        // description: `Node ${id}`,
                        // modifyAction: function (key, value) { this.action[key] = value },
                        node: { setSelected: function () { setCurrentNode(newNode) } },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value,
                        rule: rule.value,
                        showConfirmation: false
                        // edges: {},
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));

                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'True', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'condition') {
                const newNode = {
                    id,
                    type: 'ConditionNode',
                    position: positionValue,
                    data: {
                        name: '',
                        conditions: [],
                        showConfirmation: false,
                        modifyCondition: function (key, value) { this.condition[key] = value },
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value, rule: rule.value
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'True', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'response') {
                const newNode = {
                    id,
                    type: 'ResponseNode',
                    position: positionValue,
                    data: {
                        name: '',
                        formatScript: '',
                        showFormatScript: false,
                        type: undefined,
                        showConfirmation: false,
                        // response: {
                        //     type: undefined,
                        //     payload: ''
                        // },
                        // type: ''
                        // },
                        // makeFile: function () { this.augment = { type: 1 } },
                        // makeCard: function () { this.augment = { type: 2 } },
                        // makeText: function () { this.augment = { type: 3 } },
                        // modifyAugment: function (key, value) { this.augment[key] = value }
                        skill: skill.value, rule: rule.value,
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) }
                    },
                    node: { setSelected: function () { setCurrentNode(newNode) } },


                    // edges: {},
                    // modifyResponse: function (key, value) { this.response[key] = value }
                    // }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'True', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'clear') {
                const entityAndContextVariable = JSON.parse(JSON.stringify(entityAndContextVariableList));
                const newNode = {
                    id,
                    type: 'ClearContextNode',
                    position: positionValue,
                    data: {
                        name: '',
                        entities: entityAndContextVariable,
                        type: 'all',
                        showConfirmation: false,

                        modifyClearContext: function (value) { this.entities = value },
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value, rule: rule.value
                        // node: { setSelected: function () { setCurrentNode(newNode) } },
                        // edges: {},
                        // modifyResponse: function (key, value) { this.response[key] = value }
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'True', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'jump') {
                const newNode = {
                    id,
                    type: 'JumpToNode',
                    position: positionValue,
                    data: {
                        name: '',
                        toNode: '',
                        modifyJumpTo: function (value) { this.toNode = value },
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        showConfirmation: false,
                        skill: skill.value, rule: rule.value
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'True', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
        }
        else if (nodeType == 'conditionF') {
            if (nodeTargetType == 'action') {
                const newNode = {
                    id,
                    type: "ActionNode",
                    position: positionValue,
                    data: {
                        // makeAPI: function () { this.augment = { type: 'API', method: 'GET', URL: '', headers: {}, payload: {}, script: '', auth_type: '', client_id: '', client_secret: '', oauth_url: '', grant_type: '' } },
                        // makeDB: function () { this.augment = { type: 'DB', db: null, query: '', script: '' } },
                        // makeLinkTo: function () { this.augment = { type: 'LinkTo', actionName: '' } },
                        // modifyAugment: function (key, value) { this.augment[key] = value },


                        name: '',
                        description: '',
                        showConfirmation: false,
                        // modifyAction: function (key, value) { this.action[key] = value },
                        node: { setSelected: function () { setCurrentNode(newNode) } },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value, rule: rule.value
                        // edges: {},
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'False', sourceHandle: 'conditionSourceHandleFalse', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'condition') {
                const newNode = {
                    id,
                    type: 'ConditionNode',
                    position: positionValue,
                    data: {
                        name: '',
                        conditions: [],

                        modifyCondition: function (key, value) { this.condition[key] = value },
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        showConfirmation: false,
                        skill: skill.value, rule: rule.value
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'False', sourceHandle: 'conditionSourceHandleFalse', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'response') {
                const newNode = {
                    id,
                    type: 'ResponseNode',
                    position: positionValue,
                    data: {
                        name: '',
                        // response: {
                        //     type: '',
                        //     payload: ''
                        // },
                        // augment: {
                        //     augment: {
                        //         type: 0
                        //     },
                        //     makeFile: function () { this.augment = { type: 1 } },
                        //     makeCard: function () { this.augment = { type: 2 } },
                        //     makeText: function () { this.augment = { type: 3 } },
                        //     modifyAugment: function (key, value) { this.augment[key] = value }
                        // },
                        node: { setSelected: function () { setCurrentNode(newNode) } },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value, rule: rule.value,
                        formatScript: '',
                        showFormatScript: false,
                        type: undefined,
                        showConfirmation: false
                        // edges: {},
                        // modifyResponse: function (key, value) { this.response[key] = value }
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'False', sourceHandle: 'conditionSourceHandleFalse', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'clear') {
                const entityAndContextVariable = JSON.parse(JSON.stringify(entityAndContextVariableList));

                const newNode = {
                    id,
                    type: 'ClearContextNode',
                    position: positionValue,
                    data: {
                        name: '',
                        entities: entityAndContextVariable,
                        type: 'all',

                        modifyClearContext: function (value) { this.entities = value },
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value, rule: rule.value,
                        showConfirmation: false
                        // node: { setSelected: function () { setCurrentNode(newNode) } },
                        // edges: {},
                        // modifyResponse: function (key, value) { this.response[key] = value }
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'False', sourceHandle: 'conditionSourceHandleFalse', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'jump') {
                const newNode = {
                    id,
                    type: 'JumpToNode',
                    position: positionValue,
                    data: {
                        name: '',
                        toNode: '',
                        modifyJumpTo: function (value) { this.toNode = value },
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value, rule: rule.value,
                        showConfirmation: false
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, label: 'False', sourceHandle: 'conditionSourceHandleFalse', markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
        }
        else {
            if (nodeTargetType == 'action') {
                const newNode = {
                    id,
                    type: "ActionNode",
                    position: positionValue,
                    data: {
                        // makeAPI: function () { this.augment = { type: 'API', method: 'GET', URL: '', headers: {}, payload: {}, script: '', auth_type: '', client_id: '', client_secret: '', oauth_url: '', grant_type: '' } },
                        // makeDB: function () { this.augment = { type: 'DB', db: null, query: '', script: '' } },
                        // makeLinkTo: function () { this.augment = { type: 'LinkTo', actionName: '' } },
                        // modifyAugment: function (key, value) { this.augment[key] = value },


                        name: '',
                        description: '',
                        showConfirmation: false,
                        // modifyAction: function (key, value) { this.action[key] = value },
                        node: { setSelected: function () { setCurrentNode(newNode) } },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value, rule: rule.value
                        // edges: {},
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'condition') {
                const newNode = {
                    id,
                    type: 'ConditionNode',
                    position: positionValue,
                    data: {
                        name: '',
                        conditions: [],
                        modifyCondition: function (key, value) { this.condition[key] = value },
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        showConfirmation: false,
                        skill: skill.value, rule: rule.value
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'response') {
                const newNode = {
                    id,
                    type: 'ResponseNode',
                    position: positionValue,
                    data: {
                        name: '',
                        formatScript: '',
                        showFormatScript: false,
                        type: undefined,
                        showConfirmation: false,
                        // response: {
                        //     type: '',
                        //     payload: ''
                        // },
                        // augment: {
                        //     augment: {
                        //         type: 0
                        //     },
                        //     makeFile: function () { this.augment = { type: 1 } },
                        //     makeCard: function () { this.augment = { type: 2 } },
                        //     makeText: function () { this.augment = { type: 3 } },
                        //     modifyAugment: function (key, value) { this.augment[key] = value }
                        // },
                        node: { setSelected: function () { setCurrentNode(newNode) } },
                        //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                        skill: skill.value, rule: rule.value
                        // edges: {},
                        // modifyResponse: function (key, value) { this.response[key] = value }
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'clear') {
                const entityAndContextVariable = JSON.parse(JSON.stringify(entityAndContextVariableList));

                const newNode = {
                    id,
                    type: 'ClearContextNode',
                    position: positionValue,
                    data: {
                        name: '',
                        entities: entityAndContextVariable,
                        type: 'all',
                        showConfirmation: false,
                        modifyClearContext: function (value) { this.entities = value },
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(newNode) },
                        skill: skill.value, rule: rule.value
                        // node: { setSelected: function () { setCurrentNode(newNode) } },
                        // edges: {},
                        // modifyResponse: function (key, value) { this.response[key] = value }
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
            else if (nodeTargetType == 'jump') {
                const newNode = {
                    id,
                    type: 'JumpToNode',
                    position: positionValue,
                    data: {
                        name: '',
                        toNode: '',
                        modifyJumpTo: function (value) { this.toNode = value },
                        showConfirmation: false,
                        node: {
                            open: false,
                            setOpen: function (bool) { this.open = bool },
                            setSelected: function () { setCurrentNode(newNode) }
                        },
                        //OnDelete: function () { setDeletedNodes(newNode) },
                        skill: skill.value, rule: rule.value
                    }
                };
                noOfNodes = nodes.length + 1
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id, source: connectingNodeId.current, target: id, markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 15,
                        height: 15,
                        color: 'grey',
                    },
                }));
                nodeTargetType = ''
            }
        }

    }
    const askDestination = () => {
        return <Modal size="lg" centered show={showNodeTarget} onHide={() => { setNodeTarget(false) }} animation={false} >
            <Modal.Header >
                <h6>Choose the destination Node</h6>
                <div className=' btn--small btn--icon' onClick={() => { setNodeTarget(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>Which node do you want to create?</p>
            </Modal.Body>
            <Modal.Footer>
                <div className='row'>
                    <button className="btn btn--small btn--secondary" onClick={() => { nodeTargetType = 'action'; setNodeTarget(false); createNode(eventNode); }}>
                        Action Node
                    </button>
                    <button className="btn btn--small btn--secondary" onClick={() => { nodeTargetType = 'condition'; setNodeTarget(false); createNode(eventNode); }}>
                        Condition Node
                    </button>
                    <button className="btn btn--small btn--secondary" onClick={() => { nodeTargetType = 'response'; setNodeTarget(false); createNode(eventNode); }}>
                        Response Node
                    </button>
                    <button className="btn btn--small btn--secondary" onClick={() => { nodeTargetType = 'clear'; setNodeTarget(false); createNode(eventNode); }}>
                        Clear Context Node
                    </button>
                    <button className="btn btn--small btn--secondary" onClick={() => { nodeTargetType = 'jump'; setNodeTarget(false); createNode(eventNode); }}>
                        Jump To Node
                    </button>
                </div>


            </Modal.Footer>
        </Modal>

    }

    const onDragOver = useCallback(event => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const isTypeValid = type => {
        if (typeof type === 'undefined' || !type)
            return false;
        for (let i in nodeTypes)
            if (i === type)
                return true;
        return false;
    };

    const findRuleNode = () => {
        for (var i of nodes) {
            if (i.type == 'RuleNode')
                return true
        }
        return false
    }

    const onDrop = useCallback(async event => {
        clearRuleAndDesc()
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const id = getId();
        const type = event.dataTransfer.getData('application/reactflow');
        if (!isTypeValid(type))
            return;

        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left - 100,
            y: event.clientY - reactFlowBounds.top - 37.5,
        });

        setNewNodeInfo({ id, type, position })
        console.log(nodes)
        if (noOfNodes == 0) {
            if (type == 'RuleNode') {
                setNewNodeInfo(obj => new Object({ ...obj, name: '', description: '', showConfirmation: false }))
                setShowRuleModal(true)
            }
            else {
                setWarningModal(true);
                setMessages("The Dialog Flow should start with a <b>Rule Node</b>.")
            }
        }
        else {
            if (type == 'RuleNode') {
                setWarningModal(true);
                setMessages('The Dialog Flow can have just one <b>Rule Node</b>.')
            }
            else {
                if (type == 'ActionNode') {
                    setNewNodeInfo(obj => new Object({ ...obj, name: '', description: '', showConfirmation: false }))
                    setShowActionModal(true)
                }
                else if (type == 'JumpToNode') {
                    setNewNodeInfo(obj => new Object({ ...obj, toNode: { label: 'Select the JumpTo Rule', value: 'default' }, showConfirmation: false }))
                    setShowJumpToModal(true)
                }
                else if (type == 'ClearContextNode') {
                    const entityAndContextVariable = JSON.parse(JSON.stringify(entityAndContextVariableList));

                    setNewNodeInfo(obj => new Object({
                        ...obj,
                        entities: entityAndContextVariable,
                        type: 'all',
                        showConfirmation: false

                    }))
                    setShowClearContextModal(true)
                    setCheckedAll(false)
                }
                else if (type == 'ConditionNode') {
                    setNewNodeInfo(obj => new Object({ ...obj, conditions: [], showConfirmation: false }))
                    setShowConditionModal(true)
                }
                else {
                    // const newNode = {
                    //     id: getId(),
                    //     type,
                    //     position,
                    //     data: {
                    //         name: '',
                    //         type: undefined,
                    //         response: '',
                    //         setSelected: function () { setCurrentNode(newNode) },
                    //         skill: skill.value, rule: rule.value, showConfirmation: false

                    //     },

                    // };
                    // openDragFrom(event, newNode);

                    // setCurrentNode(newNode)
                    // noOfNodes = nodes.length + 1
                    // setNodes((nds) => nds.concat(newNode));
                    setNewNodeInfo(obj => new Object({ ...obj, type: '', name: '', skill: skill.value, rule: rule.value, showConfirmation: false }))
                    setShowResponseModal(true)
                }
            }
        }
    }, [reactFlowInstance]);

    const warningMessage = () => {
        return <Modal size="sm" centered show={showWarning} onHide={() => { setWarningModal(false) }} animation={false} >
            <Modal.Header style={{ color: "#fbab18" }}>
                <h5><b>Warning</b></h5>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn--small btn--secondary" onClick={() => { setWarningModal(false); }}>
                    OK
                </button>
            </Modal.Footer>
        </Modal>
    }

    const warningMessageReload = () => {
        return <Modal size="sm" centered show={showWarningReload} onHide={() => { setWarningReloadModal(false) }} animation={false} >
            <Modal.Header style={{ color: "#fbab18" }}>
                <h5><b>Warning</b></h5>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn--small btn--secondary" onClick={() => { setWarningReloadModal(false); localStorage.removeItem('rule_name'); localStorage.removeItem('rule_value'); }}>
                    Discard
                </button>
                <button className="btn btn--small btn--secondary" onClick={() => { setWarningReloadModal(false); saveDialogFlow() }}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    }
    const warningMessageRule = () => {
        return <Modal size="sm" centered show={showRuleWarning} onHide={() => { setRuleWarningModal(false) }} animation={false} >
            <Modal.Header style={{ color: "#fbab18" }}>
                <h5><b>Warning</b></h5>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn--small btn--secondary" onClick={() => {
                    setRuleWarningModal(false);
                    getNodeList(rule, skill)
                    setShowRuleModal(false);
                    clearRuleAndDesc();
                }}>
                    Discard
                </button>
                <button className="btn btn--small btn--secondary" onClick={() => {
                    saveDialogFlow();
                    setRuleWarningModal(false);
                    getNodeList(rule, skill)
                    setShowRuleModal(false);
                    clearRuleAndDesc();
                }}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    }

    const addRuleNode = () => {
        const newNode = {
            id: newNodeInfo.id,
            type: newNodeInfo.type,
            position: newNodeInfo.position,
            data: {
                slots: [],
                name: newNodeInfo.name,
                description: newNodeInfo.description,
                intent: { label: 'Select an Intent', value: 'default' },
                entity: { label: 'Select an Entity', value: 'default' },
                open: false,
                setSelected: function () { setCurrentNode(newNode) },
                skill: skill.value,
                rule: rule.value,
                showConfirmation: false,
                //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
            },
        };
        setCurrentNode(newNode)
        noOfNodes = nodes.length + 1
        setNodes(nds => nds.concat(newNode))
        clearRuleAndDesc()
    }

    const clearRuleAndDesc = () => {
        setSubmitBtnEnable(false)
        setRuleAction([{ name: "", description: "" }])
    }

    const addActionNode = () => {
        const newNode = {
            id: newNodeInfo.id,
            type: 'ActionNode',
            position: newNodeInfo.position,
            data: {
                type: undefined,
                name: newNodeInfo.name,
                description: newNodeInfo.description,
                setSelected: function () { setCurrentNode(newNode) },
                //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                skill: skill.value,
                rule: rule.value,
                showConfirmation: false
            },
        };
        setCurrentNode(newNode)
        noOfNodes = nodes.length + 1
        setNodes(nds => nds.concat(newNode))
    }

    const addJumpToNode = () => {
        const newNode = {
            id: newNodeInfo.id,
            type: 'JumpToNode',
            position: newNodeInfo.position,
            data: {
                name: newNodeInfo.name,
                toNode: newNodeInfo.toNode,
                setSelected: function () { setCurrentNode(newNode) },
                //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                skill: skill.value,
                rule: rule.value,
                showConfirmation: false,

            }
        };
        setCurrentNode(newNode);
        noOfNodes = nodes.length + 1
        setNodes(nds => nds.concat(newNode))
    }

    const addResponseNode = () => {
        const newNode = {
            id: newNodeInfo.id,
            type: 'ResponseNode',
            position: newNodeInfo.position,
            data: {
                name: newNodeInfo.name,
                type: newNodeInfo.type,
                setSelected: function () { setCurrentNode(newNode) },
                //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                skill: skill.value,
                rule: rule.value,
                showConfirmation: false
            }
        };
        setCurrentNode(newNode);
        noOfNodes = nodes.length + 1
        setNodes(nds => nds.concat(newNode))
    }

    const addClearContextNode = () => {
        const newNode = {
            id: newNodeInfo.id,
            type: 'ClearContextNode',
            position: newNodeInfo.position,
            data: {
                name: newNodeInfo.name,
                type: newNodeInfo.type,
                entities: newNodeInfo.entities,
                setSelected: function () { setCurrentNode(newNode) },
                //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                skill: skill.value,
                rule: rule.value,
                showConfirmation: false

            }
        };
        setCurrentNode(newNode);
        noOfNodes = nodes.length + 1
        setNodes(nds => nds.concat(newNode))
    }

    const addConditionNode = () => {
        const newNode = {
            id: newNodeInfo.id,
            type: newNodeInfo.type,
            position: newNodeInfo.position,
            data: {
                name: newNodeInfo.name,
                conditions: newNodeInfo.conditions,
                description: newNodeInfo.conditionDescription,
                setSelected: function () { setCurrentNode(newNode) },
                //OnDelete: function () { setDeletedNodes(del => [...del, newNode]) },
                skill: skill.value,
                rule: rule.value,
                showConfirmation: false
            },
        };
        setCurrentNode(newNode);
        noOfNodes = nodes.length + 1
        setNodes(nds => nds.concat(newNode))
    }

    function ruleSubmission(e) {
        e.preventDefault()
    }
    const handleExpressions = () => {
        let isRowSelected = false;
        console.log(expressions)
        const changeFunction = index => {
            let expressionsCopy = [...expressions]
            const temp = !expressionsCopy[index].isChecked
            expressionsCopy[index].isChecked = temp
            setExpressions(expressionsCopy);
            for (let element of expressionsCopy)
                if (element.isChecked === false) {
                    setCheckedAll(false)
                    return
                }
            setCheckedAll(true)
        }

        const changeAllFunction = () => {
            let expressionsCopy = [...expressions]
            let temp = !checkedAll
            for (let element of expressionsCopy)
                element.isChecked = temp
            setExpressions(expressionsCopy)
            setCheckedAll(temp)
        }

        const deleteFunction = () => {
            let expressionsCopy = []
            for (let element of expressions)
                if (element.isChecked === false)
                    expressionsCopy.push(element)
            setExpressions(expressionsCopy)
            setCheckedAll(false)
        }

        for (let element of expressions) {
            if (element.isChecked === true) {
                isRowSelected = true;
                break;
            }

        }
        return (
            <div className="panel panel--raised">
                <CheckUncheck listname="Expressions" checkboxlist={expressions} changeFunction={changeFunction} changeAllFunction={changeAllFunction} checkedAll={checkedAll} >
                    <div className="deletebtn base-margin">
                        <button disabled={isRowSelected === false} className="btn btn--small btn--secondary" onClick={() => { deleteFunction() }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>Delete</button>
                    </div>
                </CheckUncheck>
            </div>
        )
    }


    //     [
    //     { label: 'Create Action', value: 'newAction' },
    //     { label: 'Action1', value: 'Action1' },
    //     { label: 'Action2', value: 'Action2' },
    //     { label: 'Action3', value: 'Action3' },
    //     { label: 'Action4', value: 'Action4' },
    //     { label: 'Action5', value: 'Action5' },
    // ]);

    const [action, setAction] = useState({ label: 'Action1', value: 'Action1' })

    const openRuleModal = () =>
        <Modal size="lg" centered show={showRuleModal} onHide={() => { setShowRuleModal(false); }} animation={false} >
            <Modal.Header >
                <h5>Create Rule Node</h5>
                <div className=' btn--small btn--icon' onClick={() => {
                    setShowRuleModal(false); clearRuleAndDesc(); setShowValidationErrorMessage(false); setShowExpressionValidationErrorMessage(false); setExpressions([]); setExpression('');
                    if (localStorage.getItem("rule_name") && localStorage.getItem("rule_value")) {
                        updateRule({ label: localStorage.getItem("rule_name"), value: localStorage.getItem("rule_value") })
                    }
                    else {
                        updateRule(rules[0])
                    }
                }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }

                <form onSubmit={ruleSubmission}>
                    <div className='center base-padding col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                        {!submitBtnEnable ? <div className='warningMessage txtAlignRight'>Please fill the (*) mandatory fields</div> : ""}
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                <input id="input-type-number" type="text" autoFocus autoComplete='off' name="rule_name" onChange={event => { inputHandlerChange(event, "name") }} />
                                <label htmlFor="input-type-number">Rule Name <span className='required'>*</span></label>
                            </div>
                        </div>
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                <textarea id="textarea-state-default" rows={6} name="rule_description" onChange={event => { inputHandlerChange(event, "description") }}></textarea>
                                <label className='positionRelative' htmlFor="input-type-number">Rule Description <span className='required'>*</span></label>
                            </div>
                        </div>
                        <hr />

                        {/* <div className="panel panel--raised">
                            <div className="row" style={{ "marginLeft": "0px" }}>
                                <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
                                    <div className="form-group__text">
                                        <input id="input-type-email" type="text" value={expression} onChange={event => { setExpression(event.target.value) }} />
                                        <label htmlFor="input-type-email">Expression</label>
                                    </div>
                                </div>
                                <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                                    <span className="icon-add-outline icon-size-20" style={{ "marginTop": "25px" }} onClick={() => {
                                        setExpressions(arr => [...arr, { id: getExpressionId(), name: expression, isChecked: false }])
                                        setExpression('');
                                    }}></span>
                                </div>
                            </div>
                        </div> */}
                        {
                            showExpressionValidationErrorMessage && (<MessageBanner message={dataExpressionValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowExpressionValidationErrorMessage(false) }}></MessageBanner>)
                        }
                        <div className="row" style={{ "marginLeft": "0px" }}>
                            <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
                                <div className="form-group__text">
                                    <input id="input-type-email" type="text" autoComplete='off' value={expression} onChange={event => { setExpression(event.target.value) }} onKeyDown={(e) => {
                                        if (e.key === 'Enter' && expression.length > 0) {
                                            console.log(expression)
                                            setShowExpressionValidationErrorMessage(false);

                                            if (isAlphanumeric(expression, 'en-US', { "ignore": /[,.!?'"; ]/g })) {
                                                setExpressions(arr => [...arr, { id: getExpressionId(), value: expression, name: expression, isChecked: false, entities: [] }])
                                                setExpression('');
                                            }
                                            else {
                                                setShowExpressionValidationErrorMessage(true);
                                                setDataExpressionValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression.');
                                            }
                                        }
                                    }} />
                                    <label htmlFor="input-type-email">Expression</label>
                                </div>
                            </div>
                            <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                                <span className={(expression.length == 0 ? "disabled " : "") + "icon-add-outline icon-size-20"} style={{ "marginTop": "25px" }} onClick={() => {
                                    // setExpressions(arr => [...arr, { id: getExpressionId(), name: expression, isChecked: false, annotations: [] }])
                                    // setExpression('');
                                    console.log(expression)
                                    console.log(expressions)
                                    setShowExpressionValidationErrorMessage(false);
                                    if (isAlphanumeric(expression, 'en-US', { "ignore": /[,.!?'"; ]/g })) {
                                        setExpressions(arr => [...arr, { id: getExpressionId(), value: expression, name: expression, isChecked: false, entities: [] }])
                                        setExpression('');
                                    }
                                    else {
                                        setShowExpressionValidationErrorMessage(true);
                                        setDataExpressionValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression.');
                                    }
                                }}></span>
                            </div>
                        </div>
                        {expressions.length > 0 ? <hr></hr> : ""}
                        {expressions.length > 0 ? handleExpressions() : ""}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn  btn--small btn--secondary" onClick={() => { setShowRuleModal(false); clearRuleAndDesc(); setShowValidationErrorMessage(false); setShowExpressionValidationErrorMessage(false); setExpressions([]); setExpression(''); }}>
                    Close
                    {/* <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" disabled={!submitBtnEnable} onClick={() => {
                    setNodes([])
                    noOfNodes = 0
                    setEdges([])
                    addRuleNode();
                    clearRuleAndDesc();
                    setShowRuleModal(false);
                    let temp = rules
                    temp.push({ label: newNodeInfo.name, value: newNodeInfo.id })
                    setRules(temp)
                    setShowErrorMessage(false);
                    setRule({ label: newNodeInfo.name, value: newNodeInfo.id })
                    localStorage.setItem('rule_name', newNodeInfo.name)
                    localStorage.setItem('rule_value', newNodeInfo.id)
                    // getNodeList({ label: newNodeInfo.name, value: newNodeInfo.id }, skill)
                    setShowValidationErrorMessage(false);
                    setShowExpressionValidationErrorMessage(false);
                }}>
                    Submit
                    {/* <span className="icon-save icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal >

    const openActionModal = () =>
        <Modal size="lg" centered show={showActionModal} onHide={() => { setShowActionModal(false) }} animation={false} >
            <Modal.Header >
                <h5>Create Action Node </h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowActionModal(false); setSubmitBtnEnable(false); setShowValidationErrorMessage(false); setDataValidationErrorMessage('') }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }
                <form onSubmit={ruleSubmission}>
                    <div className=' base-padding'>
                        {!submitBtnEnable ? <div className='warningMessage txtAlignRight'>Please fill the (*) mandatory fields</div> : ""}
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                {/* <input id="input-type-number" type="text" name="rule_name" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = event.target.value; return objCopy }) }} /> */}
                                <input id="input-type-number" type="text" name="rule_name" autoComplete='off' onChange={event => { inputHandlerActionChange(event, "name") }} />
                                <label htmlFor="input-type-number">Action Name <span className='required'>*</span> </label>
                            </div>
                        </div>
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                {/* <textarea id="textarea-state-default" rows={6} name="rule_description" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.description = event.target.value; return objCopy }) }}></textarea> */}
                                <textarea id="textarea-state-default" rows={6} name="rule_description" onChange={event => { inputHandlerActionChange(event, "description") }}></textarea>
                                <label className='positionRelative' htmlFor="input-type-number">Action Description <span className='required'>*</span></label>
                            </div>
                        </div>
                        {/* <hr /> */}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--small btn--secondary" onClick={() => { setShowActionModal(false); setSubmitBtnEnable(false); setShowValidationErrorMessage(false); setDataValidationErrorMessage('') }}>
                    Close
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn  btn--secondary" disabled={!submitBtnEnable} onClick={() => { setShowActionModal(false); addActionNode(); setShowValidationErrorMessage(false); setDataValidationErrorMessage('') }}>
                    Submit
                    {/* <span className="icon-save icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>

    const openJumpToModal = () => {
        const jumpto_options = rules

        return <Modal size="lg" centered show={showJumpToModal} onHide={() => { setShowJumpToModal(false) }} animation={false} >
            <Modal.Header >
                <h5>Create Jump To Node</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowJumpToModal(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
                }
                <div className=''>
                    <div className="form-group base-margin-bottom" >
                        <div className="form-group__text">
                            {/* <input id="input-type-number" type="text" name="rule_name" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = event.target.value; return objCopy }) }} /> */}
                            <input id="input-type-number" type="text" name="rule_name" autoComplete='off' onChange={event => { inputHandlerNameChange(event) }} />
                            <label htmlFor="input-type-number">JumpTo Node Name <span className='required'>*</span> </label>
                        </div>
                    </div>
                    <div className="form-group dropdown col-12">
                        <div className="form-group">
                            <label htmlFor="select-type-basic">Select the Jump To Rule Node  </label>
                            <SearchSelect
                                defaultValue={newNodeInfo.toNode}
                                options={jumpto_options}
                                onValueChange={data => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.toNode = data; return objCopy }) }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary btn--small" onClick={() => { setShowJumpToModal(false) }}>
                    Close
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" disabled={newNodeInfo.name == undefined || newNodeInfo.name == '' || newNodeInfo.toNode == undefined || newNodeInfo.name.length < 5 || newNodeInfo.toNode.value == 'default'} onClick={() => { setShowJumpToModal(false); addJumpToNode() }}>
                    Submit
                    {/* <span className="icon-save icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>
    }

    const openClearContextModal = () => {

        const changeFunction = index => {
            let tempEntity = [...newNodeInfo.entities]
            const temp = !tempEntity[index].isChecked
            tempEntity[index].isChecked = temp
            setNewNodeInfo(object => new Object({ ...object, entities: [...tempEntity] }))
            for (let element of tempEntity)
                if (element.isChecked === false) {
                    setCheckedAll(false)
                    return
                }
            setCheckedAll(true)
        }

        const changeAllFunction = () => {
            let tempEntity = new Array(...newNodeInfo.entities)
            let temp = !checkedAll
            for (let element of tempEntity)
                element.isChecked = temp
            setNewNodeInfo(object => new Object({ ...object, entities: [...tempEntity] }))
            setCheckedAll(temp)
        }

        return <Modal size="lg" centered show={showClearContextModal} onHide={() => { setShowClearContextModal(false) }} animation={false}>
            <Modal.Header >
                <h5> Create Clear Context Node</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowClearContextModal(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {
                        showExpressionValidationErrorMessage && (<MessageBanner message={dataExpressionValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowExpressionValidationErrorMessage(false) }}></MessageBanner>)
                    }
                    <div className="form-group base-margin-bottom">
                        <div className="form-group__text">
                            {/* <input id="input-type-number" type="text" name="rule_name" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = event.target.value; return objCopy }) }} /> */}
                            <input id="input-type-number" type="text" name="rule_name" autoComplete='off' onChange={event => { inputHandlerNameChange(event) }} />
                            <label htmlFor="input-type-number">Clear Context Node Name <span className='required'>*</span> </label>
                        </div>
                    </div>
                    <div className='radiolists'>
                        <div className="form-group form-group--inline">
                            <label className="radio">
                                <input type="radio" checked={newNodeInfo.type === 'all' ? true : false} onChange={() => setNewNodeInfo(object => new Object({ ...object, type: 'all' }))} />
                                <span className="radio__input"></span>
                                <span className="radio__label">All</span>
                            </label>
                        </div>
                        <div className="form-group form-group--inline">
                            <label className="radio">
                                <input type="radio" checked={newNodeInfo.type === 'custom' ? true : false} onChange={() => setNewNodeInfo(object => new Object({ ...object, type: 'custom' }))} />
                                <span className="radio__input"></span>
                                <span className="radio__label">Custom</span>
                            </label>
                        </div>
                    </div>
                    {newNodeInfo.type === 'custom' && <div>
                        {
                            showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                        }

                        <div className="row" style={{ "marginLeft": "0px" }}>
                            <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-11 col-xxxl-11 form-group base-margin-bottom">
                                <div className="form-group__text">
                                    <input id="input-type-email" type="text" autoComplete='off' value={custom} placeholder="Enter the Custom Variable " onChange={event => { setCustom(event.target.value) }} onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                                <span className={(custom.length == 0 ? "disabled " : "") + "icon-add-outline icon-size-20"} style={{ "marginTop": "5px" }} onClick={() => {
                                    console.log(custom)
                                    setShowValidationErrorMessage(false);
                                    if (isAlphanumeric(custom, 'en-US', { "ignore": "_" })) {
                                        console.log(newNodeInfo)
                                        let temp = newNodeInfo.entities
                                        temp.push({
                                            id: temp.length,
                                            name: custom,
                                            isChecked: false
                                        })
                                        setNewNodeInfo(object => new Object({ ...object, entities: temp }))
                                        setCustom('');
                                    }
                                    else {
                                        setShowValidationErrorMessage(true);
                                        setDataValidationErrorMessage('Special characters other than _ are not allowed in an expression.');
                                    }
                                }}></span>
                            </div>
                        </div>
                    </div>}
                    {newNodeInfo.type == 'custom' && <div className="row contnerSectionbox selectDb ">
                        <label className='lbPosition'>Select Entity<span className='mandatoryIcon'>*</span></label>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <CheckUncheck listname="Entity list" checkboxlist={newNodeInfo.entities} changeFunction={changeFunction} changeAllFunction={changeAllFunction} checkedAll={checkedAll} />
                        </div>
                    </div>}
                </form>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary" onClick={() => { setShowClearContextModal(false) }}>
                    Close
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" onClick={() => { setShowClearContextModal(false); addClearContextNode(); }} disabled={newNodeInfo.name == undefined || newNodeInfo.name == '' || newNodeInfo.name.length < 5 || showExpressionValidationErrorMessage && submitBtnEnable}>
                    Submit
                    {/* <span className="icon-save icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal >
    }

    const openConditionModal = () => {
        return <Modal size="lg" centered show={showConditionModal} onHide={() => { setShowConditionModal(false) }} animation={false}>
            <Modal.Header>
                <h5>Create Condition Node</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowConditionModal(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body className='conditionPop'>
                {
                    showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
                }
                <div className="form-group base-margin-bottom" >
                    <div className="form-group__text">
                        {/* <input id="input-type-number" type="text" name="rule_name" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = event.target.value; return objCopy }) }} /> */}
                        <input id="input-type-number" type="text" name="rule_name" autoComplete='off' onChange={event => { inputHandlerNameChange(event) }} />
                        <label htmlFor="input-type-number">Condition Node Name <span className='required'>*</span> </label>
                    </div>
                </div>

                <GlobalConditionNode initialConditions={[]} editParentConditions={updatedConditions => { setNewNodeInfo(object => new Object({ ...object, conditions: updatedConditions })) }} skillEntityList={entityList.slice(1)} ruleEntityList={entityAndContextVariableList.filter(value => value.name.startsWith('var_e_')).map(value => ({ label: value.name, value: value.name }))} systemEntityList={entityAndContextVariableList.filter(value => value.name.startsWith('var_i_')).map(value => ({ label: value.name, value: value.name }))} title='Condition Details' />
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary btn--small" onClick={() => { setShowConditionModal(false) }}>
                    Close
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary " onClick={() => { setShowConditionModal(false); addConditionNode(); }} disabled={newNodeInfo.name == undefined || newNodeInfo.name == '' || newNodeInfo.name.length < 5}>
                    Submit
                    {/* <span className="icon-save icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>
    }


    const openResponseModal = () => {
        return <Modal size="lg" centered show={showResponseModal} onHide={() => { setShowResponseModal(false) }} animation={false} >
            <Modal.Header >
                <h5>Create Response Node</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowResponseModal(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
                }
                <div className=''>
                    <div className="form-group base-margin-bottom" >
                        <div className="form-group__text">
                            <input id="input-type-number" type="text" name="rule_name" autoComplete='off' onChange={event => { inputHandlerNameChange(event) }} />
                            <label htmlFor="input-type-number">Response Node Name <span className='required'>*</span> </label>
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn--secondary" disabled={newNodeInfo.name == undefined || newNodeInfo.name == '' || newNodeInfo.name.length < 5} onClick={() => { setShowResponseModal(false); addResponseNode() }}>
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
    }


    const updateSkill = (data) => {
        setSkill(data)
        localStorage.setItem("skill_name", data.label)
        localStorage.setItem("skill_value", data.value)
        setEdges([])
        setNodes([])
        setRule({ label: 'Select a Rule', value: 'default' })
        localStorage.removeItem('rule_name')
        localStorage.removeItem('rule_value')
        localStorage.removeItem('intent_name')
        localStorage.removeItem('intent_value')
        console.log("rule_name", localStorage.getItem("rule_name"))
    }

    const updateRule = (data) => {
        setRule(data)

        localStorage.setItem('rule_name', data.label)
        localStorage.setItem('rule_value', data.value)

    }

    const findSkills = (data) => {
        if (data.value == 'newSkill')
            return true
        return false
    }

    const getRuleList = (skillId) => {

        setDataLoading(true);
        DialogService.getRules(skillId.value).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                setDataLoading(false);
                if (res.rules.length == 0) {
                    setRules([{ label: 'Create Rule', value: 'newRule' }])
                    setRule({ label: 'Select a Rule', value: 'default' })


                }
                else {

                    console.log("Inside2")
                    let rule_temp = res.rules;
                    console.log("rule_temp", rule_temp)
                    let rule_list = []
                    for (let r of rule_temp) {
                        rule_list.push({ label: r.name, value: r.id })
                    }
                    rule_list.unshift({ label: 'Create Rule', value: 'newRule' })
                    setRules(rule_list)
                    if (localStorage.getItem('rule_name') && localStorage.getItem('rule_value')) {
                        setRule({ label: localStorage.getItem('rule_name'), value: localStorage.getItem('rule_value') })
                        getNodeList({ label: localStorage.getItem('rule_name'), value: localStorage.getItem('rule_value') }, skillId)
                    }
                    else {
                        setRule(rule_list[1])
                        localStorage.setItem('rule_name', rule_list[1].label)
                        localStorage.setItem('rule_value', rule_list[1].value)
                        getNodeList(rule_list[1], skillId)
                    }


                    console.log(rule_list)
                }
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to retrieve Rules!");
            setShowMessageBanner(true);
        })
    }

    const skillName = () => {
        return <SearchSelect
            id='skillName'
            className='augmentSelect'
            defaultValue={skill}
            options={skills}
            onValueChange={(data) => {
                updateSkill(data);


                setCloseSplitScreen(true);
                if (findSkills(data)) {
                    setShowCreateSkill(true)
                }
                else {
                    setShowCreateSkill(false)
                    getRuleList(data)
                    getIntentList(data)
                }
            }}
        />
    }
    const addSkill = () => {
        setDataLoading(true);
        let payload = skillAction[0];

        payload["shared"] = false;
        console.log(payload)
        SkillService.createSkill(payload).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                getSkills(skills.length);
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage('A new Skill has been created successfully!');
                setShowMessageBanner(true);
                setShowGuidance(false);
                setSkill({ label: res['result']['name'], value: res['result']['_id'] })
                localStorage.setItem('skill_name', res['result']['name'])
                localStorage.setItem('skill_value', res['result']['_id'])
                localStorage.removeItem('intent_name')
                localStorage.removeItem('intent_value')
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve Skills!");
            setShowMessageBanner(true);
        })
    }

    const getNodeList = (data, skill) => {
        console.log(data)
        setDataLoading(true);
        DialogService.getNodes(skill.value, data.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                console.log(res)
                setNodes(res['nodes'])
                noOfNodes = res['nodes'].length
                let temp = []
                for (var i of res['edges']) {
                    temp.push({
                        ...i, markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 15,
                            height: 15,
                            color: 'grey',

                        },
                        selected: false,
                        id: getId()
                    })
                }
                console.log("temp", temp)
                setEdges(temp)
                setEdgesCopy(temp)
                // setEdges(res['edges'])
                setDataLoading(false);
                setNodesCopy(res['nodes'])
                // setEdgesCopy(res['edges'])
                // setNodeNumber(res['nodes'].length)
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve Nodes!");
            setShowMessageBanner(true);
        })
    }

    const findRules = (data) => {
        if (data.value == 'newRule') {
            return true
        }
        return false
    }
    const listRules = () => {
        return <SearchSelect
            id='ruleName'
            className='augmentSelect'
            defaultValue={rule}
            options={rules}
            onValueChange={(data) => {
                setCloseSplitScreen(true);

                if (data.value === 'newRule') {

                    setShowRuleModal(true);
                    const position = reactFlowInstance.project({
                        x: 100,
                        y: 100
                    });
                    setExpressions([])
                    const id = getId();
                    const type = "RuleNode"
                    setNewNodeInfo({
                        id: id,
                        type: type,
                        position: position
                    })
                    console.log('going inside this if')
                    // updateRule(rules[1]);
                }
                else {
                    updateRule(data);
                    // func(data)

                    console.log("Inside else", nodes)
                    console.log(nodes.length)
                    if (noOfNodes > 0) {
                        setRuleWarningModal(true);
                        setMessages('Are you sure you want to go to a new Rule. Make sure to save your changes before going to another rule flow.')
                    }
                    else {
                        getNodeList(data, skill)
                        setShowRuleModal(false);
                        clearRuleAndDesc();
                    }
                    // setCloseSplitScreen(true);
                }
            }}
        />

    }

    const getIntentList = (skill) => {
        IntentService.listIntents(skill.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                if (res['intents'].length > 0) {
                    let intent_list = res['intents']
                    let vals = []
                    for (let i of intent_list) {
                        vals.push({ label: i['name'], value: i['_id'] })
                    }
                    vals.unshift({ label: 'Create an Intent', value: 'newIntent' })
                    setIntents(vals)
                }
                else {
                    setIntents([{ label: 'Create an Intent', value: 'newIntent' }])
                }
            }
            else {
                console.log(res['details'])
            }
        },
            err => {
                console.log(err)
            })
    }
    const getEntityList = (skill) => {
        EntityService.listEntity(skill.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                if (res['entities'].length > 0) {
                    let entity_list = res['entities']
                    console.log(res['entities'])
                    let vals = []
                    for (let i of entity_list) {
                        vals.push({ label: i['name'], value: i['_id'], roles: i['roles'] })
                    }
                    vals.unshift({ label: 'Create an Entity', value: 'newEntity' })
                    setEntities(vals)
                }
                else {
                    setEntities([{ label: 'Create an Entity', value: 'newEntity' }])
                }
            }
            else {
                console.log(res['details'])
            }
        },
            err => {
                console.log(err)
            })
    }

    const closeDragFroms = () => {
        setShowSlots(false);
        setShowActions(false);
        setShowResponse(false);
    };

    const onPaneClick = event => {
        closeDragFroms();
        setCurrentNode(null);
    };

    const openDragFrom = clickedNode => {
        if (!isTypeValid(clickedNode.type)) return;
        setCurrentNode(clickedNode);
        closeDragFroms();

        switch (clickedNode.type) {
            case 'RuleNode':
                setShowSlots(true);
                break;
            case 'ActionNode':
                setShowActions(true);
                break;
            case 'ResponseNode':
                setShowResponse(true);
        }
    };

    const onNodeClick = (event, clickedNode) => {
        setCloseSplitScreen(false);
        openDragFrom(clickedNode);
    };

    const width = window.innerWidth;
    const height = window.innerHeight;

    const onElementsRemove = (id) => {

        setNodes((nds) => nds.filter((node) => node.id !== id));
        noOfNodes = nodes.length
        // setEdges((edg) => edg.filter((edge) => edge.source !== id || edge.target !== id));

    };
    const smallerDimension = Math.min(height, width);
    const onNodeDragStop = useCallback((event, node) => {
        if (
            height - event.clientY <= smallerDimension / 10 &&
            width - event.clientX <= smallerDimension / 10
        ) {
            onElementsRemove(node.id);
            if (node.type == 'RuleNode') {
                let index = rules.findIndex(data => data.value == node.id)
                console.log(node.id)
                console.log(index)
                if (index != -1) {
                    rules.splice(index, 1)
                    setRule({ label: 'Select a Rule', value: 'default' })
                    setNodes([])
                    noOfNodes = 0
                    setEdges([])
                }
            }
        }
    });

    const getReactFlowProvider = () =>
        < ReactFlowProvider >
            <div className='reactflow-wrapper' ref={reactFlowWrapper} style={{ height: window.innerHeight - 200 }}>
                <div className='row' style={{ position: 'absolute', width: '100%', display: nodes && nodes.length > 0 && nodes[0].type == "RuleNode" ? 'inherit' : 'none' }}>
                    <DragNodesFrom />
                    {/* <DragSlotsFrom display={showSlots} />
                    <DragActionsFrom display={showActions} />
                    <DragResponseFrom display={showResponse} /> */}
                </div>
                <ReactFlow
                    id='canvasIDForOnBoarding'
                    edges={edges}
                    nodes={nodes}
                    onLoad={onLoad}
                    onConnect={onConnect}
                    onConnectStart={onConnectStart}
                    onConnectEnd={onConnectEnd}
                    onNodesChange={onNodesChange}
                    onNodesDelete={onNodesDelete}
                    onEdgesChange={onEdgesChange}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onNodeClick={onNodeClick}
                    onPaneClick={onPaneClick}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeType}
                    onNodeDragStop={onNodeDragStop}
                // defaultPosition={[Number(localStorage.getItem('viewport-x')), Number(localStorage.getItem('viewport-y'))]}
                // defaultZoom={Number(localStorage.getItem('viewport-zoom'))}
                // fitView={true}
                >
                    <MiniMap nodeColor={(node) => {
                        switch (node.type) {
                            case "RuleNode":
                                return "#00bceb";
                            case "ActionNode":
                                return "#6abf4b";
                            case "ConditionNode":
                                return "#fbab18";
                            case "ClearContextNode":
                                return "#e2231a";
                            case "JumpToNode":
                                return "#eed202";
                            case "ResponseNode":
                                return "#0d274d";
                            default:
                                return "#eee";
                        }
                    }} />
                    <Controls />
                    <Background />
                </ReactFlow>
                {/* <div className="dialogScreenTrashBin" >
                    <img src={process.env.PUBLIC_URL + "/images/common-images/TrashBinIcon.png"} alt="TrashBin" ></img>
                </div> */}
            </div>
        </ReactFlowProvider >
    // const getIntentEntity = (expression) => {
    //     let expression
    // }

    console.log(entityAndContextVariableList)

    const getSplitScreenContent = node => {
        if (node) {
            switch (node.type) {
                case 'RuleNode': {
                    if (expressions.length > 0) {
                        // getIntentEntity(expressions)
                    }
                    return <RuleNodeDetails node={node} expressions={expressions} closeFunction={() => { setCurrentNode(null) }} skill={skill} getIntentList={(data) => getIntentList(data)} intentList={intentList} entityList={entityList} setShowMessageBanner={(data) => setShowMessageBanner(data)} setBannerMessage={(data) => setBannerMessage(data)} setBannerMessageType={(data) => setBannerMessageType(data)} setDataLoading={(data) => setDataLoading(data)} />
                }
                case 'ActionNode':
                    return <ActionNodeDetails node={node} open={currentNode} actions={actions} closeFunction={() => { setCurrentNode(null) }} />
                case 'JumpToNode':
                    return <JumpToNodeDetails node={node} open={currentNode} rules={rules} closeFunction={() => { setCurrentNode(null) }} />
                case 'ResponseNode':
                    return <ResponseNodeDetails node={node} open={currentNode} closeFunction={() => { setCurrentNode(null) }} />
                case 'ClearContextNode':
                    return <ClearContextNodeDetails node={node} open={currentNode} closeFunction={() => { setCurrentNode(null) }} />
                case 'ConditionNode':
                    return <ConditionNodeDetails node={node} open={currentNode} skillEntityList={entityList.slice(1)} ruleEntityList={entityAndContextVariableList.filter(value => value.name.startsWith('var_e_')).map(value => ({ label: value.name, value: value.name }))} systemEntityList={entityAndContextVariableList.filter(value => value.name.startsWith('var_i_')).map(value => ({ label: value.name, value: value.name }))} closeFunction={() => { setCurrentNode(null) }} />
            }
        }
    }
    const checkSlotsValidity = (slots) => {
        for (let st of slots) {
            if (st.entityName == '' || st.prompt == [] || st.save_as == '' || (st.type == 'custom' && (st.regex == '' || st.regex == undefined))) {
                return true;
            }
        }
        return false;
    }
    const checkActionAugment = (augment, type) => {

        if (type == 'api') {
            // if (augment.url == '' || augment.payload == "[{\"key\":\"Parm1\",\"value\":\"value1\"}]" || augment.headers == [] || ((augment.method == 'POST' || augment.method == 'PUT') && augment.params == []) || (augment.auth_type == 'oauth2' && (augment.client_id == '' || augment.client_secret == '' || augment.grant_type == '' || augment.oauth_url == ''))) {
            if (augment.api == undefined) {
                return true
            }
        }
        else if (type == 'db') {
            if (augment.query == '' || augment.db == undefined) {
                return true;
            }
        }
        else if (type == 'code') {
            if (augment.code == '' || augment.code === undefined) {
                return true;
            }
        }
        else {
            if (augment.link === undefined || augment.link.value == 'default') {
                return true;
            }
        }
        return false

    }

    const getCheckedEntities = (data) => {
        let count = 0;
        for (let d of data) {
            if (d.isChecked) {
                count += 1;
                break
            }
        }
        if (count == 1) {
            return false
        }
        else {
            return true
        }
    }

    const discardChanges = () => {
        setNodes(nodesCopy)
        setEdges(edgesCopy)
    }

    const checkNodesValidation = () => {
        for (let nd of nodes) {
            console.log(nd.data.prompt == [])
            if (nd.type == "RuleNode") {
                if (nd.data.name == '' || nd.data.description == '' || ((nd.data.targeted == false || nd.data.targeted == undefined) && (nd.data.targeted === true && (nd.data.intent.value == 'default' || nd.data.intent.value == 'newIntent'))) || checkSlotsValidity(nd.data.slots)) {
                    setBannerMessage('Please fill all the mandatory details in the Rule Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
            }
            if (nd.type == "ActionNode") {
                if (nd.data.name == '' || nd.data.description == '' || nd.data.type == undefined || checkActionAugment(nd.data, nd.data.type)) {
                    setBannerMessage('Please fill all the mandatory details in the Action Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
            }
            if (nd.type == 'ClearContextNode') {
                if (nd.data.name == '' || (nd.data.type == 'custom' && getCheckedEntities(nd.data.entities))) {
                    setBannerMessage('Please fill all the mandatory details in the Clear Context Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
            }
            if (nd.type == 'JumpToNode') {
                if (nd.data.name == '' || nd.data.toNode.value == 'default') {
                    setBannerMessage('Please fill all the mandatory details in the JumpTo Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
            }
            if (nd.type == 'ResponseNode') {
                if (nd.data.name == '' || nd.data.name == undefined || nd.data.name.length < 5) {
                    setBannerMessage('Please fill the name of Response Node')
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
                if (nd.data.showFormatScript == true && nd.data.formatScript == '') {
                    setBannerMessage('Please write code in formatScript of the Response Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
                if (nd.data.type == 'card' && nd.data.code == '') {
                    setBannerMessage('Please add the payload for the adaptive card response in the Response Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
                console.log("response", nd.data.response)
                if (nd.data.type == 'text' && (nd.data.response.length == 0 || nd.data.response[0] == '')) {
                    console.log('inside this condition')
                    setBannerMessage('Please add static responses in the Response Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
                if (nd.data.type == 'file' && (parseInt(nd.data.rows) == 0 || parseInt(nd.data.columns) == 0)) {
                    setBannerMessage('Please fill all the mandatory fields for the file response in the Response Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
                if (nd.data.type == '') {
                    setBannerMessage('Please fill all the mandatory details in the Response Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
            }
            if (nd.type == 'ConditionNode') {
                if (nd.data.name == '') {
                    setBannerMessage('Please fill all the mandatory details in the Condition Node - ' + nd.data.name)
                    setBannerMessageType('error')
                    setShowMessageBanner(true);
                    return
                }
            }
        }
        saveDialogFlow()
    }

    const saveDialogFlow = () => {

        setDataLoading(true);
        if (nodes[0].data.targeted && nodes[0].data.targeted === true) {
            let temp_node = nodes[0]
            let temp = nodes[0].data;
            temp = {
                "slots": temp_node.data.slots,
                "name": temp_node.data.name,
                "description": temp_node.data.description,
                "open": temp_node.data.open,
                "skill": temp_node.data.skill,
                "rule": temp_node.data.rule,
                "showConfirmation": temp_node.data.showConfirmation,
                "targeted": temp_node.data.targeted
            }
            console.log("temp inside useeffect", temp);
            temp_node = { ...temp_node, "data": { ...temp } };
            nodes[0] = { ...temp_node };
        }

        console.log("nodes", nodes)
        let payload = {
            'nodes': nodes,
            'edges': edges
        }
        console.log(payload)
        DialogService.saveDialog(skill.value, payload).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setDataLoading(false);
                setShowMessageBanner(true);
                getRuleList(skill)
                // localStorage.setItem('rule_name', rule.label)
                // localStorage.getItem('rule_value', rule.value)
                setBannerMessageType('success');
                setBannerMessage(res['details']);
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Something went wrong. Please try again later!");
            setShowMessageBanner(true);
        })
    }

    const restoreNodes = () => {
        setNodes(nodes => [...nodes, deletedNodes.pop()])
    }
    return (
        <>
            {!isDataLoading && <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                    buttonNext: {
                        background: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    buttonBack: {
                        color: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    buttonSkip: {
                        color: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                }}
            />}
            {
                isDataLoading && (<Spinner></Spinner>)
            }

            <div id='dialog_view' className='pageTour1'>
                <div style={{ display: width < 1199.98 ? 'none' : 'block' }}>
                    <div id='dialogScreenHeader' className='base-margin'>
                        <div id='dialogScreenHeaderContainer' className='row pageTour2'>
                            {/* <h5 className='col-2'>Dialog View</h5> */}

                            <div className="col-3" style={{ zIndex: 100 }}>{skillName()}</div>
                            <div className="col-3" style={{ zIndex: 100 }}>{listRules()}</div>
                            <div className="col-2"></div>
                            <div className="col-4" >
                                <button style={{ float: 'right' }} className="btn btn--secondary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left" onClick={() => checkNodesValidation()} >Save</button>
                                <button style={{ float: 'right' }} className="btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left" onClick={() => discardChanges()} >Discard</button>
                                {deletedNodes.length !== 0 && <button style={{ float: 'right' }} className='btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left' onClick={() => restoreNodes()} >Restore Deleted</button>}

                                {/* <Link to="/entity" style={{ float: 'right' }} className="btn btn--secondary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left">Entity View</Link>
                                <Link to="/intents" style={{ float: 'right' }} className="btn btn--secondary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down">Intent View</Link> */}
                            </div>
                        </div>
                    </div>
                    {/* <hr /> */}
                    {
                        showMessageBanner && <div style={{ position: 'fixed', zIndex: 9, left: '50%', transform: 'translate(-50%, 0)' }}><MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner></div>
                    }
                    {<SplitScreenWrapper leftPanelElement={getReactFlowProvider()}
                        rightPanelElement={getSplitScreenContent(currentNode)}
                        isSplitScreenEnabled={currentNode && currentNode.data && currentNode.data.showConfirmation == false && !showRuleModal && !showCreateSkill && !closeSplitScreen} />}
                    {showGuidance && <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                        <span>It seems like no skill has been created. To create one, please click on <b>Create Skill</b> option from the above dropdown. You can also create a skill by clicking on the <b>Skill View Screen</b> (to create a new skill) or you can explore the <b>Skill library</b> (to reuse already created skills) from the sidebar menu. In case you need any help, please click on the <b>Help</b> option or converse with the <b>AskBotLite chatbot</b>.</span>
                    </div>}
                    {
                        !showGuidance && showMessageBanner && (isDataLoading === false) && <div style={{ position: 'fixed', fontColor: 'red' }}><MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner></div>
                    }

                </div>

                {openRuleModal()}
                {openActionModal()}
                {openClearContextModal()}
                {openJumpToModal()}
                {openConditionModal()}
                {openResponseModal()}
                {warningMessage()}
                {warningMessageRule()}
                {warningMessageReload()}
                {showNodeTarget && askDestination()}
                {showCreateSkill && skillCreation()}
                <div style={{ display: width < 1199.98 ? 'block' : 'none' }}>
                    <MessageBanner message={"This device doesn't support to view this screen. This view is restricted only for laptops and tablets. Please open it in a different device."} messageType={'error'}></MessageBanner>
                </div>
            </div>
        </>
    );
};
export default DialogView;
