export const ACTION_TYPE_OPTIONS = [
  { value: "api", label: "API" },
  { value: "text", label: "Static" },
  { value: "db", label: "DB" },
];

export const BOT_TYPE_OPTIONS = {
  status: "success",
  bots: [
    { bot: "indiaholiday", number_of_message: 100 },
    { bot: "ut_agent", number_of_message: 50 },
    { bot: "conversations", number_of_message: 20 },
  ],
};

export const CHART_KEYS = {
  BOT: {
    NEW_USERS: "Bot_Chart_Key_1",
    ACTIVE_USERS: "Bot_Chart_Key_2",
    TOTAL_USERS: "Bot_Chart_Key_3",
    TOTAL_MESSAGES: "Bot_Chart_Key_4",
    MISS_RATE: "Bot_Chart_Key_5",
    HIT_RATE: "Bot_Chart_Key_6",
    BOT_HEALTH: "Bot_Chart_Key_7",
    PEAK_HOUR: "Bot_Chart_Key_8",
    PEAK_HOUR_TRAFFIC: "Bot_Chart_Key_9",
    AVG_RESPONSE_TIME: "Bot_Chart_Key_10",
    AVG_RESPONSE_TIME_SEVEN_DAYS: "Bot_Chart_Key_11",
    AVG_RESPONSE_TIME_BY_ACTION: "Bot_Chart_Key_12",
    TOP_RULES: "Bot_Chart_Key_13",
    TOP_INTENTS: "Bot_Chart_Key_14",
    FREQUENCY_OF_RULES: "Bot_Chart_Key_15",
    MOST_USED_KEYWORDS: "Bot_Chart_Key_16",
    HIT_AND_MISS: "Bot_Chart_Key_17",
    RESPONSE_WRT_DIFFERENT_ACTION: "Bot_Chart_Key_18",
    RESPONSE_TIME_PER_RULE: "Bot_Chart_Key_19",
    USAGE_BY_COUNTRY: "Bot_Chart_Key_20",
    AVG_RESPONSE_TIME_INCOMING_MSG: "Bot_Chart_Key_21",
    BOT_USAGE_TREND_PER_DAY: "Bot_Chart_Key_22",
    TOP_10_USERS: "Bot_Chart_Key_23",
    PEAK_HOUR_FLOW: "Bot_Chart_Key_24",
    DAILY_USAGE: "Bot_Chart_Key_25",
  },

  PLATFORM: {
    END_USERS: "Platform_Chart_Key_1",
    ACTIVE_USERS: "Platform_Chart_Key_2",
    SKILL_CREATED: "Platform_Chart_Key_3",
    BOT_CONFIGURED: "Platform_Chart_Key_4",
    ACTIVE_BOTS_Q4: "Platform_Chart_Key_5",
    ACTIVE_BOTS_LAST_MONTHS: "Platform_Chart_Key_6",
    TOTAL_MESSAGES: "Platform_Chart_Key_7",
    RULES_CONFIGURED: "Platform_Chart_Key_8",
    RULES_IDENTIFIED: "Platform_Chart_Key_9",
    ENTITIES: "Platform_Chart_Key_10",
    INTENTS: "Platform_Chart_Key_11",
    ACTIONS_CONFIGURED: "Platform_Chart_Key_12",
    TOP_BOTS_BY_USAGE: "Platform_Chart_key_13",
    TOP_BOTS_BY_USERS: "Platform_Chart_key_14",
    MOST_USED_KEYWORDS: "Platform_Chart_key_15",
  },

  SKILL: {
    TOTAL_SKILL_CLONED: "Skill_Chart_Key_1",
    TOTAL_VERSIONS: "Skill_Chart_Key_2",
    TOTAL_ENTITIES: "Skill_Chart_Key_3",
  },
};

export const CHART_INFO = {
  BOT: {
    NEW_USERS:
      "This metric provides insight into the no of new users who have joined the bot in the last 12 days.",
    ACTIVE_USERS:
      "This metric provides insight into the no of active users who have interacted with the bot yesterday.",
    TOTAL_USERS:
      " This metric provides insight into the total no of users using the bot.",
    TOTAL_MESSAGES:
      "This metric gives information on the total no of messages interacted with the bot.",
    MISS_RATE:
      "This metric shows the percentage of total Miss to total queries made to the bot. For more info please refer botlite analytics section of the sharepoint page. You can go to the sharepoint page by clicking on the '?' icon on the top right.",
    HIT_RATE:
      "This metric shows the percentage of total Hits to total queries made to the bot. For more info please refer botlite analytics section of the sharepoint page. You can go to the sharepoint page by clicking on the '?' icon on the top right.",
    BOT_HEALTH:
      "Health of the bot is calculated wrt weekly avg response time. For more info please refer botlite analytics section of the sharepoint page. You can go to the sharepoint page by clicking on the '?' icon on the top right.",
    PEAK_HOUR: "The hour in which the traffic was max.",
    PEAK_HOUR_TRAFFIC: "The total no of messages sent during the peak hour.",
    AVG_RESPONSE_TIME: "This is avg response time from beginning till now",
    AVG_RESPONSE_TIME_SEVEN_DAYS:
      "This is avg response time for the last 7 days",
    AVG_RESPONSE_TIME_BY_ACTION:
      "This is avg response time for each of the different actions",
    TOP_RULES:
      "This metric shows the top rules that are extensively used by the users.",
    TOP_INTENTS:
      "This metric shows the top 5 intents which are extensively used by the users.",
    FREQUENCY_OF_RULES:
      "This metric shows the top rules that are extensively used by the users in a particular region",
    MOST_USED_KEYWORDS: "Frequently used top keyords from the input message.",
    HIT_AND_MISS:
      "This metrics shows the total hit and miss rate for the past 7 days.",
    RESPONSE_WRT_DIFFERENT_ACTION:
      "Percentage of response wrt different actions.",
    RESPONSE_TIME_PER_RULE:
      "This metric shows the average response time of each rule.",
    USAGE_BY_COUNTRY:
      "This metric shows diversity of the users for the bot across geograhpy and the percentage of usage in each of the countries.",
    AVG_RESPONSE_TIME_BOT:
      "This is the average response time of the bot (from the starting).",
    AVG_RESPONSE_TIME_INCOMING_MSG:
      "This metric shows the average response time and the incoming traffic to the bot per each hour of the day.",
    BOT_USAGE_TREND_PER_DAY:
      "This metric shows the trend of the total bot usage based on the days of the week. This will help the user to perceive information about when the engagement of the bot is the least and the most.",
    TOP_10_USERS:
      "This metric provides insight into the top users using the bot repeatedly. This is represented using a word cloud in which the size of each user cecid indicates how often they have interacted with the bot.",
    PEAK_HOUR_FLOW: "The hour in which the traffic was max.",
    DAILY_USAGE:
      "This metric provides insight into the usage of a bot for the current month. Calculated using No. of hits (messages) for each day.",
  },
  PLATFORM: {
    END_USERS:
      "This metric shows the total number of Users that have used the Platform.",
    ACTIVE_USERS:
      "This metric shows the total number of Users that have used the Platform in the last 30 days.",
    SKILL_CREATED: "This metric shows the total number of Skills created.",
    BOT_CONFIGURED: "This metric shows the total number of Bots configured.",
    ACTIVE_BOTS_Q4:
      "This metric shows the count of Bots that were active in the previous Quarter.",
    ACTIVE_BOTS_LAST_MONTHS:
      "This metric shows the count of Bots that were active in the last 30 days.",
    TOTAL_MESSAGES:
      "This metric shows the total number of User queries across all Bots.",
    RULES_CONFIGURED:
      "This metric gives the total number of Rules configured across all Bots.",
    RULES_IDENTIFIED:
      "This metric gives the total number of Rules processed by Botlite platform.",
    ENTITIES:
      "This metric gives the total number of Entities configured across all Bots.",
    INTENTS:
      "This metric gives the total number of Intents configured across all Bots.",
    ACTIONS_CONFIGURED:
      "This metric gives the total number of Actions configured.",
    TOP_BOTS_BY_USAGE: "This metric shows the top 10 Bots based on usage.",
    TOP_BOTS_BY_USERS:
      "This metric shows the top 10 Bots by the number of users.",
    MOST_USED_KEYWORDS:
      "This Metric gives an insight into the top keywords from the input messages.",
  },
  SKILL: {
    TOTAL_SKILL_CLONED:
      "This metric gives the number of cloned skill versions.",
    TOTAL_VERSIONS:
      "This metric gives the total number of versions created for a particular skill.",
    TOTAL_ENTITIES:
      "This metric gives the total number of entities created for a particular skill.",
  },
};

export const CHART_DOWNLOAD_INFO = {
  BOT: {
    NEW_USERS: "List of all the New Users joined in the last 12 days",
    ACTIVE_USERS: "List of all the Active Users for the last 12days",
    MISS_RATE:
      "All missed queries.  For more info please refer botlite analytics section of the sharepoint page. You can go to the sharepoint page by clicking on the '?' icon on the top right.",
    PEAK_HOUR_TRAFFIC: "Top 50 most time taken queries",
  },
  PLATFORM: {
    BOT_CONFIGURED: "Download Bot requestor Data.",
    TOP_BOTS_BY_USAGE:
      "Download usage data for all the Bots for the last 12 Months.",
    TOP_BOTS_BY_USERS:
      "Download user (user count per Bot) data for all the Bots for the last 12 Months.",
  },
};

export const CHART_HEADERS = {
  BOT: {
    NEW_USERS: "New Users",
    ACTIVE_USERS: "Active Users",
    TOTAL_USERS: "Total Users Count",
    TOTAL_MESSAGES: "Total Messages",
    MISS_RATE: "Miss Rate",
    HIT_RATE: "Hit Rate",
    BOT_HEALTH: "Bot Health",
    PEAK_HOUR: "Peak Hour",
    PEAK_HOUR_TRAFFIC: "Peak Hour Traffic",
    AVG_RESPONSE_TIME: "Avg Response Time",
    AVG_RESPONSE_TIME_SEVEN_DAYS: "Avg Response Time",
    AVG_RESPONSE_TIME_BY_ACTION: "Avg Response Time",
    TOP_RULES: "Top 5 Rules",
    TOP_INTENTS: "Top 5 Intents",
    FREQUENCY_OF_RULES: "Frequency of Rules per Region",
    MOST_USED_KEYWORDS: "Top Keywords in Input Messages ",
    HIT_AND_MISS: "Hit & Miss",
    RESPONSE_WRT_DIFFERENT_ACTION: "Response wrt Different Actions",
    RESPONSE_TIME_PER_RULE: "Response Time Per Rule",
    USAGE_BY_COUNTRY: "Usage by Country",
    AVG_RESPONSE_TIME_BOT: "Average Response Time by Mindmeld",
    AVG_RESPONSE_TIME_INCOMING_MSG: "Average Response Time/Incoming Messages",
    BOT_USAGE_TREND_PER_DAY: "Bot Usage Trend per Day",
    TOP_10_USERS: "Top 10 Users",
    PEAK_HOUR_FLOW: "Peak Hour Traffic",
    DAILY_USAGE: "Daily Usage",
  },

  PLATFORM: {
    END_USERS: "End Users",
    ACTIVE_USERS: "Active Users",
    SKILL_CREATED: "Skills created",
    BOT_CONFIGURED: "Bots configured",
    ACTIVE_BOTS_Q4: "Active Bots",
    ACTIVE_BOTS_LAST_MONTHS: "Active Bots",
    TOTAL_MESSAGES: "Total Messages",
    RULES_CONFIGURED: "Rules configured",
    RULES_IDENTIFIED: "Rules identified",
    ENTITIES: "Entities",
    INTENTS: "Intents",
    ACTIONS_CONFIGURED: "Actions configured",
    TOP_BOTS_BY_USAGE: "Top 10 Bots by usage",
    TOP_BOTS_BY_USERS: "Top 10 Bots by users",
    MOST_USED_KEYWORDS: "What are the most used keywords?",
  },
  SKILL: {
    TOTAL_SKILL_CLONED: "Total Skills Cloned",
    TOTAL_VERSIONS: "Total Versions",
    TOTAL_ENTITIES: "Total Entities",
    AVG_RESPONSE_TIME: "Avg Response Time",
    RETENTION_RATE: "Retention Rate",
  },
};

export const DEV_CHART_IDS = {
  BOT: {
    NEW_USERS: "16f3a5e1-b0b0-464a-9b29-ff5b64f9c165",
    ACTIVE_USERS: "64ff31d5-ff4d-477a-8441-0b485631b214",
    TOTAL_USERS: "643e2a35-dfb8-479e-8e3a-e636020a3772",
    TOTAL_MESSAGES: "643e2d7b-33e4-4fa0-87ce-4dfb0a568329",
    MISS_RATE: "64e8ae55-02b3-417a-81b3-7de685f3f505",
    HIT_RATE: "64e8b3bc-b948-4918-8d3a-a9fd3d986b42",
    BOT_HEALTH: "797b9f15-a091-47f6-b2b2-9094ea369934",
    PEAK_HOUR: "",
    PEAK_HOUR_TRAFFIC: "",
    AVG_RESPONSE_TIME: "92cfa2c9-2780-4f9e-8382-2dfd535ea7e1",
    AVG_RESPONSE_TIME_SEVEN_DAYS: "797b9f15-a091-47f6-b2b2-9094ea369934",
    AVG_RESPONSE_TIME_BY_ACTION: "92cfa2c9-2780-4f9e-8382-2dfd535ea7e1",
    TOP_RULES: "6413f8b7-8b24-4dcd-8c32-f28ee0913d64",
    TOP_INTENTS: "650adfd3-39fb-4925-8aea-aef77d533381",
    FREQUENCY_OF_RULES: "07922648-f962-48c1-8280-6f699f739f0f",
    MOST_USED_KEYWORDS: "752fe9ae-f636-4cfe-b0e4-bdc01f423b98",
    HIT_AND_MISS: "047f09be-937b-4f5e-bfe6-dc7dbada74bb",
    RESPONSE_WRT_DIFFERENT_ACTION: "f915aeda-4b56-481f-bab5-569402a50e05",
    RESPONSE_TIME_PER_RULE: "ffaf1075-3138-4baa-aa53-2ad167306b61",
    USAGE_BY_COUNTRY: "6502d9e2-4716-4256-8d61-bdb81e62723a",
    AVG_RESPONSE_TIME_INCOMING_MSG: "641c608e-6162-4e3d-84e2-fe711c85ec42",
    BOT_USAGE_TREND_PER_DAY: "641c3b4c-96b5-49e4-8319-3dd9a7b5d90d",
    TOP_10_USERS: "66b197b0-6405-4ebe-a6cc-93ecfe4c13b5",
    PEAK_HOUR_FLOW: "65042100-1993-4eac-878b-7f8e2a5537fc",
    DAILY_USAGE: "654a3d8e-8796-41fb-86b7-794400704328",
  },
  PLATFORM: {
    END_USERS: "643e2a35-dfb8-479e-8e3a-e636020a3772",
    ACTIVE_USERS: "643e2ada-64f7-476d-895f-0988dca0d63c",
    SKILL_CREATED: "643e2b46-14db-47ad-83db-97d9cbb7656a",
    BOT_CONFIGURED: "643e2b7b-f650-4e8a-8f40-2184908ba34a",
    ACTIVE_BOTS_Q4: "2e4eac68-685b-477c-9aa2-c16ca0043b00",
    ACTIVE_BOTS_LAST_MONTHS: "643e2c93-f1c9-4408-8fee-53b0aaed85cd",
    TOTAL_MESSAGES: "643e2d7b-33e4-4fa0-87ce-4dfb0a568329",
    RULES_CONFIGURED: "643e2db1-f650-4ef1-88d6-2184908ccd62",
    RULES_IDENTIFIED: "643e2ec5-42a8-4e0e-8ce9-3a49547d41a9",
    ENTITIES: "643e2f17-f1c9-4901-8933-53b0aaee91f9",
    INTENTS: "643e2f4f-64f7-44e3-802c-0988dca35a3e",
    ACTIONS_CONFIGURED: "643e2f89-14db-4d2d-830b-97d9cbb982ed",
    TOP_BOTS_BY_USAGE: "9ec3bf59-a67c-4d61-aa4d-0d7dd944efa8",
    TOP_BOTS_BY_USERS: "643e311a-cb03-44b5-8a29-920adaeec3b1",
    MOST_USED_KEYWORDS: "752fe9ae-f636-4cfe-b0e4-bdc01f423b98",
  },
  SKILL: {
    TOTAL_SKILL_CLONED: "d0748ceb-6e62-4cfc-b163-f92dcf3b6c00",
    TOTAL_VERSIONS: "64df7469-fca2-44b1-80c4-652c19a7831c",
    TOTAL_ENTITIES: "64e8657a-c453-4add-8915-f63c29cb4a0e",
  },
};

export const STAGE_CHART_IDS = {
  BOT: {
    NEW_USERS: "16f3a5e1-b0b0-464a-9b29-ff5b64f9c165",
    ACTIVE_USERS: "64ff31d5-ff4d-477a-8441-0b485631b214",
    TOTAL_USERS: "643e2a35-dfb8-479e-8e3a-e636020a3772",
    TOTAL_MESSAGES: "643e2d7b-33e4-4fa0-87ce-4dfb0a568329",
    MISS_RATE: "64e8ae55-02b3-417a-81b3-7de685f3f505",
    HIT_RATE: "64e8b3bc-b948-4918-8d3a-a9fd3d986b42",
    BOT_HEALTH: "a5211db7-1dd6-4052-b40a-50f6c8406f05",
    PEAK_HOUR: "",
    PEAK_HOUR_TRAFFIC: "",
    AVG_RESPONSE_TIME: "92cfa2c9-2780-4f9e-8382-2dfd535ea7e1",
    AVG_RESPONSE_TIME_SEVEN_DAYS: "a5211db7-1dd6-4052-b40a-50f6c8406f05",
    AVG_RESPONSE_TIME_BY_ACTION: "92cfa2c9-2780-4f9e-8382-2dfd535ea7e1",
    TOP_RULES: "6413f8b7-8b24-4dcd-8c32-f28ee0913d64",
    TOP_INTENTS: "650adfd3-39fb-4925-8aea-aef77d533381",
    FREQUENCY_OF_RULES: "07922648-f962-48c1-8280-6f699f739f0f",
    MOST_USED_KEYWORDS: "752fe9ae-f636-4cfe-b0e4-bdc01f423b98",
    HIT_AND_MISS: "047f09be-937b-4f5e-bfe6-dc7dbada74bb",
    RESPONSE_WRT_DIFFERENT_ACTION: "f915aeda-4b56-481f-bab5-569402a50e05",
    RESPONSE_TIME_PER_RULE: "ffaf1075-3138-4baa-aa53-2ad167306b61",
    USAGE_BY_COUNTRY: "6502d9e2-4716-4256-8d61-bdb81e62723a",
    AVG_RESPONSE_TIME_BOT: "641c3ea7-9e42-446c-85e2-5c794e38f363",
    AVG_RESPONSE_TIME_INCOMING_MSG: "641c608e-6162-4e3d-84e2-fe711c85ec42",
    BOT_USAGE_TREND_PER_DAY: "641c3b4c-96b5-49e4-8319-3dd9a7b5d90d",
    TOP_10_USERS: "66b197b0-6405-4ebe-a6cc-93ecfe4c13b5",
    PEAK_HOUR_FLOW: "65042100-1993-4eac-878b-7f8e2a5537fc",
    DAILY_USAGE: "654a3d8e-8796-41fb-86b7-794400704328",
  },
  PLATFORM: {
    END_USERS: "643e2a35-dfb8-479e-8e3a-e636020a3772",
    ACTIVE_USERS: "643e2ada-64f7-476d-895f-0988dca0d63c",
    SKILL_CREATED: "643e2b46-14db-47ad-83db-97d9cbb7656a",
    BOT_CONFIGURED: "643e2b7b-f650-4e8a-8f40-2184908ba34a",
    ACTIVE_BOTS_Q4: "2e4eac68-685b-477c-9aa2-c16ca0043b00",
    ACTIVE_BOTS_LAST_MONTHS: "643e2c93-f1c9-4408-8fee-53b0aaed85cd",
    TOTAL_MESSAGES: "643e2d7b-33e4-4fa0-87ce-4dfb0a568329",
    RULES_CONFIGURED: "643e2db1-f650-4ef1-88d6-2184908ccd62",
    RULES_IDENTIFIED: "643e2ec5-42a8-4e0e-8ce9-3a49547d41a9",
    ENTITIES: "643e2f17-f1c9-4901-8933-53b0aaee91f9",
    INTENTS: "643e2f4f-64f7-44e3-802c-0988dca35a3e",
    ACTIONS_CONFIGURED: "643e2f89-14db-4d2d-830b-97d9cbb982ed",
    TOP_BOTS_BY_USAGE: "9ec3bf59-a67c-4d61-aa4d-0d7dd944efa8",
    TOP_BOTS_BY_USERS: "643e311a-cb03-44b5-8a29-920adaeec3b1",
    MOST_USED_KEYWORDS: "752fe9ae-f636-4cfe-b0e4-bdc01f423b98",
  },
  SKILL: {
    TOTAL_SKILL_CLONED: "d0748ceb-6e62-4cfc-b163-f92dcf3b6c00",
    TOTAL_VERSIONS: "64df7469-fca2-44b1-80c4-652c19a7831c",
    TOTAL_ENTITIES: "64e8657a-c453-4add-8915-f63c29cb4a0e",
    AVG_RESPONSE_TIME: "92cfa2c9-2780-4f9e-8382-2dfd535ea7e1",
    RETENTION_RATE: "643e2a35-dfb8-479e-8e3a-e636020a3772",
  },
};

export const PROD_CHART_IDS = {
  BOT: {
    NEW_USERS: "6548939c-103b-456b-809a-a1d81e493c67",
    ACTIVE_USERS: "6548939c-103b-456d-892c-a1d81e493c69",
    TOTAL_USERS: "6548939c-103b-4ef1-8e39-a1d81e493c2f",
    TOTAL_MESSAGES: "6548939c-103b-4365-803f-a1d81e493c3b",
    MISS_RATE: "6548939c-103b-4f15-855b-a1d81e493c5a",
    HIT_RATE: "6548939c-103b-48b4-895d-a1d81e493c5c",
    BOT_HEALTH: "6548939c-103b-436e-85a4-a1d81e493c62",
    AVG_RESPONSE_TIME: "6548939c-103b-4dc3-80e9-a1d81e493c60",
    AVG_RESPONSE_TIME_SEVEN_DAYS: "6548939c-103b-436e-85a4-a1d81e493c62",
    AVG_RESPONSE_TIME_BY_ACTION: "6548939c-103b-4dc3-80e9-a1d81e493c60",
    TOP_RULES: "6548939c-103b-41f0-886f-a1d81e493c23",
    TOP_INTENTS: "6548939c-103b-4eea-8792-a1d81e493c78",
    FREQUENCY_OF_RULES: "6548939c-103b-4b42-89c7-a1d81e493c17",
    MOST_USED_KEYWORDS: "6548939c-103b-4f73-852c-a1d81e493c6f",
    HIT_AND_MISS: "6548939c-103b-469c-8683-a1d81e493c71",
    RESPONSE_WRT_DIFFERENT_ACTION: "6548939c-103b-42ca-8b0d-a1d81e493c19",
    RESPONSE_TIME_PER_RULE: "6548939c-103b-4564-8add-a1d81e493c1b",
    USAGE_BY_COUNTRY: "6548939c-103b-489a-8277-a1d81e493c73",
    AVG_RESPONSE_TIME_INCOMING_MSG: "6548939c-103b-4e90-89c8-a1d81e493c2b",
    BOT_USAGE_TREND_PER_DAY: "6548939c-103b-43bc-8aef-a1d81e493c25",
    TOP_10_USERS: "6548939c-103b-453b-8986-a1d81e493c6d",
    PEAK_HOUR_FLOW: "6548939c-103b-4344-8b5a-a1d81e493c75",
    DAILY_USAGE: "654a23aa-2b4e-406a-8dce-7ac717187401",
  },
  PLATFORM: {
    END_USERS: "6548939c-103b-4ef1-8e39-a1d81e493c2f",
    ACTIVE_USERS: "6548939c-103b-4f14-8652-a1d81e493c31",
    SKILL_CREATED: "6548939c-103b-4cf1-8b09-a1d81e493c33",
    BOT_CONFIGURED: "6548939c-103b-4a3a-8ba4-a1d81e493c35",
    ACTIVE_BOTS_Q4: "6548939c-103b-4886-83ee-a1d81e493c39",
    ACTIVE_BOTS_LAST_MONTHS: "6548939c-103b-4aa2-84de-a1d81e493c37",
    TOTAL_MESSAGES: "6548939c-103b-4365-803f-a1d81e493c3b",
    RULES_CONFIGURED: "6548939c-103b-4c3b-84a1-a1d81e493c3d",
    RULES_IDENTIFIED: "6548939c-103b-480f-8f2d-a1d81e493c3f",
    ENTITIES: "6548939c-103b-457b-8cdd-a1d81e493c41",
    INTENTS: "6548939c-103b-46e9-88bf-a1d81e493c43",
    ACTIONS_CONFIGURED: "6548939c-103b-47ab-8cbb-a1d81e493c45",
    TOP_BOTS_BY_USAGE: "6548939c-103b-4bcf-8717-a1d81e493c6b",
    TOP_BOTS_BY_USERS: "6548939c-103b-4848-8da5-a1d81e493c47",
    MOST_USED_KEYWORDS: "6548939c-103b-4f73-852c-a1d81e493c6f",
  },
  SKILL: {
    TOTAL_SKILL_CLONED: "6548939c-103b-4fcb-8e00-a1d81e493c65",
    TOTAL_VERSIONS: "6548939c-103b-4234-8396-a1d81e493c54",
    TOTAL_ENTITIES: "6548939c-103b-4dbc-8a9f-a1d81e493c58",
  },
};

export const CHART_IDS =
  process.env.REACT_APP_ENV === "prod"
    ? { ...PROD_CHART_IDS }
    : process.env.REACT_APP_ENV === "stage"
    ? { ...STAGE_CHART_IDS }
    : { ...DEV_CHART_IDS };

export const CHART_SUB_HEADERS = {
  OVER_ALL: "(Overall)",
  OVER_ALL_SECS: "(Overall (in secs))",
  LAST_30__DAYS: "(Last 30 days)",
  LAST_12_DAYS: "(Last 12 days)",
  LAST_7_DAYS: "(Last 7 Days)",
  LAST_7_DAYS_SECS: "(Last 7 days(in secs))",
  Q4: "(FY23Q4)",
  YESTERDAY: "(Yesterday)",
  YESTERDAY_GMT: "(Yesterday - GMT)",
  IN_SECS: "(in secs)",
};

export const SKILL_CHART_DETAILS = [
  {
    key: CHART_KEYS.SKILL.TOTAL_VERSIONS,
    id: CHART_IDS.SKILL.TOTAL_VERSIONS,
    header: CHART_HEADERS.SKILL.TOTAL_VERSIONS,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 0,
    y: 0,
    chartInfo: CHART_INFO.SKILL.TOTAL_VERSIONS,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.SKILL.TOTAL_ENTITIES,
    id: CHART_IDS.SKILL.TOTAL_ENTITIES,
    header: CHART_HEADERS.SKILL.TOTAL_ENTITIES,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 2,
    y: 0,
    chartInfo: CHART_INFO.SKILL.TOTAL_ENTITIES,
    downloadInfo: "",
  },
];

export const BOT_CHART_DETAILS = [
  {
    key: CHART_KEYS.BOT.NEW_USERS,
    id: CHART_IDS.BOT.NEW_USERS,
    header: CHART_HEADERS.BOT.NEW_USERS,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 1,
    y: 0,
    chartInfo: CHART_INFO.BOT.NEW_USERS,
    downloadInfo: CHART_DOWNLOAD_INFO.BOT.NEW_USERS,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.ACTIVE_USERS,
    id: CHART_IDS.BOT.ACTIVE_USERS,
    header: CHART_HEADERS.BOT.ACTIVE_USERS,
    subHeader: CHART_SUB_HEADERS.YESTERDAY,
    x: 3,
    y: 0,
    chartInfo: CHART_INFO.BOT.ACTIVE_USERS,
    downloadInfo: CHART_DOWNLOAD_INFO.BOT.ACTIVE_USERS,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.TOTAL_USERS,
    id: CHART_IDS.BOT.TOTAL_USERS,
    header: CHART_HEADERS.BOT.TOTAL_USERS,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 5,
    y: 0,
    chartInfo: CHART_INFO.BOT.TOTAL_USERS,
    downloadInfo: "",
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.TOTAL_MESSAGES,
    id: CHART_IDS.BOT.TOTAL_MESSAGES,
    header: CHART_HEADERS.BOT.TOTAL_MESSAGES,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 7,
    y: 0,
    chartInfo: CHART_INFO.BOT.TOTAL_MESSAGES,
    downloadInfo: "",
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.BOT_HEALTH,
    id: CHART_IDS.BOT.BOT_HEALTH,
    header: CHART_HEADERS.BOT.BOT_HEALTH,
    subHeader: CHART_SUB_HEADERS.LAST_7_DAYS,
    x: 9,
    y: 0,
    chartInfo: CHART_INFO.BOT.BOT_HEALTH,
    downloadInfo: "",
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.MISS_RATE,
    id: CHART_IDS.BOT.MISS_RATE,
    header: CHART_HEADERS.BOT.MISS_RATE,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 1,
    y: 6,
    chartInfo: CHART_INFO.BOT.MISS_RATE,
    downloadInfo: "",
    customDownLoadInfo: {API_PATH:"/analytics/miss_rate/<agent>",
                        HEADER_NAMES: ["Agent","Skill","User","User Text","Action_type","Country","State","City","Department"],
                        RESPONSE_ATTR: ["agent","skill","user","in_message","action_type","country","state","city","department"]},
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.HIT_RATE,
    id: CHART_IDS.BOT.HIT_RATE,
    header: CHART_HEADERS.BOT.HIT_RATE,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 3,
    y: 6,
    chartInfo: CHART_INFO.BOT.HIT_RATE,
    downloadInfo: "",
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.AVG_RESPONSE_TIME,
    id: CHART_IDS.BOT.AVG_RESPONSE_TIME,
    header: CHART_HEADERS.BOT.AVG_RESPONSE_TIME,
    subHeader: CHART_SUB_HEADERS.OVER_ALL_SECS,
    x: 5,
    y: 6,
    chartInfo: CHART_INFO.BOT.AVG_RESPONSE_TIME,
    downloadInfo: "",
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.AVG_RESPONSE_TIME_SEVEN_DAYS,
    id: CHART_IDS.BOT.AVG_RESPONSE_TIME_SEVEN_DAYS,
    header: CHART_HEADERS.BOT.AVG_RESPONSE_TIME_SEVEN_DAYS,
    subHeader: CHART_SUB_HEADERS.LAST_7_DAYS_SECS,
    x: 7,
    y: 6,
    chartInfo: CHART_INFO.BOT.AVG_RESPONSE_TIME_SEVEN_DAYS,
    downloadInfo: "",
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.AVG_RESPONSE_TIME_BY_ACTION,
    id: CHART_IDS.BOT.AVG_RESPONSE_TIME_BY_ACTION,
    header: CHART_HEADERS.BOT.AVG_RESPONSE_TIME_BY_ACTION,
    subHeader: CHART_SUB_HEADERS.IN_SECS,
    x: 9,
    y: 6,
    chartInfo: CHART_INFO.BOT.AVG_RESPONSE_TIME_BY_ACTION,
    downloadInfo: "",
    actions: ACTION_TYPE_OPTIONS,
    defaultAction: ACTION_TYPE_OPTIONS[0],
    chartData: null,
  },
];

export const PLATFORM_CHART_DETAILS = [
  {
    key: CHART_KEYS.PLATFORM.END_USERS,
    id: CHART_IDS.PLATFORM.END_USERS,
    header: CHART_HEADERS.PLATFORM.END_USERS,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 0,
    y: 0,
    chartInfo: CHART_INFO.PLATFORM.END_USERS,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.ACTIVE_USERS,
    id: CHART_IDS.PLATFORM.ACTIVE_USERS,
    header: CHART_HEADERS.PLATFORM.ACTIVE_USERS,
    subHeader: CHART_SUB_HEADERS.LAST_30__DAYS,
    x: 2,
    y: 0,
    chartInfo: CHART_INFO.PLATFORM.ACTIVE_USERS,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.SKILL_CREATED,
    id: CHART_IDS.PLATFORM.SKILL_CREATED,
    header: CHART_HEADERS.PLATFORM.SKILL_CREATED,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 4,
    y: 0,
    chartInfo: CHART_INFO.PLATFORM.SKILL_CREATED,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.BOT_CONFIGURED,
    id: CHART_IDS.PLATFORM.BOT_CONFIGURED,
    header: CHART_HEADERS.PLATFORM.BOT_CONFIGURED,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 6,
    y: 0,
    chartInfo: CHART_INFO.PLATFORM.BOT_CONFIGURED,
    downloadInfo: null,
  },
  {
    key: CHART_KEYS.PLATFORM.ACTIVE_BOTS_Q4,
    id: CHART_IDS.PLATFORM.ACTIVE_BOTS_Q4,
    header: CHART_HEADERS.PLATFORM.ACTIVE_BOTS_Q4,
    subHeader: CHART_SUB_HEADERS.Q4,
    x: 8,
    y: 0,
    chartInfo: CHART_INFO.PLATFORM.ACTIVE_BOTS_Q4,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.ACTIVE_BOTS_LAST_MONTHS,
    id: CHART_IDS.PLATFORM.ACTIVE_BOTS_LAST_MONTHS,
    header: CHART_HEADERS.PLATFORM.ACTIVE_BOTS_LAST_MONTHS,
    subHeader: CHART_SUB_HEADERS.LAST_30__DAYS,
    x: 10,
    y: 0,
    chartInfo: CHART_INFO.PLATFORM.ACTIVE_BOTS_LAST_MONTHS,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.TOTAL_MESSAGES,
    id: CHART_IDS.PLATFORM.TOTAL_MESSAGES,
    header: CHART_HEADERS.PLATFORM.TOTAL_MESSAGES,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 0,
    y: 6,
    chartInfo: CHART_INFO.PLATFORM.TOTAL_MESSAGES,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.RULES_CONFIGURED,
    id: CHART_IDS.PLATFORM.RULES_CONFIGURED,
    header: CHART_HEADERS.PLATFORM.RULES_CONFIGURED,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 2,
    y: 6,
    chartInfo: CHART_INFO.PLATFORM.RULES_CONFIGURED,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.RULES_IDENTIFIED,
    id: CHART_IDS.PLATFORM.RULES_IDENTIFIED,
    header: CHART_HEADERS.PLATFORM.RULES_IDENTIFIED,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 4,
    y: 6,
    chartInfo: CHART_INFO.PLATFORM.RULES_IDENTIFIED,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.ENTITIES,
    id: CHART_IDS.PLATFORM.ENTITIES,
    header: CHART_HEADERS.PLATFORM.ENTITIES,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 6,
    y: 6,
    chartInfo: CHART_INFO.PLATFORM.ENTITIES,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.INTENTS,
    id: CHART_IDS.PLATFORM.INTENTS,
    header: CHART_HEADERS.PLATFORM.INTENTS,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 8,
    y: 6,
    chartInfo: CHART_INFO.PLATFORM.INTENTS,
    downloadInfo: "",
  },
  {
    key: CHART_KEYS.PLATFORM.ACTIONS_CONFIGURED,
    id: CHART_IDS.PLATFORM.ACTIONS_CONFIGURED,
    header: CHART_HEADERS.PLATFORM.ACTIONS_CONFIGURED,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 10,
    y: 6,
    chartInfo: CHART_INFO.PLATFORM.ACTIONS_CONFIGURED,
    downloadInfo: "",
  },
];

export const BOT_CHART_DETAILS_2 = [
  {
    key: CHART_KEYS.BOT.TOP_RULES,
    id: CHART_IDS.BOT.TOP_RULES,
    header: CHART_HEADERS.BOT.TOP_RULES,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 0,
    y: 10,
    chartInfo: CHART_INFO.BOT.TOP_RULES,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.TOP_INTENTS,
    id: CHART_IDS.BOT.TOP_INTENTS,
    header: CHART_HEADERS.BOT.TOP_INTENTS,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 6,
    y: 10,
    chartInfo: CHART_INFO.BOT.TOP_INTENTS,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.AVG_RESPONSE_TIME_INCOMING_MSG,
    id: CHART_IDS.BOT.AVG_RESPONSE_TIME_INCOMING_MSG,
    header: CHART_HEADERS.BOT.AVG_RESPONSE_TIME_INCOMING_MSG,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 0,
    y: 20,
    chartInfo: CHART_INFO.BOT.AVG_RESPONSE_TIME_INCOMING_MSG,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.BOT_USAGE_TREND_PER_DAY,
    id: CHART_IDS.BOT.BOT_USAGE_TREND_PER_DAY,
    header: CHART_HEADERS.BOT.BOT_USAGE_TREND_PER_DAY,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 6,
    y: 20,
    chartInfo: CHART_INFO.BOT.BOT_USAGE_TREND_PER_DAY,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.MOST_USED_KEYWORDS,
    id: CHART_IDS.BOT.MOST_USED_KEYWORDS,
    header: CHART_HEADERS.BOT.MOST_USED_KEYWORDS,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 0,
    y: 30,
    chartInfo: CHART_INFO.BOT.MOST_USED_KEYWORDS,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.TOP_10_USERS,
    id: CHART_IDS.BOT.TOP_10_USERS,
    header: CHART_HEADERS.BOT.TOP_10_USERS,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 6,
    y: 30,
    chartInfo: CHART_INFO.BOT.TOP_10_USERS,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.USAGE_BY_COUNTRY,
    id: CHART_IDS.BOT.USAGE_BY_COUNTRY,
    header: CHART_HEADERS.BOT.USAGE_BY_COUNTRY,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 0,
    y: 40,
    chartInfo: CHART_INFO.BOT.USAGE_BY_COUNTRY,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.HIT_AND_MISS,
    id: CHART_IDS.BOT.HIT_AND_MISS,
    header: CHART_HEADERS.BOT.HIT_AND_MISS,
    subHeader: CHART_SUB_HEADERS.LAST_7_DAYS,
    x: 6,
    y: 40,
    chartInfo: CHART_INFO.BOT.HIT_AND_MISS,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.FREQUENCY_OF_RULES,
    id: CHART_IDS.BOT.FREQUENCY_OF_RULES,
    header: CHART_HEADERS.BOT.FREQUENCY_OF_RULES,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 0,
    y: 50,
    chartInfo: CHART_INFO.BOT.FREQUENCY_OF_RULES,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },

  {
    key: CHART_KEYS.BOT.RESPONSE_WRT_DIFFERENT_ACTION,
    id: CHART_IDS.BOT.RESPONSE_WRT_DIFFERENT_ACTION,
    header: CHART_HEADERS.BOT.RESPONSE_WRT_DIFFERENT_ACTION,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 6,
    y: 50,
    chartInfo: CHART_INFO.BOT.RESPONSE_WRT_DIFFERENT_ACTION,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.BOT.DAILY_USAGE,
    id: CHART_IDS.BOT.DAILY_USAGE,
    header: CHART_HEADERS.BOT.DAILY_USAGE,
    subHeader: CHART_SUB_HEADERS.LAST_12_DAYS,
    x: 0,
    y: 60,
    chartInfo: CHART_INFO.BOT.DAILY_USAGE,
    downloadInfo: null,
    customDownLoadInfo: {API_PATH:"/analytics/daily_usage/<agent>",
                        HEADER_NAMES: ["Agent","Skill","User","User Text","Action_type","Country","State","City","Department"],
                        RESPONSE_ATTR: ["agent","skill","user","in_message","action_type","country","state","city","department"]},
    actions: [],
    defaultAction: null,
    chartData: null,
  },

  // {
  //   key: CHART_KEYS.BOT.PEAK_HOUR_FLOW,
  //   id: CHART_IDS.BOT.PEAK_HOUR_FLOW,
  //   header: CHART_HEADERS.BOT.PEAK_HOUR_FLOW,
  //   subHeader: CHART_SUB_HEADERS.PEAK_HOUR_FLOW,
  //   x: 0,
  //   y: 70,
  //   chartInfo: CHART_INFO.BOT.PEAK_HOUR_FLOW,
  //   downloadInfo: null,
  //   actions: [],
  //   defaultAction: null,
  //   chartData: null,
  // },
];

export const PLATFORM_CHART_DETAILS_2 = [
  {
    key: CHART_KEYS.PLATFORM.TOP_BOTS_BY_USAGE,
    id: CHART_IDS.PLATFORM.TOP_BOTS_BY_USAGE,
    header: CHART_HEADERS.PLATFORM.TOP_BOTS_BY_USAGE,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 0,
    y: 10,
    chartInfo: CHART_INFO.PLATFORM.TOP_BOTS_BY_USAGE,
    downloadInfo: CHART_DOWNLOAD_INFO.PLATFORM.TOP_BOTS_BY_USAGE,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.PLATFORM.TOP_BOTS_BY_USERS,
    id: CHART_IDS.PLATFORM.TOP_BOTS_BY_USERS,
    header: CHART_HEADERS.PLATFORM.TOP_BOTS_BY_USERS,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 6,
    y: 10,
    chartInfo: CHART_INFO.PLATFORM.TOP_BOTS_BY_USERS,
    downloadInfo: CHART_DOWNLOAD_INFO.PLATFORM.TOP_BOTS_BY_USERS,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
  {
    key: CHART_KEYS.PLATFORM.MOST_USED_KEYWORDS,
    id: CHART_IDS.PLATFORM.MOST_USED_KEYWORDS,
    header: CHART_HEADERS.PLATFORM.MOST_USED_KEYWORDS,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 0,
    y: 20,
    chartInfo: CHART_INFO.PLATFORM.MOST_USED_KEYWORDS,
    downloadInfo: null,
    actions: [],
    defaultAction: null,
    chartData: null,
  },
];

export const SKILL_CHART_DETAILS_2 = [
  {
    key: CHART_KEYS.SKILL.TOTAL_SKILL_CLONED,
    id: CHART_IDS.SKILL.TOTAL_SKILL_CLONED,
    header: CHART_HEADERS.SKILL.TOTAL_SKILL_CLONED,
    subHeader: CHART_SUB_HEADERS.OVER_ALL,
    x: 6,
    y: -1,
    chartInfo: CHART_INFO.SKILL.TOTAL_SKILL_CLONED,
    downloadInfo: "",
  },
];
