import './EntityViewComponent.css';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import EntityEditPanel from '../entity-edit-component/EntityEditComponent';
import Entity from '../entity-component/EntityComponent';
import EntityService from '../../entity-services/EntityService';
import SplitScreenWrapper from '../../../../custom-components/split-screen-wrapper/SplitScreenWrapper';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useEffect } from 'react';
import SkillService from '../../../skill-module/skill-services/SkillService';
import SearchSelect from '../../../../custom-components/search-select/SearchSelect';
import Spinner from '../../../../custom-components/spinner/Spinner';
import MessageBanner from '../../../../custom-components/MessageBanner/MessageBanner';
import FileDownloader from '../../../../utils/FileDownloader';

const EntitiesView = () => {
    const [show_entity_edit_panel, setEditEntityEditPanel] = useState(false);
    const [entitySelected, selectEntity] = useState({});
    const [selectedEntityIndex, setSelectedEntityIndex] = useState(-1);
    const [entityCheckBoxList, setEntityCheckboxList] = useState([]);
    const [isEntityCheckBoxSelected, setIsEntityCheckBoxSelected] = useState(false);
    const [showCreateEntities, setCreateEntities] = useState(false);
    const [xPositionOfFloatButton, setXPositionOfFloatButton] = useState("calc(100% - 50px)");
    const [showCreateSkill, setShowCreateSkill] = useState();
    const [skillAction, setSkillAction] = useState([{ name: "", description: "" }]);
    const [skillSubmitBtnEnable, setSkillSubmitBtnEnable] = useState(false)
    const [skills, setSkills] = useState([])
    const [skillObjList, setSkillObjList] = useState([]);
    const [selectedSkill, setSkill] = useState({ label: 'Select a Skill', value: 'default' });
    const [isSkillReadOnly, setIsSkillReadOnly] = useState(false);
    const [selectedSkillObj, setSelectedSkillObj] = useState()
    const [isDataLoading, setDataLoading] = useState(true);
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [showGuidance, setShowGuidance] = useState(false)
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [showDeleteEntityScreen, setShowDeleteEntityScreen] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false)
    const [layoutType, setLayoutType] = useState(
        window.innerWidth > 1000 ? "verticle" : "horizontal"
    );
    const [mobileViewHeightOffset, setMobileViewOffset] = useState('100px');
    useEffect(() => {
        let alertDiv = document.querySelector(".alert");
        if (alertDiv) {
            console.log(alertDiv.clientHeight);
            let divHeight = String(alertDiv.clientHeight + 100);
            setMobileViewOffset(divHeight + "px");
        }

        else {
            setMobileViewOffset('100px');
        }
    })
    const handleResize = () => {
        setLayoutType(window.innerWidth > 1000 ? "verticle" : "horizontal");
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        getSkills(1);
    }, [])

    useEffect(() => {
        setIsEntityCheckBoxSelected(false);
        for (let val of entityCheckBoxList) {
            if (val) {
                setIsEntityCheckBoxSelected(true);
                break;
            }
        }
    }, [entityCheckBoxList])

    const getSkills = (index) => {
        SkillService.listSkills().then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                if (res.skills.length > 0) {
                    setSkillObjList(res.skills);
                    var vals = res.skills.map((data) => {
                        return (
                            { "label": data.name, "value": data._id }
                        )
                    })
                    vals.unshift({ label: 'Create Skill', value: 'newSkill' })
                    setSkills(vals)
                    if (localStorage.getItem("skill_name") && localStorage.getItem("skill_value")) {
                        setSkill({ label: localStorage.getItem("skill_name"), value: localStorage.getItem("skill_value") })
                        setSelectedSkillObj({ label: localStorage.getItem("skill_name"), value: localStorage.getItem("skill_value") });
                        getEntityData(localStorage.getItem("skill_value"));

                    }
                    else {
                        console.log("inside")
                        setSkill(vals[index])
                        setSelectedSkillObj(res.skills[index - 1]);
                        getEntityData(vals[index].value);

                    }

                    // setSelectedSkillObj(res.skills[index - 1]);
                    // getEntityData(vals[index].value);
                    setShowGuidance(false);
                }
                else {
                    var vals = [{ label: 'Create Skill', value: 'newSkill' }]
                    setSkills(vals)
                    setSkill({ label: 'Select a Skill', value: 'default' })
                    setDataLoading(false);
                    setShowGuidance(true);
                }
            }
            else {
                console.log("Failed to retrieve Skills");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve Skills!");
            setShowMessageBanner(true);
        })
    }

    const isReadOnlySkill = () => {
        let readOnly = false;
        if (selectedSkillObj == undefined) {
            readOnly = false;
        } else if (selectedSkillObj.read_only && selectedSkillObj.read_only == true) {
            readOnly = true;
        }
        console.log("readOnly: " + readOnly);
        return readOnly;
    }

    const onEntityEditTabClose = (task) => {
        if (task === "save") {
            //reload the updated data
            getEntityData(selectedSkill.value);
        }
        setEditEntityEditPanel(false);
        setXPositionOfFloatButton("calc(100% - 50px)");
    }

    const onEntityEditTabOpend = (entity, index) => {
        selectEntity(entity);
        setSelectedEntityIndex(index);
        setEditEntityEditPanel(true);
        setXPositionOfFloatButton("calc(50% - 50px)");

    }
    const [entity_list, setEntityList] = useState([]);

    //this function validates if entity with same name already exist or not and return bool 
    const validateEntityDuplication = (newEntityName) => {
        for (let entity of entity_list) {
            if (entity["name"] === newEntityName) {
                return false;
            }
        }
        return true;
    }

    const onSplitScreenResized = (leftWidth, rightWidth) => {
        setXPositionOfFloatButton(`calc(${leftWidth}% - 50px)`);
    }
    useEffect(() => {
        setShowGuidance(false);
        if (isDataLoading === false && entity_list && entity_list.length === 0) {
            setShowGuidance(true);
        }
    }, [entity_list, isDataLoading])

    const addSkill = () => {
        setDataLoading(true);
        // let len = skills.length
        // let sample_skills = [...skills]
        // sample_skills.push(newSkill)
        // setSkills(sample_skills)
        let payload = skillAction[0];
        payload["shared"] = false;

        SkillService.createSkill(payload).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                getSkills(skills.length);
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage('A new Skill has been created successfully!');
                setShowMessageBanner(true);
                setShowGuidance(false);
            }
            else {
                console.log("Failed to retrieve Skills");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve Skills!");
            setShowMessageBanner(true);
        })


    }
    const skillInputHandlerChange = (event, type) => {
        setShowValidationErrorMessage(false);
        // if (type === "name")
        //     setNewSkill({ label: event.target.value, value: event.target.value })
        let newdata = skillAction[0];
        newdata[type] = event.target.value
        setSkillAction([newdata])
        const empty = hasEmpty(newdata);
        let valid;
        if (!empty) {
            valid = validateSkillCreationForm(type, newdata)
        }
        else {
            valid = validateSkillCreationForm("all", newdata)
        }

        const result = empty && valid
        // if (result) {
        //     // setNewSkill({ label: newdata['name'], value: newdata['name'] })
        // }
        setSkillSubmitBtnEnable(result);
    }
    const hasEmpty = (element) => {
        return (
            (element.name != "" && element.name.length > 1) &&
            (element.description != "" && element.description.length > 19)
        );
    };
    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }
    const validateSkillCreationForm = (key, skillData) => {

        if (key == "name" || key == "all") {
            if (skillData.name === '' || skillData.name.length < 3) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Skill name should be of minimum 3 characters.');
                return false;

            }

            if (!isAlphanumeric(skillData.name, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in skill name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (skillData.description === '' || skillData.description.length < 20) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Skill description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(skillData.description)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in skill description.');
                return false;
            }
        }
        return true;
    }


    const skillCreation = () => {
        return <Modal size="md" centered show={showCreateSkill} onHide={() => { setShowCreateSkill(false) }} animation={false} >
            <Modal.Header >
                <h5>Create a Skill</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowCreateSkill(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }
                <form >
                    <div className='center base-padding'>
                        <div className='warningMessage txtAlignRight'>Please fill the (*) mandatory fields</div>
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                <input id="input-type-number" type="text" autoFocus name="skill_name" onChange={event => { skillInputHandlerChange(event, "name") }} />
                                <label htmlFor="input-type-number">Skill Name <span className='required'>*</span></label>
                            </div>
                        </div>
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                <textarea id="textarea-state-default" rows={6} name="skill_description" onChange={event => { skillInputHandlerChange(event, "description") }}></textarea>
                                <label className='positionRelative' htmlFor="input-type-number">Skill Description <span className='required'>*</span></label>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary" onClick={() => { setShowCreateSkill(false) }}>
                    Cancel
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" disabled={!skillSubmitBtnEnable} onClick={() => { setShowCreateSkill(false); addSkill(); }}>
                    Create
                    {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>
    }
    const deleteEntities = () => {
        setDataLoading(true);
        let entity_ids = [];
        for (let i = 0; i < entity_list.length; i++) {
            if (entityCheckBoxList[i] === true) {
                entity_ids.push(entity_list[i]["id"]);
            }
        }
        EntityService.deleteEntities({ "entity_ids": entity_ids }, selectedSkill.value).then((res) => res.json()).then((res) => {
            //load new eneity list
            if (res['status'] == 'success') {
                getEntityData(selectedSkill.value);

                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage('Successfully deleted the entity/entities.');
                setShowMessageBanner(true);
                onEntityEditTabClose('close')
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }

        }, (err) => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to delete the entity. Please try later.");
            setShowMessageBanner(true);
        })
    }

    const downloadEntities = fileType => {
        setDataLoading(true);
        for (let i = 0; i < entity_list.length; i++) {
            if (entityCheckBoxList[i] === true) {
                if (fileType === 'json') {
                    let obj = {}
                    entity_list[i]['values'].forEach(val => { obj[val.split(',')[0]] = val.split(',') })
                    FileDownloader([JSON.stringify(obj)], `${entity_list[i]['name']}.json`)
                }
                else FileDownloader(entity_list[i]["values"], `${entity_list[i]["name"]}.${fileType}`);
            }
        }

        //resetting the checboxes after downloading
        setIsEntityCheckBoxSelected(false);
        setEntityCheckboxList((prev) => {
            let temp = [...prev];
            for (let i = 0; i < temp.length; i++) {
                temp[i] = false;
            }
            return temp;
        })
        setDataLoading(false);
    }

    const entityDownloadModal = () => {
        let fileType = 'csv'
        return <Modal centered show={showDownloadModal} onHide={() => { setShowDownloadModal(false) }} animation={false}>
            <Modal.Header>
                <h5>Download Entity</h5>
                <div className='btn--small btn--icon' onClick={() => { setShowDownloadModal(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <input type='radio' id='CSV' name='fileType' value='CSV' defaultChecked onClick={() => { fileType = 'csv' }} />
                <label for='CSV' className='base-margin-right'>CSV</label>
                <input type='radio' id='JSON' name='fileType' value='JSON' onClick={() => { fileType = 'json' }} />
                <label for='JSON' className='base-margin-right'>JSON</label>
                <input type='radio' id='TXT' name='fileType' value='TXT' onClick={() => { fileType = 'txt' }} />
                <label for='TXT'>TXT</label>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary" onClick={() => { setShowDownloadModal(false) }}>
                    Cancel
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" onClick={() => { setShowDownloadModal(false); downloadEntities(fileType) }}>
                    Download
                    {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>
    }

    const toggleSelectAllEntity = () => {
        let newList = [];
        let isAllSelected = true;

        for (let i = 0; i < entityCheckBoxList.length; i++) {
            if (entityCheckBoxList[i] === false) {
                isAllSelected = false;
                break;
            }
        }
        if (isAllSelected) {
            for (let i = 0; i < entityCheckBoxList.length; i++) {
                newList.push(false);
            }
        }
        else {
            for (let i = 0; i < entityCheckBoxList.length; i++) {
                newList.push(true);
            }
        }

        setEntityCheckboxList(newList);
    }

    const getEntityData = (skillId) => {
        setDataLoading(true);
        EntityService.listEntity(skillId).then(res => res.json()).then((res) => {
            if (res['status'] == 'success') {
                setEntityList(() => {
                    let tempList = [];
                    let checkboxList = [];
                    for (let entity of res["entities"]) {
                        let values = [];
                        checkboxList.push(false);
                        for (let valueObj of entity["values"]) {
                            let value = valueObj["cname"];
                            for (let synomym of valueObj["synonyms"]) {
                                value += ("," + synomym)
                            }
                            values.push(value);
                        }

                        tempList.push({
                            "id": entity["_id"],
                            "name": entity["name"],
                            "values": values,
                            "roles": entity["roles"],
                            "description": entity["description"]
                        })
                    }
                    setIsEntityCheckBoxSelected(false);
                    setEntityCheckboxList(checkboxList);
                    setDataLoading(false);
                    return tempList;
                });

            }
            else {
                console.log("Failed to retrieve bots");
                setDataLoading(false);
                setBannerMessageType('error');
                if (res['details']) {
                    setBannerMessage(res['details']);
                } else {
                    setBannerMessage(res['details']);
                }
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve bots!");
            setShowMessageBanner(true);
        })
    }

    // Page tour section (Joyride).....................................................................................
    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h6>Welcome to the <b>Entities Screen</b>. Here you can easily create, delete and download Entities.  <br></br><br></br> An Entity is a word or phrase that provides information necessary to fulfill a particular intent.</h6>,
                locale: { skip: <span aria-label="skip">Skip</span> },
                placement: 'center',
                target: '.step1',
            },
            {
                content: <h6>Here, you can select from a list of skill and We can also create a new skill by entering the skill name and description.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 10,
                target: '.step2',
            },
            {
                content: <h6>Using this button you can create Entities</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 10,
                target: '.step3',
            },
            {
                content: <h6>Using this button you can download Entities</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 10,
                target: '.step4',
            },
            {
                content: <h6>Using this button you can delete Entities</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 10,
                target: '.step5',
            },
            {
                content: <h6>On Click the Entities list you can edit the Entities</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 0,
                target: '.step6',
            }
        ]
    });
    // Page tour section (Joyride) End.....................................................................................

    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        if (finishedStatuses.includes(status)) {
            setState({ run: false });
        }
    };
    const updateSkill = (data) => {
        getEntityData(data.value);
        setSkill(data);
        localStorage.setItem('skill_value', data.value)
        localStorage.setItem('skill_name', data.label)
        localStorage.removeItem('rule_name')
        localStorage.removeItem('rule_value')
        // let selectedSkill = skillObjList.find(skill => skill._id === data.value);
        // console.log("selected Skill\n" + JSON.stringify(selectedSkill));
        setSelectedSkillObj(data);
    }

    const findSkills = (data) => {
        if (data.value == 'newSkill')
            return true
        return false
    }

    const skillName = () => {
        return <div style={{ width: "91%" }}><SearchSelect
            id='skillName'
            className='augmentSelect'
            defaultValue={selectedSkill}
            options={skills}
            onValueChange={(data) => {
                if (findSkills(data)) {
                    setShowCreateSkill(true)
                }
                else {
                    setShowCreateSkill(false)
                    updateSkill(data);
                }
            }}
        />
        </div>
    }

    const showWarning = () => {

        return <div className="base-margin" >
            {/* <div className="alert alert--warning-alt">

                <div >Do you want to delete the entity/ entities?</div>
                <button className="btn btn--small btn--secondary" onClick={() => { deleteEntities(); setShowDeleteEntityScreen(false) }}>Yes</button>
                <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteEntityScreen(false) }}>No</button>
            </div> */}
            {
                (() => {
                    if (layoutType === "verticle") {
                        return (
                            <div className="alert alert--warning col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                                <span className='base-padding-right'> Are you sure you want to delete the entity/entities? </span>
                                <span>
                                    <button className="btn btn--small btn--secondary base-margin-right" onClick={() => { deleteEntities(); setShowDeleteEntityScreen(false) }}>Yes</button>
                                    <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteEntityScreen(false) }}>No</button>
                                </span>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="alert alert--warning flex flex-center center">
                                <span className='base-padding-right'>
                                    Are you sure you want to delete the entity/entities?
                                    <button className="btn btn--small btn--secondary base-margin-right" onClick={() => { deleteEntities(); setShowDeleteEntityScreen(false) }}>Yes</button>
                                    <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteEntityScreen(false) }}>No</button>
                                </span>
                            </div>
                        )
                    }
                })()
            }
        </div>
    }

    const displayMessage = (type, message) => {
        setBannerMessageType(type);
        setBannerMessage(message);
        setShowMessageBanner(true);
    }
    const CustomTag = (layoutType === "verticle") ? 'Wrapper' : 'div';
    return (
        <CustomTag>

            {isDataLoading != true && showGuidance ? <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                    buttonNext: {
                        background: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    buttonBack: {
                        color: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    buttonSkip: {
                        color: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                }}
            /> : ""}
            {
                isDataLoading && (<Spinner></Spinner>)
            }
            {/* <div className=''><ModuleHeader ViewName={"Entities Page"}></ModuleHeader></div> */}
            <div id='panel'>
                <div id='' className='row base-margin-top'>
                    {/* <h5 className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-2 col-xxl-2'>Entities Page</h5> */}
                    {/* <div className="col-2 col-sm-2 col-md-5 col-lg-5 col-xl-7 col-xxl-7"></div> */}
                    <div className="col-7 col-sm-7 col-md-8 col-lg-8 col-xl-9 col-xxl-9 base-margin-top">
                        <div className='row entity-menuWrapper'>
                            <div className='entity-menu'>
                                <button className='step3 btn btn--secondary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down entity-menuButton' onClick={() => { setCreateEntities(true) }}
                                    disabled={isReadOnlySkill()}
                                    style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                                    <span className='entity-menuText'>Create</span>
                                    {/* <span className="icon-add-contain icon-size-18 entity-menuIcon"></span> */}
                                </button>
                            </div>
                            <div className='entity-menu'>
                                <button className='step4 btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down entity-menuButton' onClick={() => { setShowDownloadModal(true) }} style={{ paddingLeft: "16px", paddingRight: "16px" }} disabled={isEntityCheckBoxSelected === false}>
                                    <span className='entity-menuText'>Download</span>
                                    {/* <span className="icon-download icon-size-18 entity-menuIcon"></span> */}
                                </button>
                            </div>
                            <div className='entity-menu'>
                                <button className='step5 btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down entity-menuButton' onClick={() => { setShowDeleteEntityScreen(true); }} style={{ paddingLeft: "16px", paddingRight: "16px" }} disabled={isEntityCheckBoxSelected === false}>
                                    <span className='entity-menuText'>Delete</span>
                                    {/* <span className="icon-delete icon-size-18 entity-menuIcon"></span> */}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-3 col-xxl-3 step2 " style={{ zIndex: 100 }}>{skillName()}</div>

                </div>

                {/* {(layoutType === "horizontal") && <>
                    <div className='row'>
                        <h5 className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-2 col-xxl-2'>Entities Page</h5>
                        <div className="col-2 col-sm-2 col-md-5 col-lg-5 col-xl-7 col-xxl-7"></div>
                        <div className="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-3 col-xxl-3 step2 " style={{ zIndex: 100 }}>{skillName()}</div>
                    </div>
                    <br />
                </>
                } */}
            </div>
            {/* {
                (layoutType === "verticle") && <hr />
            } */}
            {/* {
                (layoutType === "horizontal") && <br />

            } */}
            {
                showMessageBanner && (isDataLoading === false) && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
            }
            {
                (() => {
                    if (showGuidance) {
                        if (layoutType === "verticle") {
                            return (
                                <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                                    <span>It seems like no skill has been created. To create one, please click on <b>Create Skill</b> option from the above dropdown. You can also create a skill by clicking on the <b>Skill View Screen</b> (to create a new skill) or you can explore the <b>Skill library</b> (to reuse already created skills) from the sidebar menu. In case you need any help, please click on the <b>Help</b> option or converse with the <b>AskBotLite chatbot</b>.</span>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className="alert alert--info flex flex-center center">
                                    <span>It seems like no skill has been created. To create one, please click on <b>Create Skill</b> option from the above dropdown. You can also create a skill by clicking on the <b>Skill View Screen</b> (to create a new skill) or you can explore the <b>Skill library</b> (to reuse already created skills) from the sidebar menu. In case you need any help, please click on the <b>Help</b> option or converse with the <b>AskBotLite chatbot</b>.</span>
                                </div>
                            )
                        }

                    }
                })()
            }

            {/* {
                ((layoutType === "horizontal" && show_entity_edit_panel == false) || (layoutType === "verticle")) && (
                    <div style={{ "position": "absolute", "left": xPositionOfFloatButton, "bottom": "30px", "zIndex": "100", visibility: isReadOnlySkill() ? "hidden" : "visible" }}>
                        <button className="btn btn--secondary btn--circle btn--large " style={{ "height": "50px", "width": "50px" }} onClick={() => { toggleSelectAllEntity() }}>
                            <span className="icon-square-o-checked"></span>
                        </button>
                    </div>
                )
            } */}

            <div className='entity-componentWrapper step1'>
                <SplitScreenWrapper onResize={(leftWidth, rightWidth) => { onSplitScreenResized(leftWidth, rightWidth) }} scroll={false} isSplitScreenEnabled={show_entity_edit_panel} leftPanelElement={
                    !showGuidance && <div style={{ height: "100%" }}>
                        <br></br>

                        {
                            (() => {
                                if (entity_list.length == 0) {
                                    if (layoutType === "verticle") {
                                        return (
                                            <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                                                <span>It seems like no entity has been created for the chosen skill yet. To create one, click on the <b>Create Entity</b> button. In case you need any help, you can either select the <b>Help</b> option or converse with the <b>AskBotLite</b> chatbot.</span>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="alert alert--infoflex flex-center center">
                                                <span>It seems like no entity has been created for the chosen skill yet. To create one, click on the <b>Create Entity</b> button. In case you need any help, you can either select the <b>Help</b> option or converse with the <b>AskBotLite</b> chatbot.</span>
                                            </div>
                                        )
                                    }
                                }
                            })()
                        }

                        {showDeleteEntityScreen && showWarning()}
                        <div className='panel' style={{ "height": `calc(100% - ${mobileViewHeightOffset})`, "overflow": "auto" }}>
                            <div className='row panel entity-panel' style={{ "height": "100%" }}>
                                <div className='step6' style={{ "height": "100%", "overflow": "scroll" }}><Entity entity_list={entity_list} skillReadyOnly={isReadOnlySkill()} onEntityClicked={(entity, index) => { onEntityEditTabOpend(entity, index) }} isEntitySelected={show_entity_edit_panel} selectedEntityIndex={selectedEntityIndex} onEntityChecked={(data) => { setEntityCheckboxList(data) }} entityCheckboxListFromParent={entityCheckBoxList}></Entity></div>
                            </div>
                        </div>
                    </div>


                } rightPanelElement={
                    <EntityEditPanel entity_object={entitySelected} onCloseButtonClicked={() => { onEntityEditTabClose("close") }} onEntityUpdated={() => { onEntityEditTabClose("save") }} getEntityData={() => { getEntityData(selectedSkill.value) }} selectedSkill={selectedSkill} displayMessage={(type, message) => displayMessage(type, message)}></EntityEditPanel>
                }></SplitScreenWrapper>
            </div>

            <Modal size="lg" show={showCreateEntities} animation={false}>
                <Modal.Header>
                    <h6>Create Entities</h6>
                    <span className='icon-close icon-size-24' onClick={() => { setCreateEntities(false) }}></span>
                </Modal.Header>
                <Modal.Body>
                    <EntityEditPanel entity_object={{ "name": "", "values": [], "roles": [], "description": "" }} onCloseButtonClicked={() => { setCreateEntities(false) }} creationMode={true} onEntityCreated={() => { setCreateEntities(false); }} getEntityData={() => { getEntityData(selectedSkill.value) }} selectedSkill={selectedSkill} displayMessage={(type, message) => displayMessage(type, message)} validateEntityCreation={validateEntityDuplication}></EntityEditPanel>
                </Modal.Body>
            </Modal>
            {skillCreation()}
            {entityDownloadModal()}
        </ CustomTag>
    )



}

export default EntitiesView;