import { useState, useEffect } from 'react';

import ModelTrainingComponent from '../model-training-componet/ModelTrainingComponent';
import ModelValidationComponent from '../model-validation-component/ModelValidationComponent';
import ModuleHeader from '../../../../common-components/module-header/ModuleHeader';
import Wrapper from "../../../../common-components/wrapper/Wrapper";
import SearchSelect from '../../../../custom-components/search-select/SearchSelect';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import TrainingService from '../../training-service/TrainingService';
import BotService from '../../../bot-module/bot-services/BotService';
import './TrainingScreenComponent.css';
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import Spinner from '../../../../custom-components/spinner/Spinner';
import ModelTestingComponent from '../model-testing-component/ModelTestingComponent';

const TrainingScreen = () => {
    const [botDetails, setBotDetails] = useState()
    const [botslist, setBotslist] = useState();
    const [selectedBot, setSelectedBot] = useState();
    const [selectedBotData, setSelectedBotData] = useState();
    const [defaultbotvalue, setDefaultbotvalue] = useState()
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [showGuidance, setShowGuidance] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);

    const displayMessage = (type, message) => {
        setBannerMessageType(type);
        setBannerMessage(message);
        setShowMessageBanner(true);
    }
    useEffect(() => {
        getBotList()
    }, [])

    useEffect(() => {
        setShowGuidance(false);
        if (isDataLoading === false && botslist && botslist.length === 0) {
            setShowGuidance(true);
        }
    }, [botslist, isDataLoading])

    const getBotList = () => {
        setDataLoading(true);
        BotService.listBots().then(res => res.json()).then(res => {
            console.log(res)
            if (res.status === 'success') {

                if (res.bots.length == 0) {
                    setShowGuidance(true);
                    setDataLoading(false);
                }
                else {

                    setBotDetails(res.bots);
                    setShowGuidance(false);
                    setDataLoading(false);
                    let isBotInLSFound = false;
                    setBotslist(res.bots.map((btslist, index) => {
                        console.log(btslist.upToDate)
                        if(localStorage.getItem('botSelectedId') && btslist.id === localStorage.getItem('botSelectedId')){
                            isBotInLSFound = true;
                        }
                        return {
                            "label": btslist.display_name,
                            "value": btslist._id,
                            // "key": btslist._id,
                            "skills": btslist.skills,
                            "trained": btslist.upToDate
                        }
                    }
                    ));
                    // if bot in LS not present in list of bots from API reset the LS
                    if(!isBotInLSFound){
                        localStorage.removeItem('botSelectedName');
                        localStorage.removeItem('botSelectedId');
                        localStorage.removeItem('botSkills');
                        localStorage.removeItem('botTrained');
                    }
                    if (localStorage.getItem('botSelectedName') && localStorage.getItem('botSelectedId') && localStorage.getItem('botSkills') && localStorage.getItem('botTrained')) {
                        console.log("training", localStorage)
                        setDefaultbotvalue({
                            "label": localStorage.getItem('botSelectedName'),
                            "value": localStorage.getItem('botSelectedId'),
                            // "key": localStorage.getItem('botSelectedId'),
                            "skills": JSON.parse(atob(localStorage.getItem('botSkills'))),
                            "trained": localStorage.getItem('botTrained'),
                        })
                    }
                    else {
                        setDefaultbotvalue({
                            "label": res.bots[0].display_name,
                            "value": res.bots[0]._id,
                            // "key": res.bots[0]._id,
                            "skills": res.bots[0].skills,
                            "trained": res.bots[0].upToDate
                        })
                        localStorage.setItem('botSelectedName', res.bots[0].display_name)
                        localStorage.setItem('botSelectedId', res.bots[0]._id)
                        localStorage.setItem('botSkills', btoa(JSON.stringify(res.bots[0].skills)))
                        localStorage.setItem('botTrained', res.bots[0].upToDate)
                        
                    }
                }
            }
            else {
                console.log("Failed to retrieve bots");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setShowGuidance(false);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve bots!");
            setShowMessageBanner(true);
            setShowGuidance(false);
        })


    }
    const [tab_selected, switchTab] = useState('training');
    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h6>Welcome to <b>Training screen</b>. Here, you can setting/schedule your Training.</h6>,
                locale: { skip: <span aria-label="skip">Skip</span> },
                placement: 'center',
                target: '.pageTour1',
            },
            {
                content: <h6>Here, you can select from a list of skill and We can also create a new skill by entering the skill name and description.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour2',
            },
            {
                content: <h6>Here you can Setting and schedule your Training.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 0,
                target: '.pageTour3',
            },
            {
                content: <h6>On Click on this tab you can edit, delete and upload Expression.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 0,
                target: '.pageTour4',
            },
        ]
    });

    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setState({ run: false });
        }
    };

    const botsName = () => {
        console.log("botslist", botslist)
        return <SearchSelect
            id='botsName'
            className='augmentSelect'
            options={botslist}
            defaultValue={defaultbotvalue}
            onValueChange={(data) => {
                setShowMessageBanner(false);
                setBannerMessage('');
                setBannerMessageType('success');
                setSelectedBot(data.key)
                setSelectedBotData(data)


                localStorage.setItem('botSelectedName', data.label)
                localStorage.setItem('botSelectedId', data.value)
                localStorage.setItem('botSkills', btoa(JSON.stringify(data.skills)))
                localStorage.setItem('botTrained', data.upToDate || data.trained)
            }}
        />
    }

    const switchTrainingPageTab = (tab) => {
        setShowMessageBanner(false);
        setBannerMessage('');
        setBannerMessageType('success');
        switchTab(tab)
    }
    return (
        <Wrapper>
            {isDataLoading != true && showGuidance ? <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                    buttonNext: {
                        background: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    buttonBack: {
                        color: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    buttonSkip: {
                        color: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    tooltipContainer: {
                        textAlign: "top"
                    },
                }}
            /> : ''}

            <div style={{ borderBottom: '1px #ced4da solid' }}>
                <div className="row base-margin-right pageTour1" >
                    <h5 className="col-8 col-md-8 col-sm-8 col-lg-9 col-xl-9 col-xxl-10 col-xxxl-10 module-header-text">Training</h5>
                    <div className='col-4 col-md-4 col-sm-4 col-lg-3 col-xl-3 col-xxl-2 col-xxxl-2 '>
                        <div className='pageTour2'>
                            {botsName()}
                        </div>
                    </div>
                </div>
            </div>
            {
                isDataLoading && (<Spinner></Spinner>)
            }
            {
                showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
            }
            {showGuidance && <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                <span>It seems like you have not yet created a bot. You can create a bot by clicking on the <b>Bot Screen</b> from the sidebar menu. If you require assistance, please click on the <b>Help</b> option or chat with the <b>AskBotLite chatbot</b>.</span>
            </div>}
            {!showGuidance && <div className='trainingTabWrapper'>

                <div className='trainingtab-selector'>
                    <ul className="tabs tabs--justified trainPage-tabSelector">
                        <li id="static-entity-tab" className={tab_selected == "training" ? "active" : "" + "tab"} onClick={() => switchTrainingPageTab("training")}>
                            <a tabindex="0">Train</a>
                        </li>
                        <li id="api-entity-tab" className={tab_selected == "validation" ? "active" : "" + "tab"} onClick={() => switchTrainingPageTab("validation")}>
                            <a tabindex="0" className='pageTour4'>Validate</a>
                        </li>
                        <li id="api-entity-tab" className={tab_selected == "test" ? "active" : "" + "tab"} onClick={() => switchTrainingPageTab("test")}>
                            <a tabindex="0" className='pageTour4'>Test</a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content trainPage-tabContent">
                    <div className={tab_selected == "training" ? "active tab-pane" : "tab-pane"}>
                        {defaultbotvalue && tab_selected == "training" && <ModelTrainingComponent key="1" selectedBot={selectedBotData === undefined ? defaultbotvalue : selectedBotData} selectedBotId={selectedBotData === undefined ? defaultbotvalue.value : selectedBot} displayMessage={(type, message) => displayMessage(type, message)}></ModelTrainingComponent>}
                    </div>
                    <div className={tab_selected == "validation" ? "active tab-pane" : "tab-pane"}>
                        {defaultbotvalue && tab_selected == "validation" && <ModelValidationComponent selectedBot={selectedBotData === undefined ? defaultbotvalue : selectedBotData} displayMessage={(type, message) => displayMessage(type, message)} />}
                    </div>
                    <div className={tab_selected == "test" ? "active tab-pane" : "tab-pane"}>
                        {defaultbotvalue && tab_selected == "test" && <ModelTestingComponent selectedBot={selectedBotData === undefined ? defaultbotvalue : selectedBotData} />}
                    </div>
                </div>
            </div>}

        </Wrapper>
    )
}


export default TrainingScreen;