import './FileDropZone.css';

import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useState } from 'react';


const FileDropZone = (props) => {

    const [fileInfo, setFileInfo] = useState('');
    const [fileUploaded, setFileUploadedFlag] = useState(false);
    const [fileData, setFileData] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        setFileInfo("");
        setFileData([]);
        console.log(acceptedFiles);

        for (let i = 0; i < acceptedFiles.length; i++) {
            let file = acceptedFiles[i];

            if (isFileTypeAllowed(file.type)) {
                const reader = new FileReader();

                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = () => {

                    const dataStr = reader.result
                    let decoder = new TextDecoder();
                    setFileInfo((prevInfo) => {
                        if (prevInfo.length !== 0) {
                            return prevInfo + ", " + file.name;
                        }
                        else {
                            return file.name;
                        }
                    });

                    setFileData((prevData) => {
                        let temp = []
                        for (let data of prevData) {
                            temp.push(data);
                        }
                        if (file.type === "image/png" || file.type === "image/jpeg"){ //if file is image, file is returned for use in Data Uri converter
                            temp.push(file);
                        } else if(props.readAsBinary){ //reading as binary string
                            temp.push({ "data": dataStr.toString("utf-8"), "type": file.type, "name": file.name });
                        } else {
                            temp.push({ "data": decoder.decode(dataStr), "type": file.type, "name": file.name });
                        }
                        
                        if (acceptedFiles.length - 1 === i) {
                            //props.onFileuploaded([...temp]);
                            props.onFileuploaded(temp);
                        }
                        return temp;
                    })

                }
                //reading in form of binary string for docx file, we might have to use same for pdf extension also
                if(props.readAsBinary){
                    reader.readAsBinaryString(file);
                }
                else{
                    reader.readAsArrayBuffer(file);
                }
            }
            else {
                let fileExtension = file.name.split(".");
                fileExtension = fileExtension[fileExtension.length-1];
                setFileInfo(`.${fileExtension} file type is not allowed!`);
                setFileData([]);
                setFileUploadedFlag(true);
                return;
            }


        }
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const isFileTypeAllowed = (type) => {
        if (props.allowedFileTypes.indexOf("*") !== -1) {
            return true;
        }
        for (let value of props.allowedFileTypes) {
            if (value.toLowerCase() === type.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    return (
        <div className={props.className + ' panel'} style={props.style}>
            <div {...getRootProps()} className="file-drop-zone-wrapper">
                <div className="file-drop-zone">
                    <div className='file-drop-zone-icon row'>
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl4 col-xxxl4'></div>
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl4 col-xxxl4'>
                            <span className="icon-upload"></span>
                        </div>
                        <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl4 col-xxxl4'></div>
                    </div>
                    <div className='file-drop-zone-message'>
                        <input {...getInputProps()} />
                        <p>{props.message}</p>
                    </div>
                    <div className='file-drop-zone-info'>{fileInfo}</div>
                </div>


            </div>
        </div>
    )
}

FileDropZone.defaultProps = {
    style: {'marginTop':"8px",'marginBottom':"8px"},
    allowedFileTypes: ["*"], 
    onFileuploaded: (data) => { },
    className: "",
    message: "Drag 'n' drop some files here, or click to select files",
    readAsBinary: false
};

export default FileDropZone;