// import ImageSliderHome from '../../../common-components/ImageSlider/ImageSliderHome';
// import ImageSlider from '../../../common-components/ImageSlider/ImageSlider';
import HomeFooter from './HomeFooter';
import HomeHeader from './HomeHeader';
import './HomeScreen.css';
import Cookies from 'universal-cookie';
import Spinner from '../../../custom-components/spinner/Spinner';
import { useState } from 'react';

export const HomeScreen = () => {
    let hash = window.location.hash;
    let isGetTokenInProgres = false;
    const botliteHomeUrl = process.env.REACT_APP_ROOT_URL.split("/api")[0] + "/home";
    const code_challenge_url = `${process.env.REACT_APP_ROOT_URL}/okta/getLoginRedirectURL`
    const authAPIUrl = `${process.env.REACT_APP_ROOT_URL}/okta/loginRedirect`
    const [isDataLoading, setIsDataLoading] = useState(false);
    

    if (hash) {

        if (localStorage.getItem("isFragmentUsed") === null) {
            localStorage.setItem("isFragmentUsed", "true");
            isGetTokenInProgres = true
            setIsDataLoading(true);
            
            fetch(`${authAPIUrl}?fragment=${hash.split("=")[1].split("&")[0]}&state=${hash.split("=")[2].split("&")[0]}`).then((res) => res.json()).then((res) => {

                if (res["status"] === "success") {
                    localStorage.setItem("token", res["token"]);
                    localStorage.setItem("full_name", res["full_name"]);
                    localStorage.setItem("email", res["email"]);
                    const cookies = new Cookies();
                    cookies.set('jwt', res["token"]);
                    //log(cookies.get("token"))
                    if (res["migration_status"] === "pending") {
                        window.location.href = process.env.REACT_APP_CAE_URL
                    }
                    else {
                        localStorage.setItem("disable_botlite2_button", "false")
                        if (res["migration_status"] === "completed" || res["migration_status"] === "NA") {
                            //disable open BotLite 2.0 button
                            localStorage.setItem("disable_botlite2_button", "true")
                        }
                        window.location.href = "/";
                    }
                    setIsDataLoading(false);
                }
                else {
                    setIsDataLoading(false);
                    window.alert("Failed to login!");
                }

            },(err)=>{
                setIsDataLoading(false);
                window.alert("Failed to login!");
            })
            // let payload = {
            //     method:"POST",
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body : JSON.stringify({
            //         "fragment":hash,
            //         "code_challenge": localStorage.getItem("code_challenge")
            //     })
            // }
            // fetch(authAPIUrl,payload).then((res)=>res.json()).then((res)=>{
            //   if (res["status"] != undefined && res["status"] == "success" && res["token"]!=undefined){
            //     localStorage.setItem("token",res["token"]);
            //     //window.location.href = skill_page_url;
            //   }

            // })
        }
        // else {
        //     localStorage.removeItem("isFragmentUsed");
        // }


    }

    const redirectToLogin = () => {
        fetch(code_challenge_url).then((res) => res.json()).then((res) => {
            localStorage.setItem("code_challenge", res["code_challenge"]);
            localStorage.removeItem("isFragmentUsed");
            window.location.href = res["login_url"];
        })
    }
    return (
        <div>
            {isDataLoading && <Spinner></Spinner>}
            <HomeHeader style={{ position: 'fixed', top: '0' }} />
            <div className='row' style={{ marginTop: '10vh' }}>
                
                <div style={{ textAlign: 'center', width: '50vw' }}>
                    <img src={process.env.PUBLIC_URL + "/images/common-images/BotliteLogo.png"} href={botliteHomeUrl} className="botliteTitle" alt="BotLiteLogo" width={'60%'} style={{ border: '1px solid black', paddingRight: '10px' }}></img>
                </div>

                <div style={{ textAlign: 'center', width: '50vw', marginTop: '20px' }} className="base-padding">
                    <h2 className='base-margin-bottom'>
                        <b>Welcome To BotLite</b>
                    </h2>
                    <h6>A simple DIY no-code low-code framework and toolkit with a rich GUI to create Bots with zero to minimum technical expertise.</h6>

                    <div className='base-margin-top'>
                        <div className="row">
                            {/* <div className='col'>
                                <button className=" btn btn--secondary" href="">
                                    <span className="half-margin-right">Docs</span>
                                </button>
                            </div> */}

                            <div className='col'>
                                <button className="header-item btn btn--secondary" onClick={() => { redirectToLogin() }}>
                                    <span className="half-margin-right">Get Started</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />
            <div style={{ 'backgroundColor': '#052b58' }} className='row base-padding' >
                <div style={{ color: 'white', width: '45vw' }}>
                    <div className='base-padding'>
                        <h4 className='text-center'>Why create a Bot? </h4>
                    </div>
                    <div className='base-margin'>
                        <span style={{ textAlign: 'center' }}>Bots offer the potential to streamline various processes and cater to a wide range of objectives.</span>
                        <ul>
                            <li className='base-padding'>
                                <h6>FAQ Bot</h6>
                                <span>
                                    A bot that can address user inquiries through automated responses.
                                </span>
                            </li>
                            <li className='base-padding'>
                                <h6>Dialogue-based Bot</h6>
                                <span>
                                    Enable users to engage in meaningful conversations with the bot, where the bot can simulate human-like interactions using artificial intelligence and machine learning technologies, delivering natural responses to queries.
                                </span>
                            </li>
                            <li className='base-padding'>
                                <h6>Menu-based Bot</h6>
                                <span>
                                    Provide users with multiple options via a menu system, allowing them to choose the most relevant item from the list, with the bot delivering appropriate responses accordingly.
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div style={{ color: 'white', width: '50vw', alignContent: 'center' }}>
                    <div className='base-padding'>
                        <h4 className='text-center'>Who can create a Bot? </h4>
                    </div>
                    <div className='base-margin'>
                        <span style={{ marginLeft: '25%' }}>Any employee with a CEC ID can create a bot.</span>
                    </div>
                    <div className='base-padding'>
                        <h4 className='text-center'>What are the steps for creating a bot? </h4>
                    </div>
                    <div className='base-margin'>
                        <span>
                            <img src={process.env.PUBLIC_URL + "/images/create_bot.png"} alt="" style={{ height: '200px', marginLeft: '25%' }} />
                        </span>
                    </div>
                </div>
            </div >
            <div>
                <div className='base-padding'>
                    <h4 className='text-center'>Top Bots</h4>
                    <div className="center row base-margin" style={{ "width": '100%' }}>
                        <div className="col panel panel--raised panel--bordered base--padding base-margin">
                            {/* <div >
                                <span className='text-center' style={{ borderRadius: '50%' }}>
                                    <img src={process.env.PUBLIC_URL + "/images/pearl_bot.png"} alt="" />
                                </span>
                            </div> */}
                            <div>
                                <h6 className='text-center'><b>PEARL</b></h6>
                                <br />
                                <div>
                                    Welcome and meet Pearl. Your customers are engaging with us across a variety of channels: Cisco.com, social media, third party sites, and events. Pearl delivers which customers are engaging in specific messaging and the content being consumed allowing you to engage with your accounts aligned to their current interests.
                                </div>
                            </div>
                        </div>
                        <div className="col  panel panel--raised panel--bordered  base-padding base-margin">
                            <div>
                                <h6 className='text-center'><b>V2MOM</b></h6>
                                <br />
                                <div>
                                    ONEx uses V2MOM to accelerate our strategic business transformation by aligning on our key priorities. It is a valuable tool that drives alignment, prioritizes what is important, and holds us accountable for delivering business outcomes.
                                    V2MOM Bot uses a menu driven to support this strategy by providing users a  guide to select from a rich menu that covers a variety of topics including comprehensive information on creating and publishing V2MOMs, due dates, compatible browsers, searching for V2MOMs by user names and many more.
                                </div>
                            </div>
                        </div>
                        <div className="col  panel panel--raised panel--bordered base-padding base-margin">
                            <div>
                                <h6 className='text-center'><b>SAMI</b></h6>
                                <br />
                                <div>
                                    I'm your Security Acceleration, Modernization and Insights (SAMI) bot was showcased at the recent Cisco Impact.  This bot will help Sales Agents in their security sales journey by equipping them with the best tools, insights, and training possible.  This includes ready access to Top Security Account Targets, responses to questions related to Onboarding, Continuous Learning and Sales Accelerated Opportunities.
                                </div>
                            </div>
                        </div>
                        <div className="col panel panel--raised panel--bordered base-padding base-margin">
                            <div>
                                <h6 className='text-center'><b>Smartbuy Virtual Assistant</b></h6>
                                <br />
                                <div>
                                    This bot is your first point of contact for the Procurement helpdesk. A rich support menu answers users frequently asked questions and performs various tasks, such as providing users with details on supplier availability, the status of a Purchase Requisition (PR), Purchase Order (PO) or invoice, and more.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='panel panel--raised' style={{backgroundColor:"#052b58",color:"white",fontSize:"2vw",fontWeight:"100",marginBottom:"200px",textAlign:"center",padding:"25px"}}>
                    <p>
                    Bots can be built for a variety of purposes.  You should determine the right layout that will cater best to your audience and serve your purpose.  The following are the various reasons for building bots.  First determine the purpose.
                    </p>
                    
                    <br/><br/><br/>
                    
                    <p style={{textAlign:"left"}}>
                        <h3>The purpose is</h3>
                        <ul style={{paddingLeft:"20px !important"}}>
                            <li><b>FAQ Bot</b> - I want my Bot to Respond to my users FAQs.</li>
                            <li><b>Dialog Bot</b> - I want my Bot to have a dialog with the user to narrow in on what the user wants and then provide a response.</li>
                            <li><b>Multiple Choice Bot</b> - I want to provide multiple choices so the user can select what is most needed from the list and my Bot can provide appropriate response. </li>
                        </ul>
                        
                         
                        
                    </p>

                </div> */}

                {/* <ImageSliderHome/> */}
                {/* <br/>
                <br/>
                <div>
                    <h5 className='text-center'><b>TOP BOTS</b></h5>
                    <div className="center row base-margin">
                        <div className="col panel panel--raised panel--bordered base--padding base-margin">
                            <div >
                                <span className='text-center' style={{borderRadius:'50%'}}>
                                    <img src={process.env.PUBLIC_URL+"/images/pearl_bot.png"} alt=""/>
                                </span>
                            </div>
                            <div>
                                <h6 className='text-center'><b>PEARL</b></h6>
                                <br/>
                                <div>
                                    Welcome and meet Pearl. Your customers are engaging with us across a variety of channels: Cisco.com, social media, third party sites, and events. Pearl delivers which customers are engaging in specific messaging and the content being consumed allowing you to engage with your accounts aligned to their current interests.
                                </div>
                            </div>
                        </div>
                        <div className="col panel panel--raised panel--bordered  base-padding base-margin">
                            AskEva
                        </div>
                        <div className="col panel panel--raised panel--bordered base-padding base-margin">
                            AskERMO
                        </div>
                        <div className="col panel panel--raised panel--bordered base-padding base-margin">
                            ASK Charlie
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col base-margin'>
                        <h5 className='text-center base-margin'><b>OUR CUSTOMERS<span className="icon-communities icon--large" ></span></b></h5>
                        <img src={process.env.PUBLIC_URL+"/images/Slider/ourcustomers.jpeg"} alt="Our Customers"/>
                    </div>
                    
                    <div className="col center row base-margin">
                        <ImageSlider />
                    </div>
                </div>
                <br/>
                <br/>
                <div>
                    <h5 className='text-center base-margin'><b>CONTACT US<span className="icon-contact icon--large" ></span></b></h5>
                        
                    <div className="center row base-margin"><div className="col panel panel--raised base-margin base-padding center">
                        <div className="col-md-12" >
                            <div className="form-group base-margin-bottom">
                                <div className="form-group__text">
                                    <input id="input-state-default" type="text"/>
                                    <label htmlFor="input-state-default">Name</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12" >
                            <div className="form-group base-margin-bottom">
                                <div className="form-group__text ">
                                    <input id="input-state-default" type="text"/>
                                    <label htmlFor="input-state-default">Email ID</label>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-12" >
                            <div className="form-group base-margin-bottom">
                                <div className="form-group__text ">
                                    <input id="input-state-default" type="text"/>
                                    <label htmlFor="input-state-default">Subject</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 center">
                            <div className="form-group base-margin-bottom">
                                <div className="form-group__text">
                                    <textarea id="textarea-state-default" rows="5"></textarea>
                                    <label htmlFor="textarea-state-default">Content</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 col-lg-2 base-margin-top center">
                            <button className="btn btn--secondary text-center">Send</button>
                        </div>

                    </div>

                    <div className="col ">
                        <img src={process.env.PUBLIC_URL+"/images/Slider/contactus.jpeg"} alt="Our Customers"/>
                    </div>
                        
                        
                </div>
                </div> */}
            </div>
            <HomeFooter />
        </div >

    )
}

export default HomeScreen;