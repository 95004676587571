import { useState, useRef } from "react";
import AnalyticsFilterComponent from "../analytics-filter-component/AnalyticsFilterComponent";
import { BarChartHeader } from "../../style";
import ActionSelect from "../../../../custom-components/action-select/ActionSelect";
import exportFromJSON from "export-from-json";

const AnalyticsBarChart = ({
  chart,
  unitHeight,
  unitWidth,
  chartHeight,
  chartWidth,
  layouts,
  index,
  bot,
}) => {
  const [action, setAction] = useState({ value: "reset", label: "--Reset--" });
  const downloadDataRef = useRef();
  const [fileData, setFileData] = useState(null);

  const onActionChange = (data) => {
    setAction(data);
  };

  const getDownloadData = (e) => {
    e.preventDefault();
    const downloadData = downloadDataRef?.current?.getDownloadData();
    console.log("my data.....", downloadData);
    setFileData(downloadData);
    ExportToExcel();
  };

  const ExportToExcel = () => {
    const data = fileData;
    const fileName = "download";
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  const downloadCustomData = (event, downloadInfoObj) => {
    let apiPath = downloadInfoObj.API_PATH
    let re = /\<agent>/gi;
    
    if(bot.label && bot.label != "") {
      apiPath = apiPath.replace(re, bot.label);
      fetchCustomData(apiPath).then(res => res.json()).then(res => {
        if (res["status"] == "success") {
          let dataList = res["data"];
          exportCustomDataToCsv(downloadInfoObj.HEADER_NAMES, downloadInfoObj.RESPONSE_ATTR, dataList)
        }
      }).catch(err => {
        
      })
    }
  }

  const fetchCustomData = (apiPath) => {
    let api_url = process.env.REACT_APP_ROOT_URL
    api_url = api_url + apiPath
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "get",
      headers: reqHeaders,
    };
    return fetch(api_url, requestOptions);
  }

  const exportCustomDataToCsv = (headerNames, response_attr, dataList) => {
		let headers = [headerNames]
		let csvDataArray = []
		for(let dataObj of dataList) {
		  let response_array = []
		  for(let attrName of response_attr) {
			if(attrName in dataObj) {
			  response_array.push(dataObj[attrName])
			}else {
			  response_array.push("NA")
			}
		  }
		  csvDataArray.push(response_array.join(","))
		}
		downloadFile({
		  data: [...headers, ...csvDataArray].join("\n"),
		  fileName: "data.csv",
		  fileType: "text/csv",
		});
	}

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return (
    <div>
      <div className="base-margin">
        <BarChartHeader>{chart?.header}</BarChartHeader>
        <span>
          {" "}
          &nbsp;
          <span
            tabindex="0"
            data-balloon-break
            data-balloon={chart?.chartInfo}
            data-balloon-pos="down"
            data-balloon-length="large"
          >
            <span
              className="icon-info-outline"
              style={{ "font-size": "1.8rem", top: "-3px" }}
            ></span>
          </span>
          {!(chart.customDownLoadInfo) && chart.downloadInfo !== null && (
            <span onClick={getDownloadData}>
              <span
                tabindex="1"
                data-balloon-break
                data-balloon={chart.downloadInfo}
                data-balloon-pos="down"
                data-balloon-length="large"
              >
                &nbsp;{" "}
                <span
                  className="icon-download"
                  style={{ "font-size": "1.8rem", top: "-3px", cursor: 'pointer' }}
                ></span>
              </span>
            </span>
          )}
          {chart.downloadInfo == null && chart.customDownLoadInfo && (
            <span onClick={e => { downloadCustomData(e, chart.customDownLoadInfo) }}>
              <span
                tabindex="1"
                data-balloon-break
                data-balloon="Download"
                data-balloon-pos="down"
                data-balloon-length="large"
              >
                &nbsp;{" "}
                <span
                  className="icon-download"
                  style={{ "font-size": "1.8rem", top: "-3px", cursor: 'pointer' }}
                ></span>
              </span>
            </span>
          )}
        </span>
        {chart.key === "xyz" && (
          <span
            style={{ display: "inline-block", padding: "10px" }}
            key={chart.key}
          >
            <ActionSelect
              key={chart.key}
              defaultValue={action}
              options={[]}
              onValueChange={(data) => {
                onActionChange(data);
              }}
            />
          </span>
        )}
      </div>
      <hr />
      <AnalyticsFilterComponent
        chartId={chart?.id}
        height={
          (Object.keys(layouts).length === 0
            ? chartHeight
            : layouts["lg"][index + 1]["h"]) * unitHeight
        }
        width={
          ((Object.keys(layouts).length === 0
            ? chartWidth
            : layouts["lg"][index + 1]["w"]) -
            1) *
          unitWidth
        }
        insight="bot"
        ref={downloadDataRef}
        action={action.value}
        bot={bot}
      />
    </div>
  );
};

export default AnalyticsBarChart;
