import './ResponseNode.css';
import DialogService from '../../../../dialog-service/DialogService';
import { Handle, Position, useStore, useReactFlow, getConnectedEdges } from 'react-flow-renderer';
import { useEffect, useState } from 'react';
import { setDeletedNodes } from '../../../dialog-view-component/DialogViewComponent';

const ConnectableFn = (id, direction) => {
	const edgesCount = useStore((store) => store.edges.length);
	const { getNode, getEdges } = useReactFlow();

	const [hasConnections, setHasConnections] = useState(false);

	useEffect(() => {
		setHasConnections(() => {
			const edges = getConnectedEdges([getNode(id)], getEdges()).filter(
				(e) => e[direction] === id
			);

			return edges.length > 0;
		});
	}, [getNode, getEdges, id, edgesCount]);

	return hasConnections;
};

function ResponseNode({ id, data, xPos, yPos }) {
	const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
	const [actionList, getActionList] = useState([]);
	const [open, setOpen] = useState(false);
	const nodes = getNodes();
	const edges = getEdges();

	function makeCard() {
		data.card = ''
	}

	function makeFile() {
		data.file = { rows: 0, columns: 0, format: 'CSV' }
	}

	function makeText() {
		data.response = []
	}

	const onDragOver = event => event.preventDefault();

	const onDrop = event => {
		event.preventDefault();
		const augmentData = event.dataTransfer.getData('augment');
		if (augmentData === 'response') {
			data.node.setSelected()
			const type = event.dataTransfer.getData('response');
			switch (type) {
				case 'file':
					makeFile()
					break
				case 'card':
					makeCard()
					break
				case 'text':
					makeText()
					break
				default:
					break
			}
		}
	};

	const hasSourceConnections = ConnectableFn(id, "source");
	const deleteEdges = (id) => {
		let connectedEdges = []
		for (let i of edges) {

			if (i.target == id || i.source == id) {
				connectedEdges.push(i)
			}
		}
		console.log("edges", edges)
		console.log("connectedEdges", connectedEdges)

		const remainingEdges = edges.filter((edge) => !connectedEdges.includes(edge));
		const remainingNodes = nodes.filter((node) => node.id != id)

		console.log("remainingEdges", remainingEdges)
		console.log("remainingNodes", remainingNodes)

		let targetId = ''
		let getedges = {}
		let temp = []

		for (let i of connectedEdges) {
			if (i.target == id) {
				getedges = i;
			}
			if (i.source == id) {
				targetId = i['target'];
			}
		}
		if (connectedEdges.length == 2) {
			getedges['target'] = targetId
			temp = [...remainingEdges, getedges];
		}
		else {
			temp = [...remainingEdges]
		}
		console.log("temp", temp)

		const finalList = [...temp]
		if (remainingNodes.length == 1) {
			setEdges([])
		}
		else {
			setEdges(finalList)
		}

		setNodes(remainingNodes)
		console.log(remainingNodes)
		console.log(finalList)

	}

	const deleteNodeById = (id) => {
		let node_values = { id, data, position: { x: xPos, y: yPos }, type: "ResponseNode" }
		setDeletedNodes(node_values)

		DialogService.deleteNodeById(data.skill, data.rule, id).then(res => res.json()).then(res => {
			if (res.status == 'success') {
				deleteEdges(id)

			}
			else {
				if (res.details == id + " doesn't exists") {
					console.log("insidde")
					data.showConfirmation = false
					deleteEdges(id)
				}
				// setDataLoading(false);
				// setBannerMessageType('error');
				// setBannerMessage(res['details']);
				// setShowMessageBanner(true);
			}
		}).catch(err => {
			// setDataLoading(false);
			// setBannerMessageType('error');
			// setBannerMessage('Something went wrong. Please try again later!');
			// setShowMessageBanner(true);
		})
	}

	function getNested(arr) {
		if (arr) {
			for (let i of arr) {

				if (i['type'] == 'ActionSet') {
					return i
				}

			}
		}
		return false
	}

	const onDoubleClick = () => {
		if (open) {
			setOpen(false);
			data.open = false;
		} else {
			setOpen(true);
			data.open = true;
		}
	};
	function isJsonString(str) {
		try {
			JSON.parse(str);
			console.log("try")
		} catch (e) {
			console.log("catch")
			return false;
		}
		return true;
	}
	const processCard = () => {
		if (data.type == 'card' && data.card != '') {
			console.log("data.card", data.card)
			if (!isJsonString(data.card)) {
				console.log("invalid json")
				return;
			}
			else {
				let payload = JSON.parse(data.card)["body"]
				let temp = getNested(payload)
				console.log(temp)
				if (temp) {
					getActionList(temp)
				}
			}

		}
	}
	useEffect(() => {
		processCard()
	}, [data])

	const getActions = () => {
		if (data.type == 'card' && actionList.length !== 0 && open) {
			return (
				<div className='augmentsContainer' id={`actions${id}`}>
					<ul className='augmentsList ' id={`ul${id}`}>
						{actionList.actions.map((action, index) => <li key={index} className="augmentsListElement">{action.type.split('.')[1]} - {action.data.actionName}</li>)}
					</ul>
				</div>
			)
		}
	}
	const truncateStrings = (str, length) => {
		if (str) {
			if (length > str.length) {
				return str
			}
			else {
				str = str.substring(0, length) + '...'
				return str;
			}
		}
		else {
			return '';
		}
	}

	return <div className='nodeContainer responseNodeContainer' id={`nodeContainer${id}`} onDragOver={onDragOver} onDrop={onDrop} >
		{data.showConfirmation == true &&
			<div style={{ margin: '3px' }}>
				<div style={{ fontSize: '0.8em' }}>
					Are you sure you want to delete the Response Node?
				</div>
				<div className='text-center' style={{ marginTop: '2px' }}>
					<button className="btn btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { data.showConfirmation = false; }} >
						No
					</button>
					<button className="btn  btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { deleteNodeById(id) }} >
						Yes
					</button>
				</div>
				<hr />
			</div>
		}
		<div className='node' id={`node${id}`} onDoubleClick={onDoubleClick}>
			<Handle
				type='target'
				position={Position.Top}
				id='responseHandle'
				className="react-flow_handle"
			/>
			<Handle
				type='source'
				position={Position.Bottom}
				id='responseSourceHandle'
				className="react-flow_handle"
				isConnectable={!hasSourceConnections}
			/>
			<div>
				<div className='nodeType'>
					Response
				</div>
				<div className='nodeDeleteType' >
					<span className='icon-delete' onClick={() => { console.log(id); data.showConfirmation = true }}></span>
				</div>
				<div className='nodeCount'>
					{data.type}
				</div>

			</div>
			<div className='nodeName'>
				{truncateStrings(data.name, 20)}
			</div>
			<div className='augmentsContainer' id={`response${id}`}></div>
		</div>
		{getActions()}
	</div>
}

export default ResponseNode;
