const faq_url = `${process.env.REACT_APP_ROOT_URL}/bot`;
const user_url = `${process.env.REACT_APP_ROOT_URL}/user`;
//const faq_url = `http://127.0.0.1:8092/api/bot`;

const FAQService = {
    getUserDetails: ()=>{
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          return fetch(user_url, requestOptions);
    },
    getFAQFiles: (botId)=>{
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          return fetch(`${faq_url}/${botId}/faq`, requestOptions);
    },
    getQA: (botId, fileName)=>{
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          return fetch(`${faq_url}/${botId}/faq/qa/${fileName}`, requestOptions);
    },
    filterQA: (botId, fileName, pageNumber, searchFilter)=>{
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          return fetch(`${faq_url}/${botId}/faq/qa/${fileName}/${pageNumber}/${searchFilter}`, requestOptions);
    },
    uplodDocumentInChunks: (botId,isUnformattedUpload, file, fileName, chunkNumber, isLast, userCec)=>{
        var headers = new Headers();
        headers.append("Content-Type", "application/json");

        fileName = fileName.replace(/ /g,"_");

        var raw = JSON.stringify({
            "bot_id": botId,
            "user_cec": userCec,
            "filename": fileName,
            "sequence_id": chunkNumber,
            "is_last": isLast,
            "is_unformatted_upload": isUnformattedUpload,
            "file": file
        });
 
        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        return fetch(`${faq_url}/${botId}/faq`, requestOptions)
    },
    deleteFiles(botId, ids){
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ids": ids
        })
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            body: raw,
            headers: reqHeaders,
          };
        return fetch(`${faq_url}/${botId}/faq`, requestOptions)
    },
    deleteFAQs(botId, fileName, ids){
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "filename": fileName,
            "bot_id": botId,
            "ids": ids,
            
        });

        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            body: raw,
            headers: reqHeaders,
          };
          
        return fetch(`${faq_url}/${botId}/faq/qa`, requestOptions)
    },
    addFaq(botId, fileName, question, answer){
        var headers = new Headers();
        headers.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "filename": fileName,
            "question": question,
            "answer": answer,
            "bot_id": botId
        });

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        return fetch(`${faq_url}/${botId}/faq/qa`, requestOptions)
    },
    updateFaq(botId, rowId, fileName, question, answer, user_cec){
        var headers = new Headers();
        headers.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "_id": rowId,
            "filename": fileName,
            "question": question,
            "answer": answer,
            "user_cec": user_cec
        });

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        return fetch(`${faq_url}/${botId}/faq/qa`, requestOptions)
    },
    getAdvancedSettings(botId){
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        return fetch(`${faq_url}/${botId}/faqAdvancedSettings`, requestOptions);
    },
    updateAdvancedSettings(botId, llm, clientId, clientSecret, llmModelClientUrl, llmModelClientAppKey){
        var headers = new Headers();
        headers.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "llm": llm,
            "llm_client_id": clientId,
            "llm_client_secret": clientSecret,
            "llm_client_url":llmModelClientUrl,
            "llm_client_appkey": llmModelClientAppKey,
            "bot_id": botId
        });

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        return fetch(`${faq_url}/${botId}/faqAdvancedSettings`, requestOptions)
    },
    faqQAFormatter(fileData, fileName){
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        fileName = fileName.replace(/ /g,"_");
        let payload = JSON.stringify({
            "file": fileData,
            "filename": fileName
        })
        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: payload,
            redirect: 'follow'
        }
        return fetch(`${process.env.REACT_APP_ROOT_URL}/faq/Formatter`, requestOptions);
    },
    faqFormatterLambdaWarmpUpCall(){
        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        let payload = JSON.stringify({
            "file": "TG9yZW0gaXBzdW0gZG9sb3Igc2l0IGFtZXQsIGNvbnNlY3RldHVyIGFkaXBpc2NpbmcgZWxpdCwgc2VkIGRvIGVpdXNtb2QgdGVtcG9yIGluY2lkaWR1bnQgdXQgbGFib3JlIGV0IGRvbG9yZSBtYWduYSBhbGlxdWEuIFV0IGVuaW0gYWQgbWluaW0gdmVuaWFtLCBxdWlzIG5vc3RydWQgZXhlcmNpdGF0aW9uIHVsbGFtY28gbGFib3JpcyBuaXNpIHV0IGFsaXF1aXAgZXggZWEgY29tbW9kbyBjb25zZXF1YXQuIA==",
            "filename":"test.txt"
        })
        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: payload,
            redirect: 'follow'
        }
        return fetch(`${process.env.REACT_APP_ROOT_URL}/faq/Formatter`, requestOptions);
    },
    enableGenAi(botId, fileName, genAi){
        var headers = new Headers();
        headers.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "is_ga_enabled": genAi
        });

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };
        return fetch(`${faq_url}/${botId}/faq/${fileName}/gai`, requestOptions)
    },
};

export default FAQService;
