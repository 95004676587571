import './BotViewComponent.css';
import isAlpha from 'validator/lib/isAlpha';
import isAlphanumeric from 'validator/lib/isAlphanumeric';

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import EmailChip from '../../../../custom-components/email-chip/EmailChip';
import MultiSelect from '../../../../custom-components/multi-select/MultiSelect';
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import Spinner from "../../../../custom-components/spinner/Spinner";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import Bot from '../bot-component/BotComponent';
import BotEdit from '../bot-edit-component/BotEditComponent';
import BotService from "../../bot-services/BotService";
import { useEffect } from "react";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import SkillService from "../../../skill-module/skill-services/SkillService";
export const BotView = () => {

    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h6>Welcome to <b>Bot View</b>. Here, you view/create a bot.</h6>,
                locale: { skip: <span aria-label="skip">Skip</span> },
                placement: 'center',
                target: '.pageTour1',
            },
            {
                content: <h6>Click this button, enter all the mandatory fields and click submit to create a bot. </h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour2',
            },
        ]
    });
    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setState({ run: false });
        }
    };
    const [bu_options, setBUOptions] = useState([])

    const getBusinessOrg = () => {
        setDataLoading(true);
        BotService.getBusinessOrg().then(res => res.json()).then(res => {
            if (res["status"] == 'success') {
                setBUOptions(res['bu_org'])
                setDataLoading(false);
            }
            else {
                // console.log("Failed to retrieve Business Orgs List!");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            // console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to retrieve Business Orgs List!");
            setShowMessageBanner(true);
        })
    }


    const [newBotForm, setNewBotForm] = useState({
        "botName": "",
        "botDescription": "",
        "businessUnit": bu_options[0],
        "businessBenefit": "",
        "businessJustification": "",
        "devMembers": [],
        "skills": [],
        "useGenAI": false
    })
    const [listOfBots, setListOfBots] = useState([]);
    const [isDataLoading, setDataLoading] = useState(false);
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [showGuidance, setShowGuidance] = useState(false);
    const [show_create_bot, setShowCreateBot] = useState(false);
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [show_edit_bot, setShowEditBot] = useState(false);
    const [id_of_bot_selected, setBotIdOnSelection] = useState(undefined);
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false)
    const [showWarningModal, setShowWarningMessage] = useState(false);
    const [username, setUsername] = useState('Botlite User');
    const [isDataLoadingDevMembers, setDataLoadingDevMembers] = useState(false);
    const [layoutType, setLayoutType] = useState(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');
    const [devMembersEmails, setDevMembersEmails] = useState([]);
    const [botDetails, setBotDetails] = useState([{ botName: "", botDescription: "", businessBenefit: "", businessJustification: "", devMembers: [] }]);
    const useGenAIDropDownOptions = [{ "_id": "64af8a2c8186e31bc66119a5", "label": "Yes", "value": true }, { "_id": "64af8a2c8186e31bc46119aa", "label": "No", "value": false }];
    const [nameCheckTimeout, setNameCheckTimeout] = useState(0)
    const [showCheckName, setShowCheckName] = useState('blank')
    const inputHandlerChange = (e, type) => {
        setShowValidationErrorMessage(false);
        setSubmitBtnEnable(false);
        var validataTxt = "";
        let botForm = newBotForm;

        if (type == "devMembers") {
            setNewBotForm({ ...newBotForm, "devMembers": e })
            botForm = { ...newBotForm, "devMembers": e }
            console.log(e)
            setDevMembersEmails(e)
            // validateBotCreationForm(type)
        }
        else {
            // console.log(e.target.value)
            if (type === "botName") {
                var charATT = e.target.value.charAt(e.target.value.length - 1)
                if (e.target.value.length === 1 && (!isNaN(e.target.value) || e.target.value === "_")) {
                    validataTxt = e.target.value.replace(e.target.value, "")
                } else {
                    if (charATT === "_") {
                        // setNewBotForm({ ...newBotForm, "botName": e.target.value.toLowerCase() })
			validataTxt = e.target.value.toLowerCase()
                        botForm = { ...newBotForm, "botName": validataTxt }
                    } else {
                        validataTxt = e.target.value.replace(/[^\w\s]/gi, "")
                        botForm = { ...newBotForm, "botName": validataTxt }
                        // setNewBotForm({ ...newBotForm, "botName": validataTxt })
                    }
                }
		if (validataTxt.length >= 5)
			setNameCheckTimeout(setTimeout(() => {checkDuplicateName(validataTxt)}, 3000));
            }
            else if (type === "botDescription") {
                botForm = { ...newBotForm, "botDescription": e.target.value }
                // setNewBotForm({ ...newBotForm, "botDescription": e.target.value })
            }
            else if (type === "businessBenefit") {
                botForm = { ...newBotForm, "businessBenefit": e.target.value }
                // setNewBotForm({ ...newBotForm, "businessBenefit": e.target.value })
            }
            else if (type === "genAI") {
                console.log("useGenAI", e)
                botForm = { ...newBotForm, "useGenAI": e.value }
            }
            else {
                botForm = { ...newBotForm, "businessJustification": e.target.value }
                // setNewBotForm({ ...newBotForm, "businessJustification": e.target.value })
            }
        }

        console.log("newBotForm", newBotForm)
        setNewBotForm(botForm)
        console.log("botForm", botForm)
        let empty = hasEmpty(botForm)
        console.log("empty", empty)
        let valid = false;
        if (!empty) {
            valid = validateBotCreationForm(type, botForm)
        }
        else {
            valid = validateBotCreationForm("all", botForm)
        }

        console.log("valid", valid)
        const result = empty && valid
        // setShowValidationErrorMessage(!result);
        console.log("Result", result)
        setSubmitBtnEnable(result);
    }

    const hasEmpty = (element) => {
        console.log(element)
        return (
            (element.botName != "" && element.botName.length > 0) &&
            (element.botDescription != "" && element.botDescription.length > 0) &&
            (element.businessBenefit != "" && element.businessBenefit.length > 0) &&
            (element.businessJustification != "" && element.businessJustification.length > 0) &&
            (element.devMembers != "" && element.devMembers.length > 0)
        );
    };

    const handleResize = (() => {
        setLayoutType(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');

    })
    function truncateStrings(str, length) {
        if (length > str.length) {
            str = str + " ".repeat(length - str.length)
            return str
        }

        str = str.substring(0, length)
        return str;
    }

    const getBots = () => {
        BotService.listBots().then(res => res.json()).then(res => {
            if (res["status"] == 'success') {
                if (res["bots"].length == 0) {
                    setShowGuidance(true);
                    setDataLoading(false);
                }
                else {
                    setListOfBots((prev) => {
                        let botsLitsFromApi = res["bots"];

                        for (let i = 0; i < botsLitsFromApi.length; i++) {
                            // add missing fields to bots list
                            if (botsLitsFromApi[i]["description"] === undefined) {
                                botsLitsFromApi[i]["description"] = ""
                            }
                            if (botsLitsFromApi[i]["active_users"] === undefined) {
                                botsLitsFromApi[i]["active_users"] = 0
                            }
                            if (botsLitsFromApi[i]["business_unit"] === undefined) {
                                botsLitsFromApi[i]["business_unit"] = { label: 'Sales', value: 'sales' }
                            }
                            if (botsLitsFromApi[i]["integration"] === undefined) {
                                botsLitsFromApi[i]["integration"] = [Math.floor(Math.random() * 99) % 2 === 0 && 'webex', Math.floor(Math.random() * 99) % 2 === 0 && 'widget', Math.floor(Math.random() * 99) % 2 === 0 && 'slack']
                            }
                            if (botsLitsFromApi[i]["training_model"] === undefined) {
                                botsLitsFromApi[i]["training_model"] = "DT"
                            }
                            if (botsLitsFromApi[i]["training_percentage"] === undefined) {
                                botsLitsFromApi[i]["training_percentage"] = Math.floor(Math.random() * 99);
                            }
                            if (botsLitsFromApi[i]["dev_members"] === undefined) {
                                botsLitsFromApi[i]["dev_members"] = []
                            }
                            if (botsLitsFromApi[i]["skills"] === undefined) {
                                botsLitsFromApi[i]["skills"] = []
                            }
                            if (botsLitsFromApi[i]["externalise"] === undefined) {
                                botsLitsFromApi[i]["externalise"] = Math.floor(Math.random() * 99) % 2 === 0 ? true : false;
                            }
                        }
                        setDataLoading(false);
                        return botsLitsFromApi;
                    });
                }
            }
            else {
                // console.log("Failed to retrieve bots");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            // console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve bots!");
            setShowMessageBanner(true);
        })
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        let userEmail = localStorage.getItem("email");
        if (userEmail !== undefined) {
            setUsername(userEmail);
            updateDevEmails([userEmail])
        }
        setDataLoading(true);
        getBots();

    }, [])
    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }

    const validateBotCreationForm = (key, botForm) => {
        // console.log(key)

        // let botForm = newBotForm;
        // console.log(botForm)
        if (key == "useGenAI" || key == "all") {
            if (botForm.useGenAI !== false && botForm.useGenAI !== true) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage("Please select a valid response for 'Do you intend to utilize BotLite GenAI's capabilities?'.");
                return false;
            }
        }
        if (key == "botName" || key == "all") {
            if (botForm.botName === undefined || botForm.botName.length < 3) {
                // conskole.log("1")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Bot name should be of minimum than 3 characters.');
                return false;

            }

            if (!isAlphanumeric(botForm.botName, 'en-US', { "ignore": "_" })) {
                // console.log("2")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in bot name.');
                return false;
            }
        }
        if (key == "botDescription" || key == "all") {
            if (botForm.botDescription === undefined || botForm.botDescription.length < 20) {
                // console.log("3")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Bot description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(botForm.botDescription)) {
                // console.log("4")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in bot description.');
                return false;
            }
        }

        if (key == "businessBenefit" || key == "all") {
            if (botForm.businessBenefit === undefined || botForm.businessBenefit.length < 10) {
                // console.log("5")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('\'What is your intended use for the bot field\' should be greater than 10 characters');
                return false;

            }
            if (!validateAlphaNumericString(botForm.businessBenefit)) {
                // console.log("6")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in plan on using bot');
                return false;
            }
        }
        if (key == "businessJustification" || key == "all") {
            if (botForm.businessJustification === undefined || botForm.businessJustification.length < 10) {
                // console.klog("7")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Business Justification should be greater than 10 characters');
                return false;

            }
            if (!validateAlphaNumericString(botForm.businessJustification)) {
                // console.log("8")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in bot justification');
                return false;
            }
        }
        if (key == "devMembers" || key == "all") {
            if (botForm.devMembers === undefined || botForm.devMembers.length == 0) {
                // console.log("9")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('It is mandatory to add atleast one bot owner to a bot (e.g: dev@cisco.com)');
                return false;
            }
            if (botForm.devMembers.length == 1 && botForm.devMembers[0] == username) {
                // console.log("10")
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('You need to add a different email apart from your own email.');
                return false;
            }
        }
        return true;
    }
    const deleteBot = (id) => {
        setShowValidationErrorMessage(false);
        setDataValidationErrorMessage('');

        setShowCreateBot(false);
        setDataLoading(true);
        BotService.deleteBot(id).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                // console.log(res);

                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage("Succesfully deleted the Bot!");
                setShowMessageBanner(true);
                getBots();
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }

        }).catch(err => {
            // console.log(err);

            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to delete the bot! Please try again.");
            setShowMessageBanner(true);
        })
    }
    const clearForm = () => {
        setNewBotForm({
            "botName": "",
            "botDescription": "",
            "businessUnit": bu_options[0],
            "businessBenefit": "",
            "businessJustification": "",
            "devMembers": [],
            "skills": []
        });
        setSubmitBtnEnable(false)
        // console.log(newBotForm)
    }

    const createBot = () => {
        setShowValidationErrorMessage(false);
        setDataValidationErrorMessage('');

        // data validation

        if (!validateBotCreationForm('all', newBotForm)) {
            return;
        }

        setShowCreateBot(false);
        setDataLoading(true);
        let skillList = [];
        for(let i=0;i<newBotForm.skills.length;i++){
            // remove default skill from payload as it is add by default in backend
            if(newBotForm.skills[i]["name"] !== "default"){
                skillList.push(newBotForm.skills[i]);
            }
            
        }
        let reqPayload = {
            "display_name": newBotForm.botName,
            "description": newBotForm.botDescription,
            "icon": "",
            "activate": true,
            "business_benefit": newBotForm.businessBenefit,
            "business_justification": newBotForm.businessJustification,
            "dev_members": newBotForm.devMembers,
            "skills": skillList,
            "useGenAI": newBotForm.useGenAI

        }

        if (newBotForm.businessUnit) {
            reqPayload["business_unit"] = newBotForm.businessUnit.value;
        }
        else {
            reqPayload["business_unit"] = ""
        }

        BotService.createBot(reqPayload).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                // console.log(res);
                setShowGuidance(false);
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                getBots();
                clearForm();

            }
            else {


                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                // clearForm();
            }

        }).catch(err => {
            // console.log(err);

            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to create bot! Please try again.");
            setShowMessageBanner(true);

        })
    }
    const resizer = document.getElementById('bot_view_panel')

    const closeEditBotPanel = () => {
        setShowEditBot(false);
    }
    const showEditBotPanel = (id) => {
        // console.log("Open panel for ", id);
        setBotIdOnSelection(id);
        setShowEditBot(true);
    }
    const getBotById = (id) => {
        for (let bot of listOfBots) {
            if (bot["_id"] === id) {
                return bot;
            }
        }
    }
    function botSubmission(e) {
        e.preventDefault()
        // console.log(e.target);
    }

    useEffect(() => {
        getSkills();
        getBusinessOrg();
    }, [])
    const [skills, setSkills] = useState([])
    const getSkills = () => {
        SkillService.listSkills().then(res => res.json()).then(res => {
            if (res) {
                var vals = res.skills.map((data) => {
                    return (
                        { "id": data._id, "name": data.name }
                    )
                })
                setSkills(vals);
            } else {
                console.log("error")
            }
        })
    }
    function changeBotListOrder(id) {
        setListOfBots(() => {
            // console.log(id);
            let index = listOfBots.map(object => object._id).indexOf(id);
            // console.log(index);
            let newListOfBots = [listOfBots[index]];
            // console.log(newListOfBots);
            for (let i = 0; i < listOfBots.length; i++) {
                if (i != index) {
                    newListOfBots.push(listOfBots[i]);
                }

            }

            return newListOfBots;
        })

    }
    const warningModal = () => {
        return <Modal size="sm" centered show={showWarningModal} onHide={() => { setShowWarningMessage(false) }} animation={false} >
            <Modal.Header style={{ color: "#fbab18" }}>
                <h5><b>Warning</b></h5>
            </Modal.Header>
            <Modal.Body>
                <div> Are you sure you want to delete the bot?</div>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary" onClick={() => { setShowWarningMessage(false) }}>
                    Cancel
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" onClick={() => { setShowWarningMessage(false); deleteBot(id_of_bot_selected); }}>
                    Delete
                    {/* <span className="icon-delete icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>
    }


    const updateDevEmails = (emails) => {
        setShowValidationErrorMessage(false);
        setDataLoading(true);
        let dev_list = newBotForm['devMembers']
        let difference = emails.filter(x => !dev_list.includes(x)) + dev_list.filter(x => !emails.includes(x));
        // console.log("difference", difference.length);
        if (difference.length !== 0) {
            inputHandlerChange(emails, "devMembers");
        }
        setDataLoading(false);
    }

    const validateEmail = async (newEmail) => {
        setShowValidationErrorMessage(false);
        setDataLoadingDevMembers(true);
        setShowMessageBanner(false);
        let validationResponse = await BotService.validateUsers({
            "users": [newEmail]
        })
        if (validationResponse.status === 200) {
            let data = await validationResponse.json();
            if (data["status"] === "success") {
                if (data["invalid_users"].indexOf(newEmail) === -1) {
                    setDataLoadingDevMembers(false);
                    return true;
                }
                else {
                    setDataValidationErrorMessage('The specified bot owner(s) ' + data['invalid_users'].join(', ') + '  is invalid. Please re-enter the information and attempt again.');
                }
            }
            else {
                setDataValidationErrorMessage("Something went wrong while validating the bot owners. Please try again later!");
            }
        }
        else {
            setDataValidationErrorMessage("Something went wrong while validating the bot owners. Please try again later!");
        }
        setShowValidationErrorMessage(true);
        setSubmitBtnEnable(false);
        setDataLoadingDevMembers(false);
        return false;
    }

	const checkDuplicateName = () => {
		let data = {display_name: newBotForm.botName}
		BotService.checkDuplicateName(data).then(res => res.json()).then(res => {	
			if (newBotForm.botName === res.bot_name)
				setShowCheckName(res.status)
		}).catch(err => console.log(err))
	}

    return (
        <div>
            {isDataLoading != true && showGuidance ? <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                    buttonNext: {
                        background: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    buttonBack: {
                        color: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                    buttonSkip: {
                        color: '#1e4471',
                        border: '1px solid #1e4471',
                        borderRadius: '5px'
                    },
                }}
            /> : ""}
            {
                isDataLoading && (<Spinner></Spinner>)
            }

            <div style={{ borderBottom: '1px #ced4da solid' }} className="pageTour1">

                <div className="row base-margin-top base-margin-right" >
                    <h5 className="col-7 col-md-8 col-sm-9 col-lg-10 col-xl-10 col-xxl-11 col-xxxl-11" >Bots</h5>
                    <div className='col-5 col-md-4 col-sm-3 col-lg-2 col-xl-2 col-xxl-1 col-xxxl-1 flex flex-right'>
                        {!showGuidance && <button className='btn btn--small btn--secondary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down pageTour2' style={{ top: '-0.7vh' }} onClick={() => { setShowValidationErrorMessage(false); setDataValidationErrorMessage(''); setShowCreateBot(true) }}>
                            Create
                            {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                        </button>}
                    </div>
                </div>
            </div>
            {
                showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
            }
            {
                isDataLoading && (<Spinner style={{ zIndex: '1042' }}></Spinner>)
            }
            {showGuidance && <div className="alert alert--info  flex flex-center flex-middle center panel" style={{ "position": "relative", "left": "35%", width: '70%', marginTop: '15%', "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                <h6 style={{ lineHeight: '1.6', textAlign: 'center' }}>You have not created a bot yet.
                    <br />
                    <br />
                    <button className='btn btn--secondary pageTour2' onClick={() => { setShowValidationErrorMessage(false); setDataValidationErrorMessage(''); setShowCreateBot(true) }}>
                        Create
                        {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                    </button>
                    <br />
                    <br />
                    Require assistance? Click on <b>Help</b> or chat with our bot, <b>AskBotLite</b>.
                </h6>



            </div>}
            <div className=' base-margin pageTour4' id="wrapper_div" style={{ 'display': 'flex', height: 'calc(100vh - 140px)' }}>

                {listOfBots && <div id="bot_view_panel" className={show_edit_bot ? 'hide-scroll-bar flex flex-wrap flex-center base-padding pageTour3' : 'flex flex-wrap flex-center pageTour3'} style={{ "maxHeight": "85vh", overflowY: show_edit_bot ? "" : "auto", "display": show_edit_bot && resizer.parentNode.getBoundingClientRect().width < 1137.98 ? 'none' : 'flex', 'width': show_edit_bot && resizer.parentNode.getBoundingClientRect().width < 1137.98 ? '100%' : show_edit_bot ? '35%' : '100%', borderRight: !(show_edit_bot && resizer.parentNode.getBoundingClientRect().width > 1137.98) ? '' : '1px solid #adb5bd' }}>
                    {
                        show_edit_bot && <div>
                            <div className="panel panel--bordered panel--raised base-margin" style={{ "borderColor": "#64bbe3", "borderWidth": "2px", "backgroundColor": "rgb(100 187 227 / 60%)", "borderRadius": "20px", paddingRight: "16px" }}>
                                <div style={{ height: "100%", "width": "23vw" }} className="base-margin" >
                                    <button className="btn btn--circle btn--ghost" style={{ visibility: getBotById(id_of_bot_selected)['externalise'] ? 'visible' : 'hidden', float: "right" }}>
                                        <span className="icon-star"></span>
                                    </button>
                                    <div className="row">
                                        <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3">
                                            <img src={process.env.PUBLIC_URL + "/images/boticon.png"} alt="botLogo" style={{ borderRadius: '50%', width: "70px" }} />
                                        </div>
                                        <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
                                            <div className="bot-name" style={{ "position": "relative", "top": "25px" }}>
                                                <h6 style={{ "whiteSpace": "pre-wrap" }}><b>{truncateStrings(getBotById(id_of_bot_selected)['display_name'], 50)}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    }
                    {
                        show_edit_bot && <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto', basePadding: '5px' }}>{
                            listOfBots.map((bot, index) => {
                                if (bot["_id"] != id_of_bot_selected)
                                    return <Bot key={index} index={index} bot={bot} onBotClick={(id) => { showEditBotPanel(id); changeBotListOrder(id) }} show_edit_bot={show_edit_bot} selectedID={id_of_bot_selected} />
                            })
                        }</div>
                    }

                    {
                        !show_edit_bot && listOfBots.map((bot, index) => {
                            return <Bot key={index} index={index} bot={bot} onBotClick={(id) => { showEditBotPanel(id); changeBotListOrder(id) }} show_edit_bot={show_edit_bot} selectedID={id_of_bot_selected} onBotDelete={(id) => { setShowWarningMessage(true); setBotIdOnSelection(id); }} />
                        })
                    }

                </div>}

                <div id="bot_edit_panel" className={show_edit_bot ? 'panel panel--bordered base-margin-left' : 'hide-edit-bot-panel'} style={{ 'width': show_edit_bot && resizer.parentNode.getBoundingClientRect().width < 1137.98 ? '100%' : '68.5%' }}>
                    <BotEdit bot={getBotById(id_of_bot_selected)} onCloseClicked={() => { closeEditBotPanel() }} getBots={() => { getBots() }} username={username}></BotEdit>
                </div>
            </div>


            <Modal size="lg" centered show={show_create_bot} animation={false} >
                <Modal.Header >
                    <h5>Create Bot</h5>
                    <div className=' btn--small btn--icon' onClick={() => { clearForm(); setShowCreateBot(false); }}>
                        <span className="icon-close icon-size-24"></span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                    }
                    <form onSubmit={botSubmission}>
                        <div className='center base-padding col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                            {
                                layoutType === "horizontal" ? (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group base-margin-bottom">
                                                    <div className="form-group__text">
                                                        <input id="input-type-number" type="text" autoFocus autoComplete="off" name="bot_name" value={newBotForm.botName} onChange={e => { inputHandlerChange(e, "botName"); setShowCheckName('loading'); clearTimeout(nameCheckTimeout); }} />
                                                        <label htmlFor="input-type-number">Bot Name <span className='required'>*</span></label>
                                                    </div>
					{showCheckName === 'failure' ? <span className='icon-close' style={{color: 'red'}}>Bot Name is already taken</span> : showCheckName === 'success' ? <span><span className='icon-check' style={{color: 'green'}}></span> Bot Name is available</span> : showCheckName === 'loading' ? <span className='icon-spinner'></span> : <span></span>}
                                                </div>
						
                                                <div className="form-group dropdown " style={{ paddingTop: '5px' }}>
                                                    <div className="form-group">
                                                        <label htmlFor="select-type-basic">Business Unit / Org </label>
                                                        <SearchSelect placeholder={'Select the Business Unit / Org'} options={bu_options} onValueChange={(data) => { setNewBotForm({ ...newBotForm, "businessUnit": data }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group base-margin-bottom">
                                                    <div className="form-group__text">
                                                        <textarea id="textarea-state-default" rows={6} name="bot_description" value={newBotForm.botDescription} onChange={e => { inputHandlerChange(e, "botDescription") }}></textarea>
                                                        <label htmlFor="input-type-number">Bot Description <span className='required'>*</span> </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group base-margin-bottom">
                                                    <div className="form-group__text">
                                                        <textarea id="textarea-state-default" rows={3} name="plan_on_use" value={newBotForm.businessBenefit} onChange={e => { inputHandlerChange(e, "businessBenefit") }}></textarea>
                                                        <label htmlFor="input-type-number">What is your intended use for the bot? <span className='required'>*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group base-margin-bottom">
                                                    <div className="form-group__text">
                                                        <textarea id="textarea-state-default" rows={3} name="business_justification" value={newBotForm.businessJustification} onChange={e => { inputHandlerChange(e, "businessJustification") }}></textarea>
                                                        <label htmlFor="input-type-number">Business Justification <span className='required'>*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
					<div className='row'>
						<div className="col form-group dropdown base-margin-bottom" style={{ paddingTop: "5px" }}>
                                			<div className="form-group" style={{ width: "350px" }}>
                                    				<div style={{ marginBottom: "8px" }}>Do you intend to utilize BotLite GenAI's capabilities? <span className='required'>*</span></div>
                                    				<SearchSelect placeholder={'Select your choice'} options={useGenAIDropDownOptions} onValueChange={e => { inputHandlerChange(e, "genAI") }} />
                                			</div>
                            			</div>
						<div className='col base-margin' style={{ paddingTop: '20px' }}>
                                			<label className="switch">
                                    				<input type="checkbox" style={{ marginBottom: '8px' }} />
				                                    <span className='switch__label'>Enable Translation</span>
                                				    <span className="switch__input"></span>
                                			</label>
                            			</div>
					</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className="col form-group base-margin-bottom">
                                                <div className="form-group__text">
                                                    <input id="input-type-number" type="text" autoFocus name="bot_name" value={newBotForm.botName} onChange={e => { inputHandlerChange(e, "botName") }} />
                                                    <label htmlFor="input-type-number">Bot Name <span className='required'>*</span></label>
                                                </div>
                                            </div>
					    <div><button onClick={() => {checkDuplicateName()}}>Check</button></div>
                                        </div>
                                        <div className="row">
                                            <div className="col form-group dropdown " style={{ paddingTop: '5px' }}>
                                                <div className="form-group">
                                                    <label htmlFor="select-type-basic">Business Unit / Org </label>
                                                    <SearchSelect placeholder={'Select the Business Unit / Org'} options={bu_options} onValueChange={(data) => { setNewBotForm({ ...newBotForm, "businessUnit": data }) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col form-group base-margin-bottom">
                                                <div className="form-group__text">
                                                    <textarea id="textarea-state-default" rows={6} name="bot_description" value={newBotForm.botDescription} onChange={e => { inputHandlerChange(e, "botDescription") }}></textarea>
                                                    <label htmlFor="input-type-number">Bot Description <span className='required'>*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col form-group base-margin-bottom">
                                                <div className="form-group__text">
                                                    <textarea id="textarea-state-default" rows={3} name="plan_on_use" value={newBotForm.businessBenefit} onChange={e => { inputHandlerChange(e, "businessBenefit") }}></textarea>
                                                    <label htmlFor="input-type-number">What is your intended use for the bot? <span className='required'>*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col form-group base-margin-bottom">
                                                <div className="form-group__text">
                                                    <textarea id="textarea-state-default" rows={3} name="business_justification" value={newBotForm.businessJustification} onChange={e => { inputHandlerChange(e, "businessJustification") }}></textarea>
                                                    <label htmlFor="input-type-number">Business Justification <span className='required'>*</span></label>
                                                </div>
                                            </div>
                                        </div>
					<div className='row'>
						<div className="col form-group dropdown base-margin-bottom" style={{ paddingTop: "5px" }}>
                                			<div className="form-group" style={{ width: "350px" }}>
                                    				<div style={{ marginBottom: "8px" }}>Do you intend to utilize BotLite GenAI's capabilities? <span className='required'>*</span></div>
                                    				<SearchSelect placeholder={'Select your choice'} options={useGenAIDropDownOptions} onValueChange={e => { inputHandlerChange(e, "genAI") }} />
                                			</div>
                            			</div>
					</div>
					<div className='row'>
						<div className='base-margin-top base-margin-bottom'>
                                			<label className="switch">
		                                    <input type="checkbox" />
        		                            <span className='switch__label'>Enable Translation</span>
                		                    <span className="switch__input"></span>
                        			        </label>
                            			</div>
					</div>
                                    </>
                                )
                            } 
                            <div>
                                <label htmlFor="input-type-number">Bot Owners <span className='required'>* </span><b class="info">  (e.g: xyz@cisco.com)</b>&nbsp;
                                    <span data-balloon-length="medium" data-balloon="Minimum two (or more) users should be configured as Bot Owners. Bot Owners would have access to the Bot and the associated Skills." data-balloon-pos="down"><span class="icon-info-outline"></span></span>
                                    {isDataLoadingDevMembers && <div class="loading-dots" aria-label="Loading, please wait...">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>}</label>
                                <EmailChip email_list={newBotForm.devMembers} placeholder={devMembersEmails.length == 0 ? "Please enter your Cisco Email ID and hit enter" : "Please enter another Cisco Email ID and hit enter"} onVerifyEmail={validateEmail} onDataChanged={(emails) => { updateDevEmails(emails) }} />
                            </div>
                            <div className='base-margin-top'>
                                <div style={{ display: skills.length == 0 ? 'inherit' : "none" }} className="alert alert--info">
                                    <span>It seems like you don't have access to/created any skill.</span>
                                    <br />
                                </div>
                                <div style={{ display: skills.length == 0 ? 'inherit' : "none" }} className="alert alert--info">
                                    <span>Select either Skills from the side bar menu to create a new skill or select <b>Skill library</b> to view/reuse from an existing list of skills.</span>

                                </div>
	    			<label htmlFor="input-type-number">Skills</label>
				<div style={{ display: skills.length !== 0 ? 'inherit' : 'none', textAlign: 'justify' }}><MessageBanner>default_skill is added to all bots by default. This includes the clear and who_am_i intents with corresponding expressions and rules already included and configured. clear intent resets the contextual memory of the bot, clearing stored context variables from previous interactions with the user.</MessageBanner></div> 
                                <MultiSelect
                                    option={skills.filter(value => value.id !== "64a3b39d5e96d78dc5922a10")}
                                    placeholder="Select Skills"
                                    selectedValues={newBotForm.skills}
                                    onValueChange={(data) => { setNewBotForm({ ...newBotForm, "skills": data }); console.log(skills) }} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="base-margin-left base-margin-right">
                    {/* <button className="btn btn--secondary" onClick={() => { clearForm(); setShowCreateBot(false); console.log(newBotForm) }}>
                        Cancel
                        <span className="icon-close icon-size-18 base-margin-left"></span>
                    </button> */}
                    <button className="btn btn--secondary" disabled={!submitBtnEnable} onClick={() => createBot()}>
                        Submit
                        {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                    </button>
                </Modal.Footer>
            </Modal>
            {warningModal()}


        </div >



    )
}

export default BotView;
