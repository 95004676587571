import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import './Header.css';
import { Link } from "react-router-dom";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UserManagementService from "../../modules/user-management-module/user-management-service/UserManagementService";
import Modal from 'react-bootstrap/Modal';

const Header = ({ onToggleClick = () => { }, isNavbarOpen = true }) => {
    const [userName, setUserName] = useState("BotLite User");
    const [showMenu, setShowMenu] = useState(false);
    const [showHelpScreen, setShowHelpScreen] = useState(false);
    const [isButtonHidden, setIsButtonHidden] = useState(true);
    const [isBotLiteAdmin, setIsBotLiteAdmin] = useState(false);
    const location = useLocation();
    const botliteHomeUrl = process.env.REACT_APP_ROOT_URL.split("/api")[0] + "/home";

    useEffect(() => {
        let userName = localStorage.getItem("full_name");
        let isButtonHidden = localStorage.getItem("disable_botlite2_button")
        if (isButtonHidden === "true"){
            setIsButtonHidden(true);
        }
        else{
            setIsButtonHidden(false);
        }
        if (userName) {
            setUserName(userName)
        }
        checkBotLiteAdmin();
    }, [])

    const [showAlert, setShowAlert] = useState(false);

    const closeAlert = () => {
        setShowAlert(false);
    };

    const showAlertWithButtons = () => {
        setShowAlert(true);
    };

    const redirectTo2 = () => {
        window.location.href = process.env.REACT_APP_CAE_URL; 
    }

    const checkBotLiteAdmin = () => {
        UserManagementService.isBotLiteAdmin()
            .then((res) => res.json())
            .then((res) => {
                if (res["status"] === "success" && res["isBotLiteAdmin"]) {
                    setIsBotLiteAdmin(true);
                }
            })
            .catch((err) => {
                console.log("Not BotLite admin.");
            });
    }
    
    const helpModal = (page) => {
        switch (page) {
            case "/":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h2>Build Your Own Bot</h2>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#useCase">Determine Your Use Case</a></li>
                                    <li> • </li>
                                    <li><a href="#createBot">Create a Bot </a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="useCase">Determine Your Use Case - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Create-Bot.aspx#determine-your-use-case" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>The first step when you decide to build a conversational bot is to choose an appropriate use case that would be helpful to the users. In many situations, a voice or chat conversation with a bot can assist the user to find information or accomplish a task. If you can answer the below mentioned questions (Questions are referred from Mindmeld original documentation) before selecting a use case, you should consider that use case and build a conversational bot.
                            </p>
                            <ol class="text-left">
                                <li>Do users feel that they are talking to a real human while they are conversing with your bot?</li>
                                <li>Do users save their time and accomplish any task by just conversing with your bot?</li>
                                <li>Can a user with non-technical background understand and use your bot comfortably?</li>
                                <li>Do you think your bot is narrow enough that it is feasible for machine learning models to achieve high accuracy?</li>
                                <li>Do you think your bot is broad enough that users can gain a useful experience for a variety of tasks?</li>
                                <li>Is it easy to collect training data for your bot?</li>
                            </ol>
                            <br></br>
                            <h4 id="createBot">Create a Bot - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Create-Bot.aspx#create-a-bot" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <ol class="text-left">
                                <li>After determining your use case, you can create a bot by clicking the Create a Bot button in the top right corner of the screen.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/CreateIntent.png"} class="help-reduce-img-size"></img>
                                <br></br>

                                <li>A window will pop up where you can enter all of your bot's information along with adding Dev Members so that you can work on the bot collaboratively.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/bot-help-images/CreateBotFields.png"} alt="Create Bot Fields" class="center"></img>
                                <br></br>

                                <li>After entering all of the fields, you can click Submit to create your bot. </li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/bot-help-images/SubmitBot.png"} alt="Save Changes" class="help-reduce-img-size"></img>

                            </ol>

                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Create-Bot.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/skills":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Create Skills</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#skills">Skills</a></li>
                                    <li> • </li>
                                    <li><a href="#skill_view">Skill View</a></li>
                                    <li> • </li>
                                    <li><a href="#my_skills">My Skills</a></li>
                                    <li> • </li>
                                    <li><a href="#create_skill">Create a Skill</a></li>
                                    <li> • </li>
                                    <li><a href="#create_skill_version">Create a Skill Version</a></li>
                                    <li> • </li>
                                    <li><a href="#delete_skill">Delete Skill</a></li>
                                    <li> • </li>
                                    <li><a href="#skill_versions">Skill Versions</a></li>
                                    <li> • </li>
                                    <li><a href="#publish_skill_version">Publish Skill Version</a></li>
                                    <li> • </li>
                                    <li><a href="#revert_skill">Revert Skill</a></li>
                                    <li> • </li>
                                    <li><a href="#delete_skill_version">Delete Skill Version</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="skills">Skills - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#skills" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Skills are a way to group your bot's Intents, Entities, and Dialog that work together to perform a set of actions for the user. An example of this would be creating a Skill that can book a flight for a user. Contained in this Skill would be the Intents that determine if the user is asking to book a flight, location Entities to store relevant locations that the user requests for, and the Dialog that requests for more information if needed and books the ticket using APIs. Skills can also be used to group frequently asked questions together. When you create a Skill, you will have the option to upload your skill to the Skill Library.
                            </p>
                            <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/Skills.png"} alt="Skills" class="center"></img>
                            <ul>
                                <li><b>Intent:</b> A mapping between what the user asks for and the required action to be taken by the bot's logic. Ex. In the question, “What is the weather in San Jose?“, Weather is the Intent.</li>
                                <li><b>Entity:</b> A word or phrase that provides information necessary to fulfill a particular intent. Ex. A book_flight intent could have a location entity for words like 'Miami' or 'New York'.</li>
                                <li><b>Dialog:</b> Dialog allows you to create responses and actions whenever an intent is recognized.</li>
                            </ul>
                            <br></br>
                            
                            <h4 id="skill_view">Skill View - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#skill-view" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Skill View is where you can manage all of the skills you have created, copied, or created versions of. There are three tabs on this page:
                            </p>
                            <div class="help-tab">
                                <ol>
                                    <li><b>My Skills:</b> All skills that you have created yourself.</li>
                                    <li><b>Shared Skills:</b> Skills that you have copied (cloned or read-only) from the Skill Library.</li>
                                    <li><b>Skill Versions:</b> All skill versions that you have created from your own skills.</li>
                                </ol>
                            </div>
                            <br></br>

                            <hr class="solid"></hr>

                            <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/MySkills.png"} alt="Skills" class="center"></img>
                            <h4 id="my_skills">My Skills - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#my-skills" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>This tab contains all of the skills that you have created. From this page you can manage each individual skill by either creating a skill version or deleting the skill. You can also view how many users have copied each skill from the Skill Library represented by the number inside each published skill.
                            </p>

                            <h4 id="create_skill">Create a Skill - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#create-a-skill" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Follow the below steps to create a skill in the My Skills tab:
                            </p>
                            <ol class="text-left">
                                <li>Click the Create button in the top right of the screen to begin creating your skill.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/CreateIntent.png"} alt="Create skill" class="help-reduce-img-size"></img>
                                <br></br>

                                <li>Enter the skill name and description in the following fields.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/CreateSkillFields.png"} alt="Create skill Fields" class="center"></img>
                                <br></br>

                                <li>After entering the details, you can click Submit to create the Skill.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit skill" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <h4 id="create_skill_version">Create a Skill Version - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#create-a-skill-version" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>A Skill version is a checkpoint that captures the current state of the Skill and saves it so that any future changes will not affect that particular version. This allows you to revert back to this checkpoint anytime or publish this version to the Skill Library if you want other users to utilize the capabilities you have created.
                            </p>
                            <ol class="text-left">
                                <li>To create a skill version, click the skill version icon in the bottom right corner of the skill.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/CreateSkillVersion.png"} alt="Create skill version" class="help-reduce-img-size"></img>
                                <br></br>

                                <li>Enter the skill version name and detailed description to describe the current capabilities of the current state of this skill.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/CreateSkillVersionFields.png"} alt="skill version fields" class="center"></img>
                                <br></br>

                                <li>Click the Submit button to create the skill version. To view the skill version after clicking submit, you cab go to the Skill Versions tab.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="create version" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <h4 id="delete_skill">Delete Skill - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#delete-skill" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>When a skill is deleted, all users with a clone or read-only copy of the skill versions will be notified that there will be no further updates to that skill and that it is deprecated. The skill owner will have 30 days from the time of deletion to revert the deletion and have the skill and skill versions added back to their Skill View. To do this, contact the BotLite team at: <a href="mailto:bot-lite@cisco.com"> bot-lite@cisco.com</a>.
                            </p>
                            <ol class="text-left">
                                <li>To delete a skill, click the trash bin icon in the skill that you wish to be deleted.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/TrashBin.png"} alt="trash bin" class="help-reduce-img-size1"></img>
                                <br></br>

                                <li>A confirmation window will appear to confirm the correct skill that will be deleted.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/ConfirmDelete.png"} alt="confirm deletion" class="center"></img>
                                <br></br>

                                <li>Click the Confirm button to delete the skill.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/DeleteConfirm.png"} alt="confirm deletion" class="help-reduce-img-size"></img>
                            </ol>

                            <hr class="solid"></hr>

                            <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/SkillVersions.png"} alt="Skill Versions" class="center"></img>
                            <h4 id="skill_versions">Skill Versions - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#skill-versions" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>In Skill Versions, we can find and manage all of the skill versions that you have created. You can also publish these skills to the Skill Library if you would like to share the skill version with other users in BotLite.
                            </p>

                            <h4 id="publish_skill_version">Publish Skill Version - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#publish-skill-version" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Publishing a skill version allows other users to use your skill in their own bots. This is useful if you have created unique capabilities that you believe could be useful in other bots. When publishing your skill version, you can either allow users to full Clone the skill version or only create a Read-only copy of it. A <b>Clone</b> copy of a Skill allows users to fully copy the Skill and change any part of the Skill. A <b>Read-only</b> copy of a Skill only allows users to use the Skill without being able to make any changes or view sensitive details.
                            </p>

                            <p>Here are the steps to publish a skill Version:</p>
                            <ol class="text-left">
                                <li>Click the Publish button in the bottom right corner of the skill version you wish to publish.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/PublishSkill.png"} alt="Publish skill" class="help-reduce-img-size1"></img>
                                <br></br>

                                <li>Enter the skill name and description in the following fields.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/PublishSkillVersionFields.png"} alt="Create skill Fields" class="center"></img>
                                <br></br>

                                <li>After entering the details, you can click Submit to create the Skill.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/PublishSkillVersion.png"} alt="Submit skill" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <h4 id="revert_skill">Revert Skill - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#revert-skill" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Reverting a Skill will replace everything in the skill currently with the data that was captured when that skill version was created. This is helpful for when you would like to remove changes that have been implemented after creating the skill version or when you need to revert back to a working version of the skill.
                            </p>
                            <ol class="text-left">
                                <li>To revert your skill to a different version, click the revert icon in the skill version you want to revert to.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/RevertSkill.png"} alt="Revert skill" class="help-reduce-img-size1"></img>
                                <br></br>

                                <li>The following confirmation window will appear to confirm the correct skill version that you would like to revert to.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/RevertSkillFields.png"} alt="Revert skill" class="center"></img>
                                <br></br>

                                <li>Click the Confirm button after confirming that you have clicked the correct skill version.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/DeleteConfirm.png"} alt="Confirm Revert" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <h4 id="delete_skill_version">Delete Skill Version - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx#delete-skill-version" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>When a skill version is deleted, all users with a clone or read-only copy of the skill version will be notified that the skill version is no longer available in the Skill Library and to look for newer versions of that skill. The skill version owner will have 30 days from the time of deletion to revert the deletion and have the skill version added back to their Skill View. To do this, contact the BotLite team at: <a href="mailto:bot-lite@cisco.com"> bot-lite@cisco.com</a>.
                            </p>
                            <ol class="text-left">
                                <li>To delete a skill version, click the trash bin icon in the skill version that you wish to be deleted.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/TrashBin.png"} alt="Delete skill" class="help-reduce-img-size1"></img>
                                <br></br>

                                <li>A confirmation window will appear to confirm the correct skill version that will be deleted.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/ConfirmDeleteVersion.png"} alt="Delete skill version" class="center"></img>
                                <br></br>

                                <li>Click the Confirm button to delete the skill version.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/skill-help-images/DeleteConfirm.png"} alt="Confirm Delete" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Skills.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/entity":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Create Entity</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#enttiy">Entity</a></li>
                                    <li> • </li>
                                    <li><a href="#entityRole">Entity Role</a></li>
                                    <li> • </li>
                                    <li><a href="#entityValue">Entity Value</a></li>
                                    <li> • </li>
                                    <li><a href="#createEntityStatic">Create an Entity - Static</a></li>
                                    <li> • </li>
                                    <li><a href="#createEntityAPI">Create an Entity - API</a></li>
                                    <li> • </li>
                                    <li><a href="#createEntityFile">Create an Entity - File Upload</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="entity">Entity  - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#entity" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>An Entity is a word or phrase that provides information necessary to fulfill a particular intent. For example, a book_flight intent could have a location entity for words like 'Miami' or 'New York'.
                            </p>
                            <br></br>

                            <h4 id="entityRole">Entity Role - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#entity-role" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>An Entity Role is a label that specifies a sub-category in entities of the same type. Roles are used when identically typed entities need to be interpreted differently in different contexts. For example, In the expression “I need to book a flight from Miami to New York”, the location entity in the book_flight intent can have an origin role (Miami) and destination role (New York).
                            </p>
                            <br></br>

                            <h4 id="entityValue">Entity Value - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#entity-value" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>In the example "Can you book a flight from Delhi to New York?", the intent is "book_flight" and the entity is "location". The end customer, however, can provide different values for the "location" type entity such as Miami, Bangalore, San Jose, etc. These different values are called Entity Values. Also, each of these entity values can be provided by the user in many ways, referred to as synonyms. For Example, New York (Entity Value) can also be referred to as NYC, NY, New York. These values(NYC, NY, New York) are considered to be synonyms. These values can be generated either manually, using an API, or by uploading a file.
                            </p>
                            <br></br>

                            <h4 id="createEntityStatic">Create an Entity - Static - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#create-an-entity-static" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <ol class="text-left">
                                <li>To create an entity, first click the Create button on the top-left side of the screen.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/CreateIntent.png"} alt="Create Bot" class="help-reduce-img-size"></img>
                                <br></br>

                                <li>The below window will appear where you can enter the Entity name, description, roles, and values.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/entity-help-images/StaticEntityFields.png"} alt="Create Bot Fields" class="center"></img>
                                <br></br>

                                <li>Finally, you can click the Submit button which will submit the details and add the entity to your entity list. </li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit Intent" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>


                            <h4 id="createEntityAPI">Create an Entity - API - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#create-an-entity-api" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Below are the instructions to create an Entity using an API to generate the Entity values:</p>
                            <ol class="text-left">
                                <li>To create an entity, first click the Create button on the top-left side of the screen</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/CreateIntent.png"} alt="Create Bot" class="help-reduce-img-size"></img>
                                <br></br>
            
                                <li>The below window will appear where you can enter the Entity Name and Description. Afterwards, you can select the API tab to enter the API details that will return the entity values.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/entity-help-images/ApiEntityFields.png"} alt="API Entity Fields" class="center"></img>
                                <br></br>

                                <li>In the same window you can use the format script editor which allows you to provide Python code that will format the output of the API response. The response of the API is stored in the 'result' variable.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/entity-help-images/ApiFormatScript.png"} alt="API Entity Fields" class="center"></img>
                                <br></br>

                                <li>Finally, you can click the Submit button which will submit the details and add it to your entity list.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit Intent" class="help-reduce-img-size"></img>
                            </ol>

                            <p><b>Note:</b> The final output should be a JSON array with two key-value pairs.<br></br></p>

                            <div class="help-indent">
                                <p>i) "value": String</p>
                                <p>ii) "synonyms": Array of Strings</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + "/images/common-images/entity-help-images/FormatEntityApi.png"} alt="JSON Format" class="center"></img>
                            <br></br>
                            <br></br>

                            <h4 id="createEntityFile">Create an Entity - File Upload - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#create-an-entity-file-upload" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <ol class="text-left">
                                <li>To create an entity, first click the Create button on the top-left side of the screen.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/CreateIntent.png"} alt="Create Bot" class="help-reduce-img-size"></img>
                                <br></br>

                                <li>The below window will appear where you can enter the Entity Name and Description. After, you can drag and drop your .txt or .csv file into the designated space. When the file is dragged and dropped into the zone, the entity values will be processed and displayed in a table to esnure accuracy.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/entity-help-images/FileUploadEntityFields.png"} alt="File Upload Entity" class="center"></img>
                                <br></br>

                                <li>Finally, after reviewing and confirming the list, you can click the Submit button which will submit the details and add it to your entity list. </li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit Intent" class="help-reduce-img-size"></img>
                            </ol>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/intents":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Create Intent</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#intent">Intent</a></li>
                                    <li> • </li>
                                    <li><a href="#createIntent">Create an Intent </a></li>
                                    <li> • </li>
                                    <li><a href="#expressions">Expressions </a></li>
                                    <li> • </li>
                                    <li><a href="#createExpressionStatic">Create an Expression - Static </a></li>
                                    <li> • </li>
                                    <li><a href="#createExpressionFile">Create an Expression - File Upload </a></li>
                                    <li> • </li>
                                    <li><a href="#entityInExpression">Mark Entities in Expressions</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="intent">Intent - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#intent" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>An Intent is a mapping between what the user asks for and the required action to be taken by the bot's logic.
                            </p>
                            <p>For example, in the question, “<u>What is the weather in San Jose?</u>“, <b>Weather</b> is the Intent and, in the question, “<u>What is the RMA status for Order_Number?</u>“, <b>RMA Status</b> is the Intent. Intent is a combination of Expressions and Actions.</p>
                            <br></br>

                            <h4 id="createIntent">Create an Intent - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#create-an-intent" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <ol class="text-left">
                                <li>To create an intent in BotLite, you will first need to click the Create button on the left-hand side of the screen.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/CreateIntent.png"} alt="Create Intent" class="help-reduce-img-size"></img>
                                <br></br>

                                <li>Afterwards you will be able to fill in the Intent Name along with the Intent Description.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/IntentFields.png"} alt="Create Intent Fields" class="center"></img>
                                <br></br>

                                <li>Finally, you can click the Submit button in the above window to create the Intent.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit Intent" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <p><b>Note:</b> To delete an Intent, click the checkbox next to the intents you wish to delete. Then click the Delete button to remove the selected intents<br></br></p>
                            <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/DeleteIntent.png"} alt="Trash Bin Icon" class="help-reduce-img-size"></img>
                            <br></br>

                            <hr class="solid"></hr>

                            <h4 id="expressions">Expressions - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#expressions" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>An expression is the way a user might ask their question to a bot. "What are my open incidents?" could be an expression with an intent of viewing users open incidents. An intent can have multiple expressions. The number of expressions you add and the variety of expressions affect the accuracy score of your bot. By adding more expressions, you will allow your bot to identify and respond to a wider range of questions since each user will ask questions in different ways.
                            </p>

                            <h4 id="createExpressionStatic">Create an Expression - Static - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#create-an-expression-static" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Below are the instructions to create expressions for your selected intent by manually entering each expression:</p>
                            <ol class="text-left">
                                <li>To create an expression, you will need to first click the Create button on the right-hand side of the screen. </li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/CreateIntent.png"} alt="Create Expression" class="help-reduce-img-size"></img>
                                <br></br>

                                <li>After clicking the Create button, the below window will appear where you can enter each expression in the text box field.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/StaticExpressionFields.png"} alt="Enter Expression" class="center"></img>
                                <br></br>

                                <li>Click the + icon after typing each expression to add the expression to the list for that intent.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/AddExpression.png"} alt="Submit Expression" class="help-reduce-img-size1"></img>
                                <br></br>

                                <li>The last step is to click the Submit button to submit all of the expressions you typed.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Enter Expression" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <h4 id="createExpressionFile">Create an Expression - File Upload - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx#create-an-expression-file-upload" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <ol class="text-left">
                                <li>To create an expression, you will need to first click the Create button on the right-hand side of the screen. </li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/CreateIntent.png"} alt="Create Expression" class="help-reduce-img-size"></img>
                                <br></br>

                                <li>After clicking the Create button, the below window will appear where you can drag and drop a .txt or .csv file containing all of the expressions for your intent. When the file is added, the file will be processed and show all of the expressions contained in the file in a list.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/FileUploadExpressionFields.png"} alt="Enter Expression" class="center"></img>
                                <br></br>

                                <li>After verifying all of the expressions were added correctly, you can click the Submit button to save all of the added expressions to the selected intent.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit Expression" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <p><b>Note:</b> To delete an expression, click the checkbox next to the expressions you wish to delete. Then click the Delete button to remove the selected expressions from the intent.<br></br></p>
                            <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/DeleteIntent.png"} alt="Trash Bin Icon" class="help-reduce-img-size"></img>
                            <br></br>

                            <hr class="solid"></hr>

                            <h4 id="entityInExpression">Mark Entities in Expressions</h4>
                            <p>You can highlight and mark Entities within your expressions on the Intent page with the following steps:
                            </p>
                            <ol class="text-left">
                                <li>First highlight the word or phrase that you would like to mark as an Entity within your expression.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/Expression.png"} alt="Expression"></img>
                                <br></br>

                                <li>A window will appear below the expression where it will display the phrase you selected and allow you to choose the Entity from a dropdown menu.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/EntityHighlighting.png"} alt="Highlighting Entity"></img>
                                <br></br>

                                <li>After you have selected the Entity, you can click the Save button in the bottom right of the window.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SaveEntity.png"} alt="Save Entity"></img>
                                <br></br>

                                <li>Afterwards, the Entity you selected will be highlighted as displayed below.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/ConfirmEntity.png"} alt="Submit Intent" ></img>
                            </ol>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Intents-%26-Entities.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/dialog":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Dialog</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#dialogue_view">Dialog</a></li>
                                    <li> • </li>
                                    <li><a href="#dialogue_nodes">Dialog Nodes</a></li>
                                    <li> • </li>
                                    <li><a href="#rule_node">Rule Node </a></li>
                                    <li> • </li>
                                    <li><a href="#action_node">Action Node</a></li>
                                    <li> • </li>
                                    <li><a href="#condition_node">Condition Node</a></li>
                                    <li> • </li>
                                    <li><a href="#jump_to_node">Jump To Node</a></li>
                                    <li> • </li>
                                    <li><a href="#clear_context_node">Clear Context Node</a></li>
                                    <li> • </li>
                                    <li><a href="#response_node">Response Node</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="dialogue_view">Dialog - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx#dialog" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>The dialog page allows you to create the bot responses and actions whenever an intent is recognized by the bot. Dialog uses a graphical way to see your bot's conversation flows allowing for the creation of complex automation to be done easily. Dialog splits each part of a rule into nodes.
                            </p>
                            <br></br>

                            <h4 id="dialogue_nodes">Dialog Nodes - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx#dialog-nodes" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Nodes are the blocks that can be dragged and dropped into the canvas and be connected together to form the conversation flows for your bot. Use these nodes to visualize how a user would progress through your bot's conversation flow step by step.
                            </p>
                            <br></br>

                            <hr class="solid"></hr>

                            <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Nodes/RuleNode.png"} alt="Create Rule" class="help-reduce-img-size"></img>
                            <h4 id="rule_node">Rule Node - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx#rule-node" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>A rule node has a set of conditions, which when evaluated to true, will continue through the rest of the conversation flow. In a rule node is where you select the intent and entity (if needed) that the bot should detect in the user's question before continuing. You can also configure slots that will prompt the user for more information such as missing entities if the user doesn't provide them in the first question. If the user does provide all needed information within the first question, these slots will be skipped.
                            </p>
                            <br></br>

                            <h5 id="create_rule">Create a Rule</h5>
                            <p>Follow these steps to create a rule in Dialog:
                            </p>
                            <ol class="text-left">
                                <li>After selecting the skill you would like to create the rule in, click the Select a Rule drop down on the left-hand side of your screen. Next click the Create Rule option to begin creating your rule for that particular skill.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Rule/CreateRule.png"} alt="Create Rule" class="center"></img>
                                <br></br>

                                <li>The following window will appear in the Dialog View canvas where you can enter the Rule Name, Rule Description, and any relevant expressions for that rule.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Rule/CreateRuleFields.png"} alt="Create Rule Fields" class="center"></img>
                                <br></br>

                                <li>After entering all of those details, click the Submit button to create the rule and have it added to the Dialog View canvas.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit Rule" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <h5 id="edit_rule">Edit a Rule</h5>
                            <ol class="text-left">
                                <li>You can edit a rule by clicking the rule node. A sidebar will appear where you can edit the rule details such as Name, Description, or make the rule <b>targeted-only</b>. By making a rule targeted-only, the rule can not be triggered by an intent but instead by having another rule jump to the selected rule by using a jump to node. This is useful if you would like to chain multiple rules together that need to be executed after or are dependent on a previous rule.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Rule/RuleDetails.png"} alt="Rule details" class="center"></img>
                                <br></br>

                                <li>After clicking Next, you will proceed to configuring the condition to trigger where you can select the intent and entity (optional) that are needed to be detected by the bot before executing this rule.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Rule/ConditionToTrigger.png"} alt="Condition to Trigger" class="center"></img>
                                <br></br>

                                <li>Next, you can configure any slots (optional) that are necessary for this rule to proceed. Configuring slots will be covered in the next section in more detail.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Rule/NoSlots.png"} alt="Rule with no Slots" class="center"></img>
                                <br></br>

                                <li>After you have finished editing the rule, you can click the Finish button to submit the changes.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Rule/Finish.png"} alt="Finish" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <hr class="solid"></hr>

                            <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Nodes/ActionNode.png"} alt="Action Node" class="help-reduce-img-size"></img>
                            <h4 id="action_node">Action Node - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx#action-node" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Actions nodes are executed by the bot based on rule conditions. Actions can be of two types. An API call or a Database Query. In the example of “What are my open incidents?”, the bot should be able to perform an API action of executing a get request and reply with list of open incidents.
                            </p>
                            <p><b>Note:</b></p>
                            <div class="help-tab">
                                <ul>
                                    <li>All the variables captured (given in the variables section) can be used by using &#123;variableName&#125; notation in the actions. For example: &#123;loggedInUserName&#125; , &#123;var_e_location&#125; and so on. </li>
                                    <li>Example: "Hey &#123;loggedInUserName&#125;, do you want to know the Covid-19 live status in &#123;var_e_city&#125;?" The variables within the curly braces will be replaced with the actual values given by the user.</li>
                                </ul>
                                <br></br>
                            </div>

                            <h5 id="create_action">Create an Action</h5>
                            <p>To create an action node, you will need to drag and drop the action node from top left-hand side of the canvas. After, follow the below steps to finish creating an action based on the action type you would like to implement:
                            </p>
                            <h5 id="create_action_api">API</h5>
                            <ol class="text-left">
                                <li>If this action requires a new API connection, click the Add / Delete API slider to create a new API connection. If the connection has already been created in BotLite, simply select the connection from the connections drop down.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Action/ApiSlider.png"} alt="action api slider" class="help-reduce-img-size2"></img>
                                <br></br>

                                <li>The below fields will appear where you can enter all of the API details including the Connection Name and whether the API connection is Internal or External to Cisco so that the API can be saved and used in future actions.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Action/ApiNewConnection.png"} alt="action api new connection" class="center"></img>
                                <br></br>

                                <li>After entering all of the API details, click the Add API button to save the API details and finish configuring the action.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Action/AddApi.png"} alt="action add api" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <h5 id="create_action_code">Code</h5>
                            <ol class="text-left">
                                <li>Enter the Python code into the code editor below that you wish to be executed when this action is triggered by a user.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Action/Code.png"} alt="action code" class="center"></img>
                                <br></br>
                            </ol>
                            <br></br>

                            <h5 id="create_action_database">Database</h5>
                            <ol class="text-left">
                                <li>If this action requires a new database connection, click the Add / Delete Database slider to create a new database connection. If the connection has already been created in BotLite, simply select the connection from the connections drop down.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Action/DatabaseSlider.png"} alt="action databse slider" class="help-reduce-img-size2"></img>
                                <br></br>

                                <li>In the below fields you can enter all of the required database connection details and save the connection under the Connection Name so that the database connection can be used in a future action.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Action/DatabaseNewConnection.png"} alt="action database connection" class="center"></img>
                                <br></br>

                                <li>After entering the database connection details, click the Add Database button to save the conncetion.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Action/AddDatabase.png"} alt="action add database" class="help-reduce-img-size"></img>
                                <br></br>

                                <li>After saving the connection details, you can enter the relevant query for the database action that you are configuring.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Action/DatabaseQuery.png"} alt="action database query" class="center"></img>
                            </ol>
                            <br></br>

                            <hr class="solid"></hr>

                            <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Nodes/ConditionNode.png"} alt="Condition Node" class="help-reduce-img-size"></img>
                            <h4 id="condition_node">Condition Node - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx#condition-node" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Condition nodes allow you to enter which entities or variables need to be detected before the conversation flow continues. You can set multiple conditions and group conditions together to be met before the flow progresses.
                            </p>
                            <br></br>
                            <p>There are two types of variables in BotLite, <b>Entity</b> and <b>Custom</b>. When the user starts conversing with the bot, all the information is stored in form of variables. This information is used to execute desired actions.
                            </p>
                            <h7><b>Variable Types:</b></h7>
                            <div class="help-tab">
                                <ol>
                                    <li><b>Entity Variables</b></li>
                                    <div class="help-indent">
                                        <p>a. Entity type will have all the variables which are captured in form of entities that is, those which start with <b>var_e_</b> (captured through slots) and <b>var_i_</b> (identified by the bot).</p>
                                        <p>b. All the possible combinations which a user can give in form of entity will be shown under variables starting with <b>var_i_</b>.</p>
                                    </div>
                                    <li><b>Context Variables</b></li>
                                    <div class="help-indent">
                                        <p>a. All the variables which are of custom_type that is those which start with <b>var_c_.</b> </p>
                                        <p>b. You can also match the intent and domain to a value. They can be accessed using <b>var_req_intent</b> and <b>var_req_domain</b>.</p>
                                    </div>
                                </ol>
                                <br></br>
                            </div>
                            <h5 id="create_condition">Create Condition</h5>
                            <p>After dragging and dropping a condition node from the top left of the screen into the canvas, follow the below steps to configure the condition node:
                            </p>
                            <ol class="text-left">
                                <li>Click on the condition node in the canvas to open the side bar to edit the condition details. Click the Add Condition button to begin adding the necessary requirements that need to be met before proceeding through the rule. </li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Condition/AddCondition.png"} alt="condition choice" style={{width:"18%"}}></img>
                                <br></br>

                                <li>In the first field of the condition, select the entity or custom variable that is required.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Condition/SelectEntity.png"} alt="condition select entity" class="center-reduce"></img>
                                <br></br>

                                <li>Next, select the condtion operator that the entity or custom variable needs to equate to in order for the rule to progress.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Condition/SelectOperator.png"} alt="condition select operator" class="center-reduce"></img>
                                <br></br>

                                <li>Lastly, enter the desired value for that condition.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Condition/SelectValue.png"} alt="condition select value" class="center"></img>
                            </ol>
                            <br></br>
                            <h5 id="connecting_conditions">Connecting Multiple Conditions</h5>
                            <p>If your rule requires multiple conditions to be met, you can add multiple conditions and/or create a group that can check for further conditions. This will allow for simple to complex logic be implemented within a condition node easily.
                            </p>

                            <hr class="solid"></hr>

                            <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Nodes/JumpToNode.png"} alt="Jump To Node" class="help-reduce-img-size"></img>
                            <h4 id="jump_to_node">Jump To Node - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx#jump-to-node" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Jump to nodes chain one rule to another rule to help with complex automation or response flows. These nodes are implemented at the end of the conversation flow to then transition to the next rule seamlessly. These nodes are helpful if you the process you are implementing requires multiple steps that can't be completed in a singular rule.
                            </p>
                            <br></br>

                            <hr class="solid"></hr>

                            <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Nodes/ClearContextNode.png"} alt="Clear Context Node" class="help-reduce-img-size"></img>
                            <h4 id="clear_context_node">Clear Context Node - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx#clear-context-node" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>A clear context node resets all or a custom list of variables so that the interaction with the user can start again without previous data. This is used to prevent the bot from using the previous context the next time the user triggers the rule. Custom variables and entity values can be cleared.
                            </p>
                            <br></br>
                            <h5 id="clear_context_variables">Clear Context Variables</h5>
                            <ol class="text-left">
                                <li>After clicking the Clear Context node to open the edit window, you can choose either clearing All or only certain Custom variables.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/ClearContext/ClearContextChoice.png"} alt="clear context choice" class="help-reduce-img-size2"></img>
                                <br></br>

                                <li>If you are wanting to clear a specific custom variable during an interaction, enter the custom variable into the input field at the top of the window and click the + icon to the right to enter the variable into the list below.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/ClearContext/CustomVariable.png"} alt="clear context custom variable" class="center"></img>
                                <br></br>

                                <li>After entering all of the relevant custom variables, select the variables that you would like to clear from the list below. Click the check box next to the relevant variables to select the variable.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/ClearContext/EntityList.png"} alt="clear entity list" class="center"></img>
                                <br></br>

                                <li>After selecting all of the variables that need to be cleared, click the Submit button to finish configuring the clear context node.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="clear Submit" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>

                            <hr class="solid"></hr>

                            <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Nodes/ResponseNode.png"} alt="Response Node" class="help-reduce-img-size"></img>
                            <h4 id="response_node">Response Node - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx#response-node" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Response nodes are used to provide static text messages, Adaptive Cards, or file attachments to users. The text messages and Adaptive Cards can use context variables within the message to have dynamic information. A format script can be added before the response if any computation is needed to happen before the response is processed.
                            </p>
                            <p><b>Note:</b> To delete an Intent, click the trash bin icon next to the Intent that you wish to be deleted.</p>
                            <div class="help-tab">
                                <ul>
                                    <li>All the variables captured (given in the variables section) can be used by using &#123;variableName&#125; notation in the actions. For example: &#123;loggedInUserName&#125; , &#123;var_e_location&#125; and so on. </li>
                                    <li>Example: "Hey &#123;loggedInUserName&#125;, do you want to know the Covid-19 live status in &#123;var_e_city&#125;?" The variables within the curly braces will be replaced with the actual values given by the user.</li>
                                </ul>
                                <br></br>
                            </div>
                            <h5 id="static_message">Static Message</h5>
                            <p>A static message is a text based message that can be formatted with Markdown and also include variables. This response type is useful for providing information to users such as links, resources, or points of contacts. Follow the step below to create your static message response:
                            </p>
                            <ol class="text-left">
                                <li>After selecting the Static Message button in the response sidebar, enter the message that you would like to send to the user. You can add multiple message options if you would like to have the bot respond with multiple variations of the message to make the interaction seem human-like.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Response/DisplayMessage.png"} alt="Display Message" class="center"></img>
                            </ol>
                            <br></br>
                            <h5 id="adaptive_card">Adaptive Card</h5>
                            <p>Adapative cards add interactivity to Webex messages through the use of graphical cards. Adaptive cards are useful for providing a graphical menu to users, send notifications such as surveys to users, and condensing lots of information into a single graphics based response. To learn more about cards and how to configure them, users can ping the bot 'CardSchool' in Webex teams. This is an open-source Webex Teams Bot that helps users and developers become familiar with Webex Teams Buttons and Cards. To design and create your own Adaptive card for your bot, <a href="https://developer.webex.com/buttons-and-cards-designer" target="_blank" rel="noopener noreferrer">click here</a>. Follow the below steps to create an adaptive card response:
                            </p>
                            <ol class="text-left">
                                <li>After creating your adaptive card in the <a href="https://developer.webex.com/buttons-and-cards-designer" target="_blank" rel="noopener noreferrer">card editor</a>, copy and paste the card payload into the code editor of the response node.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Response/WebexCardPayload.png"} alt="Display Card" class="center"></img>
                                <br></br>

                                <li>Click the Show Preview button to view what the card will look like when outputted to a user in Webex.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/dialog-help-images/Response/ShowPreview.png"} alt="Display Card" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>
                            <h5 id="file_attachment">File Attachment</h5>
                            <p>File Attachment responses are used for providing .csv, .txt, or .json files to a response containing any relevant information that will answer the user's query. These file attachments will be sent to the user through Webex messages as a downloadable file that the user can preview in Webex or download and view in their choice of software.
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Dialogue-View.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)
            case "/faq":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>FAQ Bots</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#faq_bot">FAQ bot</a></li>
                                    <li> • </li>
                                    <li><a href="#faq_feature">When to use FAQ</a></li>
                                    <li> • </li>
                                    <li><a href="#faq_nlp">FAQ and NLP Models</a></li>
                                    <li> • </li>
                                    <li><a href="#faq_files">FAQ Files</a></li>
                                    <li> • </li>
                                    <li><a href="#configure_faq">Configure a FAQ Bot</a></li>
                                </ul>
                            </div>
                            <br></br>

                            <h4 id="faq_bot">What is a FAQ bot? - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/FAQ-Bot.aspx#what-is-a-faq-bot" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>An FAQ bot is a chatbot designed to answer Frequently Asked Questions (FAQs) from users. It is programmed to understand and respond to a set of pre-determined questions and provide accurate answers based on the information fed to the bot. Typically used to handle common inquiries from users in a fast and efficient manner without the human intervention.
                            </p>
                            <br></br>

                            <h4 id="faq_feature">When to use FAQ feature? - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/FAQ-Bot.aspx#when-to-use-faq-feature" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>When dealing with bots that have a vast vocabulary, it can be challenging to configure intent for every question and add various expressions under each of them to train the bot. In such cases, the FAQ (Frequently Asked Questions) feature can be employed to create a bot easily.
                            </p>

                            <p>Additionally, if the use case is straightforward and does not require any API or database calls, it is quicker and simpler to use the FAQ feature to build a bot that provides answers to a predetermined set of questions, rather than creating intents and configuring actions for each one.
                            </p>
                            <br></br>

                            <h4 id="faq_nlp">Can FAQ and NLP classifier be used at same time in BotLite? - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/FAQ-Bot.aspx#can-faq-and-nlp-classifier-be-used-at-same-time-in-botlite" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Yes, both FAQ and NLP classifier can be used simultaneously in BotLite. In this scenario, the backend engine will attempt to provide the most suitable response for the given query using both FAQ corpus and NLP training data.
                            </p>
                            <br></br>

                            <h4 id="faq_files">What type of files can be uploaded? - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/FAQ-Bot.aspx#what-type-of-files-can-be-uploaded" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Formatted data can be uploaded in following file formats:
                            </p>
                            <ul class="text-left">
                                <li><b>CSV:</b> The file should consist of two columns. First column should contain questions, and the second column should have the corresponding answers as shown below. Each row in the file should contain a question-and-answer pair in a comma separated fashion. If the question and answer contain comma in it, please enclose the data in double quotes</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/faq-help-images/CsvFile.png"} alt="CSV file example" class=""></img>
                                <br></br>

                                <li><b>JSON:</b> Sample formatted question and answer in CSV format.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/faq-help-images/JsonFile.png"} alt="JSON file example" class=""></img>
                                <br></br>

                                <li><b>DOCX:</b> Each question-and-answer pair must be kept adjacent to each other in the file. The question should be prefixed with <b>Q:/q:</b> and the answer should be prefixed with <b>A:/a:</b> . These special annotations should not be used within the question and answer itself.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/faq-help-images/DocxFile.png"} alt="DOCX file example" class="center"></img>
                            </ul>
                            <br></br>

                            <h4 id="configure_faq">Configure a FAQ Bot - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/FAQ-Bot.aspx#configure-a-faq-bot" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>To begin configuring your bot to use the FAQ capabilities, follow the below steps:
                            </p>
                            <ol class="text-left">
                                <li>To format raw data into question and answer, click on the <b>"Upload Data"</b> button on the FAQ page. Select <b>“Formatted”</b> or <b>"Unformatted"</b> option in the pop-up. Next upload <b>“Upload”</b> button to submit the file to be processed. Our machine learning algorithms will automatically take care of generating questions and answers from the given raw data. Note that this process may take a few seconds depending on the size of the input file.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/faq-help-images/UploadDataFields.png"} alt="clear context choice" class="center"></img>
                                <br></br>

                                <li>The file is then formatted into question-and-answer pairs and displayed in the table. Users can edit the question-and-answer pair to make corrections by clicking on the edit icon on the right side of the table. If a question-and-answer pair is not needed, it can be deleted. </li>
                                <br></br>

                                <li>After entering all of the relevant custom variables, select the variables that you would like to clear from the list below. Click the check box next to the relevant variables to select the variable.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/faq-help-images/TrainBot.png"} alt="Train Bot" class="help-reduce-img-size"></img>
                            </ol>
                            <br></br>
                            <hr class="solid"></hr>
                            <br></br>

                            <h3 id="llm_faq">LLM FAQs - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/LLM-FAQs.aspx" target="_blank" rel="noopener noreferrer">SharePoint</a></h3>
                            
                            <br></br>

                            <h4 id="cost">Cost</h4>
                            <p>LLMs can be resource intensive and costly due to all that is involved in creating, scaling and maintaining these capabilities. We will be using a very generous cost model for everyone that would like to partner with us to utilize the LLM capabilities in BotLite. Details coming soon.
                            </p>
                            <br></br>
                            
                            <h4 id="cost">Data Privacy</h4>
                            <p>We have put great importance on ensuring our LLM features adhere to Cisco's principles in data privacy. When a file is uploaded to BotLite, we only allow the bot owner to view the files that have been uploaded. If the bot owner decides to delete a file, all traces of the file are deleted from our systems. Only the logs of user questions and answers will be maintained in our database.
                            </p>
                            <br></br>

                            <h4 id="cost">Responsible Artificial Intelligence</h4>
                            <p>All of our LLM models and implementations follow Cisco's <a href="https://cisco.sharepoint.com/sites/AIML" target="_blank" rel="noopener noreferrer">Responsible Artificial Intelligence</a> (RAI) policy. As a part of these policies, when the LLM capabilities have been enabled for a bot owner, they will have to agree to a terms of usage agreement. Along with this, we use a Personal Identifiable Information (PII) filter that scans through a document when it is uploaded and highlights any possible PII. The bot owner will be shown each instance of PII, and they will be able to decide whether they keep or delete the data in the uploaded document.
                            </p>
                            <br></br>

                            <h4 id="cost">LLM Models</h4>
                            <p>In BotLite, we currently are using an approved open-source LLM model. We plan to add Cisco's Enterprise OpenAI model so that bot owners can decide which model to utilize for their set of data. As more models are approved, we will continue to add them to our framework to give bot owners a wider range of models to select from based on their use case and the performance.
                            </p>
                            <br></br>

                            <h4 id="cost">Files and Handling</h4>
                            <p>When a document is uploaded, we do not do any preprocessing to remove any words or objects from the documents. Words or objects may be removed at the bot owner's discretion after the PII filter scans through the document. Currently the max file size that can be uploaded is 100MB. We store all formatted files inside of our Mongo Database and all unformatted files inside of a Vector Database. 
                            </p>
                            <br></br>

                            <h4 id="cost">Training</h4>
                            <p>After uploading all of the relevant documents and training the bot, you can test the LLM model in the Training page. By clicking the Test tab on the Training page, you can ask questions to the bot to check how the LLM model is performing along with which model (LLM or intent) is used for certain questions. If the inputted question has similar intent and LLM similarity scores, the bot will pick the answer with the highest similarity score.
                            </p>
                            <br></br>

                            <h4 id="cost">Flow</h4>
                            <p>Currently our LLM capabilities follow a 4-step flow from when the user inputs their question to when they get a response:
                            </p>
                            <ol>
                                <li>First a semantics check is performed.</li>
                                <li>This semantics check will retrieve the top 5 documents based on similarity to the inputted question.</li>
                                <li>If the first file is formatted, only the first file's context will be used. If the first file is unformatted, all 5 files will be used for context.</li>
                                <li>Next the selected files will be fed into the LLM to create an answer.</li>
                            </ol>
                            <br></br>

                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/FAQ-Bot.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/training":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Train Your Bot</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#training">Training</a></li>
                                    <li> • </li>
                                    <li><a href="#trainBot">Train Your Bot</a></li>
                                    <li> • </li>
                                    <li><a href="#scheduleTraining">Schedule Training</a></li>
                                    <li> • </li>
                                    <li><a href="#validateTraining">Validate Training </a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="training">Training - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Training.aspx#training" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Training the bot is an important step that makes the bot ready to identify the intent and entities from the user questions. The NLP (Natural Language Processing) classifier which is available in the backend, creates the files that can clearly identify intent, entities, and roles tagged from the expressions.
                            </p>

                            <h4 id="trainBot">Train Your Bot - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Training.aspx#train-your-bot" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>After selecting the Skill that you would like to train, follow these steps to train your bot:
                            </p>
                            <ol class="text-left">
                                <li>The first step in training your bot will be to select the Classifiers you would like to use for your model. To learn more about each Classifier, refer to this link for more help: <a href="https://www.mindmeld.com/docs/userguide/domain_classifier.html">https://www.mindmeld.com/docs/userguide/domain_classifier.html</a></li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/AdvanceSetting.png"} alt="Advance Settings" class="center"></img>
                                <br></br>

                                <li>After selecting each Classifier, click the Apply button to train your bot. Training can take a few minutes to complete.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/ApplyTraining.png"} alt="Apply Training"></img>
                                <br></br>

                                <li>When the training is complete, the model will appear in the list below with the corresponding Model ID, Trained By, Created At, and Accuracy fields.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/TrainingModels.png"} alt="List of Training Models"></img>
                            </ol>
                            <br></br>

                            <p><b>Note:</b> To delete an Expression, click the trash bin icon next to the Expression that you wish to be deleted.<br></br></p>
                            <img src={process.env.PUBLIC_URL + "/images/common-images/TrashBinIcon.png"} alt="Trash Bin Icon" class="help-reduce-img-size1"></img>
                            <br></br>

                            <hr class="solid"></hr>

                            <h4 id="scheduleTraining">Schedule Training - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Training.aspx#schedule-training" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Scheduling training for your bot will set a regular interval that the bot will be trained at each time.
                            </p>
                            <ol class="text-left">
                                <li>To schedule the training interval, select the Time Duration and Frequency that you would like the bot to be trained at.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/ScheduleTraining.png"} alt="Schedule Training" class="center"></img>
                                <br></br>

                                <li>Next, click the Schedule button to apply the setting and start the interval of training.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/SubmitSchedule.png"} alt="Schedule Training Button"></img>
                                <br></br>
                            </ol>
                            <br></br>

                            <p><b>Note:</b> In order to stop the training interval, you will need to click the Stop Schedule button.<br></br></p>
                            <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/StopSchedule.png"} alt="Stop Schedule Button"></img>
                            <br></br>

                            <hr class="solid"></hr>

                            <h4 id="validateTraining">Validate Training - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Training.aspx#validate-training" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>To ensure that your training model is correctly identifying questions correctly, you can validate your model by clicking the Validation tab on the training page. Follow these instructions to test your model:
                            </p>
                            <ol class="text-left">
                                <li>To enter expressions to test your model, you will need to click the Upload button on the upper right-hand side of the screen.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/UploadValidate.png"} alt="Upload Training"></img>
                                <br></br>

                                <li>A window will appear allowing you to either manually enter the expressions you would like to test or upload a file containing all of the expressions.</li>
                                <div class="help-indent">
                                    <p>a) <b>Manual</b>: To manually enter each expression, you will need to type the expression in the text box then click the + button to add it to the list of expressions.</p>
                                </div>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/PlaceholderManualValidate.png"} alt="Manually Validate" class="center"></img>
                                <br></br>

                                <div class="help-indent">
                                    <p>b) <b>File Upload</b>:  To upload a file of expressions, click the Validation tab to drag and drop the file into the window. The file will be processed and automatically add each expression to the validation list.</p>
                                </div>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/PlaceholderUploadValidate.png"} alt="File Upload Validate" class="center"></img>
                                <br></br>

                                <li>After adding all of your expressions, you can view the validation list where each expression is list along with the predicted skill/intent, expected skill/intent, and the accuracy.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/ValidateTraining.png"} alt="Validate Training"></img>
                                <br></br>

                                <li>If the predicted skill and intent are incorrect, you can click the skill or intent dropdown and set the value to the expected attribute.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/training-help-images/ValidationOfIntent.png"} alt="Validation of Training" class="center-reduce"></img>
                                <br></br>
                            </ol>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Training.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/integration":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Integrations</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#integration">Integration</a></li>
                                    <li> • </li>
                                    <li><a href="#integrate_bot">Integrate Your Bot</a></li>
                                    <li> • </li>
                                    <li><a href="#integration_webex">Integration - Webex</a></li>
                                    <li> • </li>
                                    <li><a href="#integration_widget_code">Integration - Widget Code</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="integration">Integration - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Integration.aspx#integration" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Configuring integrations in BotLite allows for the bot that you created to communicate with users through different platforms and services. There are also options to integrate your bot into any website by generating code for your widget.
                            </p>
                            <br></br>

                            <h4 id="integrate_bot">Integrate Your Bot - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Integration.aspx#integrate-your-bot" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>After selecting the Bot that you would like to integrate, you will see multiple options for integrations. Select the option that best suites your bot's use case. Below are steps to integrate your bot with a few of the options in BotLite:
                            </p>
                            <br></br>

                            <h4 id="integration_webex">Integration - Webex - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Integration.aspx#integration-webex" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>A rule node has a set of conditions, which when evaluated to true, will continue through the rest of the conversation flow. In a rule node is where you select the intent and entity (if needed) that the bot should detect in the user's question before continuing. You can also configure slots that will prompt the user for more information such as missing entities if the user doesn't provide them in the first question. If the user does provide all needed information within the first question, these slots will be skipped.
                            </p>
                            <ol class="text-left">
                                <li>To integrate your bot with Webex, click the connect button inside the Webex option.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/integration-help-images/Connect.png"} alt="Connect"></img>
                                <br></br>
                                <li>A windows will appear where you can enter all of the Webex details needed in order to connect your BotLite bot to Webex. </li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/integration-help-images/WebexIntegrationFields.png"} alt="Webex Integration Fields" class="center"></img>
                                <br></br>
                                <li>After entering all of the fields in the Webex Integration windows, click the Create Integration button to fully integrate your BotLite bot with Webex. You can now begin using the bot in Webex.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/integration-help-images/WebexCreateIntegration.png"} alt="Webex Create Integration"></img>
                                <br></br>
                            </ol>
                            <br></br>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Integration.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/skillLibrary":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Skill Library</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#skill_library">Skill Library</a></li>
                                    <li> • </li>
                                    <li><a href="#copy_skill">Copy Skill</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="skill_library">Skill Library - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skill-Library.aspx#skill-library" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>The Skill Library is a place where users can upload their bot's Skills for other users to utilize or to even copy a Skill that another user has created. This will reduce the duplication of work between bots and allow for more collaboration between users. When uploading a Skill to the Skill Library, you will have two options for the privacy level of the Skill. The first option is a <b>Clone</b> copy of a Skill which allows users to fully copy the Skill and change any part of the Skill. The second option is a <b>Read-only</b> copy of a Skill that only allows users to use the Skill without being able to make any changes.
                            </p>
                            <br></br>

                            <h4 id="copy_skill">Copy Skill - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Skill-Library.aspx#copy-skill" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>Here are the steps to copy a skill from the Skill Library:
                            </p>
                            <ol class="text-left">
                                <li>First, click the Copy button in the skill that you would like to create a copy of.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/library-help-images/Copy.png"} alt="Copy" class="help-reduce-img-size1"></img>
                                <br></br>
                                <li>The following window will appear where you enter a new name and description for how you will use the copied skill for your own use case. </li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/library-help-images/CopySkillWindow.png"} alt="clone fields" class="center"></img>
                                <br></br>
                                <li>After updating the fields in the window above, click the Submit button to copy the skill that you chose.</li>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit" class="help-reduce-img-size"></img>
                            </ol>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Integration.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/notification":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Send Notifications</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#notification">Notification</a></li>
                                    <li> • </li>
                                    <li><a href="#send_a_notification">Send a Notification</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="notification">Notification - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Notifications.aspx#notification" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>This feature enables users to send Webex messages to large audiences. The message can be in simple text or text message with markdowns or an Adaptive card. The target audience can be given in form of comma separated list of CEC(s) or comma separated list of Cisco mailer Aliases or combination of both. This feature also has the option to send customized messages to users by uploading the users' details through a CSV file. The notification can be sent at a particular time to all given users or at their local time zone.
                            </p>
                            <br></br>

                            <h4 id="send_a_notification">Send a Notification - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Notifications.aspx#send-a-notification" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>To send a notification, follow these steps:
                            </p>
                            <div class="help-tab">
                                <ol>
                                    <li>The first step is to create the notification message that you would like to send to users. There are two types of notification message types that you can send through BotLite:</li>
                                    <div class="help-indent">
                                        <p>a. <b>Webex Card:</b> Copy and paste the card payload that you have created into the message field.</p>
                                        <p>b. <b>Markdown Text:</b> Type out your message using markdown to format the text.</p>
                                        <img src={process.env.PUBLIC_URL + "/images/common-images/notification-help-images/MessageType.png"} alt="message type" class="center"></img>
                                        <br></br>
                                    </div>
                                    <li>Next you will select what time and date you would like to send the notification at. There are two options for time zone selections:</li>
                                    <div class="help-indent">
                                        <p>a. <b>Local Time Zone:</b> When you select local time zone, the notification will be sent out to each user in your user list when it is the selected time in their respective time zone. BotLite will check which time zone each user inside your user list is in before sending the notification to them at the selected time.</p>
                                        <p>b. <b>Specific Time Zone:</b> Selecting specific time zone will send the notification to everyone in your user list all at once at the selected time you choose.</p>
                                        <img src={process.env.PUBLIC_URL + "/images/common-images/notification-help-images/ScheduleInfo.png"} alt="schedule information" class="center"></img>
                                        <br></br>
                                    </div>
                                    <li>Afterwards you will need to configure the user list that you would like to send the notifications to. There are two types of ways to configure the user list:</li>
                                    <div class="help-indent">
                                        <p>a. <b>Active Users:</b> Selecting active users will send the notification to all users that have already used the bot.</p>
                                        <p>b. <b>Custom Users:</b> This option gives the most versatility to how you can send notifications to users. You can upload a .csv file with all users, enter the user list manually, or give an email alias that contains all of the users.</p>
                                        <img src={process.env.PUBLIC_URL + "/images/common-images/notification-help-images/UserList.png"} alt="User List" class="center"></img>
                                        <br></br>
                                    </div>
                                    <li>The last step is to preview the message to ensure it looks correct and then submitting the notification.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/intent-help-images/SubmitIntent.png"} alt="Submit" class="help-reduce-img-size"></img>
                                </ol>
                                <br></br>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Notifications.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)

            case "/user":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>User Management</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#role_management">Role Management</a></li>
                                    <li> • </li>
                                    <li><a href="#allow_list">Allow List</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="user_management">User Management - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/User-Management.aspx#user-management" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>The User Management Screen in BotLite 3.0 is used to control which users are able to view, modify, and interact with your bot. This is an optional step that is only needed if you wish to restrict aspects of your bot. The Role Management tab allows you to modify who has access to your bot in BotLite and what they can or can't change. The Allow List tab gives control over the actions and integrations by selecting which users can utilize each specific action you have created along with who can interact with your bot through the chosen integration.
                            </p>
                            <br></br>

                            <h4 id="role_management">Role Management - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/User-Management.aspx#role-management" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <div class="help-tab">
                                <ol>
                                    <li>Enter the email of the user you would like to add access to for the selected bot. After click Add to add the email to the role management list.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/settings-help-images/UserEmail.png"} alt="user email" class="center" style={{width:"100%"}}></img>
                                    <br></br>
                                    
                                    <li>Next, select the level of permissions you would like to give the selected user from the list. Permissions can be stacked if needed.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/settings-help-images/SelectRole.png"} alt="user email" class="center" style={{width:"80%"}}></img>
                                    <br></br>

                                    <li>You will see the changes reflected in the role management list which can be updated at any time.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/settings-help-images/RoleList.png"} alt="Role List" class="center" style={{width:"80%"}}></img>
                                    <br></br>

                                </ol>
                                <br></br>
                            </div>

                            <h4 id="allow_list">Allow List - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/User-Management.aspx#allow-list" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <div class="help-tab">
                                <ol>
                                    <li>First select either Action or Integration depending on which type of access you would like to control. Afterwards, click the Add button to begin controlling access.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/settings-help-images/Add.png"} alt="Add" class="help-reduce-img-size" ></img>
                                    <br></br>
                                    
                                    <li>Next, select the Action or Integration you would like to grant access to along with the user or My ID Group that will be given access.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/settings-help-images/AddAllowList.png"} alt="user email" class="center" style={{width:"80%"}}></img>
                                    <br></br>

                                    <li>You will see the changes reflected in the role management list which can be updated at any time.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/settings-help-images/AllowListTable.png"} alt="Role List" class="center" style={{width:"80%"}}></img>
                                    <br></br>

                                </ol>
                                <br></br>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/User-Management.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)


            case "/schedule":
                return (<Modal size="xl" show={showHelpScreen} animation={false}>
                        <Modal.Header>
                            <h3>Schedule Action</h3>
                            <span className='icon-close icon-size-24' onClick={() => { setShowHelpScreen(false) }}></span>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="text-center">
                                <ul class="list list--inline">
                                    <li><a href="#schedule_an_action">Schedule an Action</a></li>
                                </ul>
                            </div>
                            <br></br>
                            <h4 id="schedule">Schedule - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Schedule-Action.aspx#schedule" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <p>The Schedule page can be used to schedule actions to be automatically executed by the bot at the set schedule. This allows for more control over when an action is executed and proactive automation by the bot. Scheduled actions can be performed within Webex spaces with multiple users or be sent individually to a set list of users depending on your use case. These schedules can be stopped at any time by returning to the page, selecting the action that is scheduled and then clicking Stop Schedule at the bottom of the page.
                            </p>
                            <br></br>

                            <h4 id="schedule_an_action">Schedule an Action - <a href="https://cisco.sharepoint.com/sites/BotLite/SitePages/Schedule-Action.aspx#schedule-an-action" target="_blank" rel="noopener noreferrer">SharePoint</a></h4>
                            <div class="help-tab">
                                <ol>
                                    <li>The first step to schedule an action is to set the desired schedule and frequency that you would like the selected action to be performed at.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/schedule-help-images/SetSchedule.png"} alt="Set Schedule" class="center" style={{width:"100%"}}></img>
                                    <br></br>
                                    
                                    <li>Next, enter the Room Id, User CECs, or MyID Group that you would like the selected action to be sent.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/schedule-help-images/ScheduleList.png"} alt="Schedule List" class="center" style={{width:"100%"}}></img>
                                    <br></br>

                                    <li>Afterwards, select the time zone that the schedule should be performed in.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/schedule-help-images/ScheduleTimeZone.png"} alt="Schedule Time Zone" class="center" style={{width:"100%"}}></img>
                                    <br></br>

                                    <li>After inputting all of the above fields, click the Start Schedule button to submit the scheduled action.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/common-images/schedule-help-images/StartSchedule.png"} alt="Start Schedule" class="help-reduce-img-size" style={{width:"18%"}}></img>
                                    <br></br>

                                </ol>
                                <br></br>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="left-sharepoint">
                                <button className='btn btn--primary'  onClick={() => {window.open('https://cisco.sharepoint.com/sites/BotLite/SitePages/Schedule-Action.aspx')}} >SharePoint</button>
                            </div>
                            <button className="btn btn--dark" onClick={() => { setShowHelpScreen(false) }}>Close</button>
                        </Modal.Footer>
                    </Modal>)
            
        }
    }

    return (
        <div className="header botlite-header">
            <div className="container-fluid" >
                <div className="header-panels">
                    <div className="header-panel">
                        <div className="base-margin-right col-1" onClick={() => { onToggleClick() }}>
                            <span className="icon-list-menu icon-size-20"></span>
                        </div>
                        <a className="header__logo" href="http://www.cisco.com" >
                            <span className="icon-cisco"></span>
                        </a>
                        <div className="header__title" >
                            <a href={botliteHomeUrl}>
                                <img src={process.env.PUBLIC_URL + "/images/common-images/BotliteLogo.svg"} className="botliteTitle" alt="BotLiteLogo" style={{ width: '155px', marginTop: '10px' }}></img>
                            </a>

                        </div>
                    </div>

                    <div class="header-panel header-panel--right">
                        {
                            isBotLiteAdmin && <div className="header-item" style={{marginRight: '10px'}}>
                                <Link to="/botlite_updates" style={{ float: 'right' }} className="text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down">
                                    <div class="flex-center-vertical">
                                        <div class="flex-center">
                                            <span class="icon-broadcast-message icon-size-24"></span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        }
                        <div className='base-margin-right'>
                            {!isButtonHidden && (
                                <button className='btn btn--small btn--primary base-margin-top  base-margin-bottom' onClick={showAlertWithButtons} >Previous Version</button>
                            )}
                            {showAlert && (
                                <div className="custom-alert">
                                <span className="close" onClick={closeAlert}>&times;</span>
                                <p>You would be redirected to Previous version. If you wish to come back to New version, please visit botlite.cisco.com.</p>
                                <button onClick={redirectTo2}>OK</button>
                                </div>
                            )}
                        </div>

                        <a class="header-item" onClick={() => { setShowHelpScreen(true) }}>
                            <span class="qtr-margin-right">Help</span>
                            <span class="icon-info-outline icon-size-20" ></span>
                        </a>
                        <div className="header-item">
                            <Link to="/approval" style={{ float: 'right' }} className="text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down">
                                <Badge badgeContent={11} color="primary" max={10}>
                                    <NotificationsIcon fontSize='large' color="action" />
                                </Badge>
                            </Link>

                        </div>
                        <a class="header-item" href='/home' onClick={() => { localStorage.removeItem("token") }}>
                            <span class="half-margin-right">Log Out</span>
                            <span class="icon-sign-out icon-size-20"></span>
                        </a>
                    </div>

                </div>
            </div >
            {helpModal(location.pathname)}
        </div >
    )
}
export default Header;