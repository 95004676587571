// module imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BotLiteHTTPInterceptor from "./interceptor";
import { useEffect } from "react";

//Components imports
import HomeScreen from "./modules/home-module/home-module/HomeScreen";
import BotScreen from "./modules/bot-module/bot-component/bot-screen-component/BotScreenComponent";
import EntityScreen from "./modules/entity-module/entity-component/entity-screen-component/EntityScreenComponent";
import IntentScreen from "./modules/intent-module/intent-component/intent-screen-component/IntentScreenComponent";
import TrainingScreen from "./modules/training-module/training-component/training-screen-component/TrainingScreenComponent";
import DialogScreen from "./modules/dialog-module/dialog-components/dialog-screen-component/DialogScreenComponent";
import AnalyticsScreen from "./modules/analytics-module/analytics-component/analytics-screen-component/AnalyticsScreenComponent";
import AnalyticsAdminScreen from "./modules/analytics-module/analytics-admin-component/analytics-admin-screen-component/AnalyticsAdminScreenComponent";
import SkillScreen from "./modules/skill-module/skill-component/skill-screen-component/SkillScreenComponent";
import SkillLibraryScreen from "./modules/skill-library-module/skill-library-component/skill-library-screen-component/SkillLibraryScreenComponent";
import IntegrationScreen from "./modules/integration-module/integration-components/integration-screen-component/IntegrationScreenComponent";
import NotificationScreen from "./modules/notification-module/notification-component/notification-screen-component/NotificationScreenComponent";
import ApprovalScreen from "./modules/approval-module/approval-component/approval-screen-component/ApprovalScreenComponent";
import FAQScreen from "./modules/faq-module/faq-component/faq-screen-component/FAQScreenComponent";
import UserManagementScreen from "./modules/user-management-module/user-management-component/user-management-screen-component/UserManagementScreenComponent";
import BotLiteUpdatesScreen from "./modules/botlite-updates-module/botlite-updates-component/botlite-updates-screen-component/BotLiteUpdatesScreenComponent";

// CSS imports
import "./cui-2.0.5/css/cui-standard.min.css";
import "./App.css";
import "./common-css/Modal.css";
import ScheduleScreen from "./modules/schedule-module/schedule-component/schedule-screen-component/ScheduleScreenComponent";

function App() {
  //Routing paths
  // For GET requests

  //call http fetch interceptor
  //BotLiteHTTPInterceptor();

  const authPingUrl = `${process.env.REACT_APP_ROOT_URL}/authPing`;
  const code_challenge_url = `${process.env.REACT_APP_ROOT_URL}/okta/getLoginRedirectURL`
  const access_token_url = `${process.env.REACT_APP_ROOT_URL}/okta/accessToken`
  var authPingRef;

  const authPing = ()=>{
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    let requestOptions = {
      method: "GET",
      headers: reqHeaders
    }
    let location = window.location;

    if(location.pathname !== "/home"){
      fetch(authPingUrl, requestOptions)
      .then(res => res.json()).then((res)=>{
        if(res["params"]["header"]["Regenerate-Access-Token"] === "true"){
          // access token is about to expire so generate a new access token
          // we have 2 mins buffer to complete this task successfully
          fetch(access_token_url).then((res)=>res.json()).then((res)=>{
            if(res["status"] !== "success"){
              // could not regenerate access token
              // redirect user to login
              clearInterval(authPingRef);
              fetch(code_challenge_url).then((res) => res.json()).then((res) => {
                localStorage.setItem("code_challenge", res["code_challenge"]);
                localStorage.removeItem("isFragmentUsed");
                window.location.href = res["login_url"];
            })
            }
          })
        }
      },err => {
        clearInterval(authPingRef);
        fetch(code_challenge_url).then((res) => res.json()).then((res) => {
          localStorage.setItem("code_challenge", res["code_challenge"]);
          localStorage.removeItem("isFragmentUsed");
          window.location.href = res["login_url"];
      })
      })
    }

  }

  authPing();
  useEffect(()=>{
    authPingRef = setInterval(authPing, 5000);
  },[])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // page is being refreshed so remove authpings
      clearInterval(authPingRef);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
  return (
    <div className="main-div">
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/" element={<BotScreen />} />
          <Route path="/skills" element={<SkillScreen />} />
          <Route path="/entity" element={<EntityScreen />} />
          <Route path="/intents" element={<IntentScreen />} />
          <Route path="/dialog" element={<DialogScreen />} />
          <Route path="/faq" element={<FAQScreen />} />
          <Route path="/training" element={<TrainingScreen />} />
          <Route path="/integration" element={<IntegrationScreen />} />
          <Route path="/skillLibrary" element={<SkillLibraryScreen />} />
          <Route path="/analytics/bot" element={<AnalyticsScreen tabValue={0} />} />
          <Route path="/analytics/skill" element={<AnalyticsScreen tabValue={2} />} />
          <Route path="/notification" element={<NotificationScreen />} />
          <Route path="/analytics/metrics" element={<AnalyticsAdminScreen />} />
          <Route path="/approval" element={<ApprovalScreen />} />
          <Route path="/user" element={<UserManagementScreen />} />
          <Route path="/schedule" element={<ScheduleScreen />} />
          <Route path="/botlite_updates" element={<BotLiteUpdatesScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
