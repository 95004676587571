const TimezoneConverter = (time)=>{
    let offset = new Date().getTimezoneOffset();
    let offsetSign = offset >= 0 ? "-" : "+";
    let offsetAbs = Math.abs(offset);
    let offsetHr = Math.floor(offsetAbs / 60);
    let offsetMin = offsetAbs - offsetHr * 60;
    let userCountryTimeZoneOffset = offsetSign.toString() +
        (offsetHr < 10 ? "0" + offsetHr.toString() : offsetHr.toString()) + ":" +
        (offsetMin < 10 ? "0" + offsetMin.toString() : offsetMin.toString());

    
    let UTC2LocalTimeConverter = (time, offset)=>{
        let numberOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let date = time.split(' ')[0].split('-');
        let year = parseInt(date[0]);
        let month = parseInt(date[1]);
        let day = parseInt(date[2]);
    
        if (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)) {
            numberOfDays[2] = 29; //leap year
        }
    
        let timeSplit = time.split(' ')[1].split(':');
        let offesetSplit;
        if (offset[0] == '+') {
            offesetSplit = offset.split('+')[1].split(':');
        } else {
            offesetSplit = offset.split('-')[1].split(':');
        }
    
        let offset_hh = parseInt(offesetSplit[0]);
        let offset_mm = parseInt(offesetSplit[1]);
    
        let hh = parseInt(timeSplit[0]);
        let mm = parseInt(timeSplit[1]);
        let ss = 0;
    
        if (offset[0] == '+') {
            mm = mm + offset_mm;
            if (mm >= 60) {
                mm = mm - 60;
                hh += 1;
            }
    
            hh += offset_hh;
            if (hh > 23) {
                hh = hh - 24;
                day += 1;
            }
    
            // check for odd/even month
            if (day > numberOfDays[month - 1]) {
                day = day - numberOfDays[month - 1];
                month += 1;
            }
            if (month > 12) {
                month += 12;
                year += 1;
            }
        } else {
            if (mm - offset_mm < 0) {
                mm = 60 - (offset_mm - mm);
                hh -= 1;
            } else {
                mm = mm - offset_mm;
            }
    
            if (hh - offset_hh < 0) {
                hh = 24 - (offset_hh - hh);
                day -= 1;
            } else {
                hh = hh - offset_hh;
            }
    
            if (day < 1) {
                month -= 1;
    
                if (month < 1) {
                    month = 12;
                    year -= 1;
                }
                day = numberOfDays[month - 1];
            }
        }
    
        let newTime =
            year.toString() +
            '-' +
            (month < 10 ? '0' + month.toString() : month.toString()) +
            '-' +
            (day < 10 ? '0' + day.toString() : day.toString()) +
            ' ' +
            (hh < 10 ? '0' + hh.toString() : hh.toString()) +
            ':' +
            (mm < 10 ? '0' + mm.toString() : mm.toString()) +
            ':' +
            (ss < 10 ? '0' + ss.toString() : ss.toString());
        return newTime;
      }


    let result =  UTC2LocalTimeConverter(time, userCountryTimeZoneOffset);
    console.log(result);
    return result;
}

export default TimezoneConverter;