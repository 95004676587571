const NOTIFICATION_BASE_URL = `${process.env.REACT_APP_ROOT_URL}/notification`

const NotificationService = {
    getActiveUsers: (botId) => {
        var requestOptions = {
            method: 'GET'
        };

        return fetch(`${NOTIFICATION_BASE_URL}/bot/${botId}/activeUsers`, requestOptions)
    },
    // message payload and scheduleType is passed in this step so that estimated time to complete can be obtained
    getUserCount: (emails, mailerGroups, csvFile, messagePayload, scheduleType) => {
        var formdata = new FormData();

        formdata.append("emails", emails);
        formdata.append("mailer_groups", mailerGroups);
        formdata.append("message", messagePayload);
        formdata.append("schedule_type", scheduleType)

        if (csvFile) {
            formdata.append("input_type", "file_upload");
            formdata.append("csv_file", csvFile);
        }
        else {
            formdata.append("input_type", "form");
        }

        formdata.append("csv_file", csvFile);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${NOTIFICATION_BASE_URL}/userCount`, requestOptions);
    },
    scheduleNotification: (botId, emails, mailerGroups, csvFile, messagePayload, scheduleType, messageType, notificationScheduleTimeStamp, parseMessageStringToMarkdown, createdBy, timeZone) => {
        var formdata = new FormData();

        formdata.append("bot_id", botId);
        formdata.append("created_by", createdBy)
        formdata.append("parse_message_string_to_markdown", parseMessageStringToMarkdown);
        formdata.append("emails", emails);
        formdata.append("mailer_groups", mailerGroups);
        formdata.append("message", messagePayload);
        formdata.append("schedule_type", scheduleType);
        formdata.append("scheduled_at", notificationScheduleTimeStamp);  //<dd/mm/yyyy <space> hh:mm:ss>
        formdata.append("schedule_time_zone", timeZone)

        if (messageType === "Webex Card") {
            //add default lines
            let card_payload = {
                "contentType": "application/vnd.microsoft.card.adaptive",
                "content": JSON.parse(messagePayload)
            }

            formdata.append("message", "Webex Adaptive card is not supported in your device");
            formdata.append("webexCard", JSON.stringify(card_payload));
        }
        else {
            formdata.append("message", messagePayload);
            formdata.append("webexCard", "");
        }

        if (csvFile) {
            formdata.append("input_type", "file_upload");
            formdata.append("csv_file", csvFile);
        }
        else {
            formdata.append("input_type", "form");
        }

        formdata.append("csv_file", csvFile);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${NOTIFICATION_BASE_URL}/bot/${botId}`, requestOptions);
    },
    validateFile: (csvFile) => {
        var formdata = new FormData();
        formdata.append("csv_file", csvFile);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${NOTIFICATION_BASE_URL}/validateFile`, requestOptions);
    },
    listNotifications: (botId) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        return fetch(`${NOTIFICATION_BASE_URL}/bot/${botId}`, requestOptions);
    },
    deleteNotification: (botId, notificationId, timeZone) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        let data = {
            "time_zone": timeZone
        }
        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };
        return fetch(`${NOTIFICATION_BASE_URL}/bot/${botId}/${notificationId}`, requestOptions);
    },
    deleteNotificationByTimeZone: (botId, notificationId, timeZone) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        let data = {
            "time_zone": timeZone
        }
        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };
        return fetch(`${NOTIFICATION_BASE_URL}/bot/${botId}/${notificationId}/timezome`, requestOptions);
    },
    updateNotification: (botId, notificationId, payload, type) => {
        var formdata = new FormData();
        formdata.append("content", payload);
        formdata.append("type", type);

        var requestOptions = {
            method: 'PUT',
            body: formdata,
            redirect: 'follow'
        };
        return fetch(`${NOTIFICATION_BASE_URL}/bot/${botId}/${notificationId}`, requestOptions);
    },
    updateNotificationSchedule:(botId, notificationId, scheduled_at)=>{
        var formdata = new FormData();
        formdata.append("scheduled_at", scheduled_at);  //<dd/mm/yyyy <space> hh:mm:ss>

        var requestOptions = {
            method: 'PUT',
            body: formdata,
            redirect: 'follow'
        };
        return fetch(`${NOTIFICATION_BASE_URL}/bot/${botId}/${notificationId}/updateScheduleTime`, requestOptions);
    },
    getTimeZone: () => {
        const tzAPIUrl = process.env.REACT_APP_ROOT_URL + "/getTimezoneList";
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        return fetch(tzAPIUrl, requestOptions);
    }
};

export default NotificationService;
