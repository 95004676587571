import { useState, useEffect } from 'react';
import './ModelTrainingComponent.css';
import TrainingService from '../../training-service/TrainingService';
import Spinner from '../../../../custom-components/spinner/Spinner';
import SplitScreenWrapper from '../../../../custom-components/split-screen-wrapper/SplitScreenWrapper';
import { dateTimeFormatChange } from "../../../../utils/utils.js"

const ModelTrainingComponent = ({ selectedBot, displayMessage = (type, message) => { } }) => {

    const [models, setModels] = useState();
    const [showAdvancedSetting, setShowAdvancedSetting] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [botId, setBotId] = useState(selectedBot.value)
    const [skillslist, setSkillslist] = useState([])
    const timeDurations = [1, 6, 12, 24];
    const frequencies = ['hourly', 'daily', 'weekly', 'monthly'];
    const mlModels = ['logistic regression', 'svm', 'decision tree'];
    const [trainFlag, setTrainFlag] = useState(selectedBot.trained);
    const [timeDuration, setTimeDuration] = useState(timeDurations[0]);
    const [frequency, setFrequency] = useState(frequencies[0]);
    const [intentClassifierModel, setIntentClassifierModel] = useState(mlModels[0]);
    const [skillClassifierModel, setSkillClassifierModel] = useState(mlModels[1]);
    const [layoutType, setLayoutType] = useState(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');
    const [skilltoggle, setSkillstoggle] = useState(false)
    const [expand, setExpand] = useState()

    useEffect(() => {
        setBotId(selectedBot.value)
        setModels()
        getModels(selectedBot.value);
        bindSkills();
    }, [selectedBot])

    const bindSkills = () => {
        if (selectedBot) {
            let temp = []
            for (let data of selectedBot.skills) {
                temp.push({
                    "skill_name": data.name,
                    "skill_id": data.id,
                    "intent_classifier": intentClassifierModel
                })
            }
            setSkillslist(temp)
        }
    }

    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    function formattedDate(dateTime) {
        return dateTimeFormatChange(dateTime)
    }

    function fetchAccurayData(accuracyData) {
        let accuracyScore = '--';
        if (accuracyData.intent_classifiers && accuracyData.intent_classifiers.length > 0) {
            let accuracyDataList = accuracyData.intent_classifiers;
            const skillCount = accuracyDataList.length;
            let accuracyPresentCount = 0;
            let totalValue = 0;

            if (skillCount > 0) {
                for (let accuracyData of accuracyDataList) {
                    if (typeof (accuracyData.accuracy) === 'number') {
                        totalValue += accuracyData.accuracy;
                        accuracyPresentCount++;
                    }
                }

                if (accuracyPresentCount > 0) {
                    let average = totalValue / accuracyPresentCount;
                    accuracyScore = Math.abs(average.toFixed(2));
                }
            }
        }
        return accuracyScore;
    }

    const getModels = (id) => {
        setDataLoading(true);
        setTrainFlag(false);
        TrainingService.getListModels(id).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setModels(res.data);
                if (res.data == undefined) {
                    setTrainFlag(true)
                }
                else if (res.data && res.data.length > 3) {
                    setTrainFlag(false)
                }
                else if (res.data && res.data.length == 0) {
                    setTrainFlag(true)
                }
                else {
                    console.log(selectedBot.trained)
                    setTrainFlag(!selectedBot.trained)
                }

                setDataLoading(false);
            } else {
                // console.log("Failed to retrieve Models");
                setDataLoading(false);
                setTrainFlag(true);
                displayMessage('error', res['details'])
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setTrainFlag(true);
            displayMessage('error', "Failed to retrieve Models")
        })
    }
    const deleteModeldata = (modelid) => {
        setDataLoading(true);
        let models = new Array()
        models.push(modelid)
        let modelData = { "models": models }
        TrainingService.deleteModel(botId, modelData).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                const del = models.filter((item) => {
                    return item.model_name != modelid
                })
                setDataLoading(false);
                displayMessage('success', "Delete model successfully")
                getModels(botId)
            } else {
                setDataLoading(false);
                displayMessage('error', "Fail to Delete the model")
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            displayMessage('error', err)
        })
    }
    //console.log("models", models);

    const handleResize = (() => {
        setLayoutType(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');

    })

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [])

    const trainbotHandler = () => {
        setDataLoading(true)
        const agentid = {
            agent_id: botId
        }
        let startTime = performance.now();
        TrainingService.trainBot(botId, agentid).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setTrainFlag(false)
                setDataLoading(false);
                displayMessage('success', res['details'])
                getModels(botId);
                setTrainFlag(false)
            } else {
                setDataLoading(false);
                
                displayMessage('error', res['details'])
            }
        }).catch((err) => {
            if(err.response){
                console.log("err response", err.response);
            }
            let totalTimeTaken = performance.now() - startTime
            totalTimeTaken = totalTimeTaken/1000;
            
            if(totalTimeTaken > 28){
                // its a gateway time out error
                displayMessage('success', 'Model is getting trained. Please refresh this page in some time to view model deatils!');
                setDataLoading(false);
                return;
            }
            console.log(err);
            setDataLoading(false);
            displayMessage('error', err)
        })
    }

    const renderModelTable = (layoutType) => {
        if (layoutType === 'verticle') {
            return <div className='panel panel--raised panel--bordered base-margin-top ' style={{ "backgroundColor": "#00bceb60" }}>
                <div style={{ "fontSize": "20px", "fontWeight": "400", "color": "#1e4471" }}>
                    Trained Models
                </div>

                {
                    models && models.length > 0 && models.map((row, index) => {
                        let trainedAtDateFormat = dateTimeFormatChange(row.created_time)
                        return (
                            <div className='panel panel--raised panel--bordered base-margin-top modelAccuracy-card-small' key={index}>
                                <div className='training-row'>
                                    <span style={{ "color": "purple", "paddingRight": "10px" }}>Model Id:</span>
                                    {row.model_name}
                                </div>
                                <hr />
                                <div className='training-row'>
                                    <span style={{ "color": "purple", "paddingRight": "10px" }}>Trained By:</span>
                                    {row.trained_by}

                                </div>
                                <hr />
                                <div className='training-row'>
                                    <span style={{ "color": "purple", "paddingRight": "10px" }}>Trained At:</span>
                                    {trainedAtDateFormat}
                                </div>
                                <hr />
                                <div className='training-row'>
                                    <span style={{ "color": "purple", "paddingRight": "10px" }}>Skill Classifier Accuracy:</span>
                                    {fetchAccurayData(row.accuracies)}
                                    <span className="icon-delete icon-size-18" style={{ "position": "absolute", "right": "15px" }}></span>
                                </div>
                                <hr />
                                <div className='training-row'>
                                    <span style={{ "color": "purple", "paddingRight": "10px" }}>Intent Classifier Accuracy:</span>
                                    {roundToTwo(row.avg_ic_acc)}
                                    <span className="icon-delete icon-size-18" style={{ "position": "absolute", "right": "15px" }}></span>
                                </div>
                            </div >
                        );
                    })
                }
            </div >
        }
        else {
            return <div className=' training-models-panel base-margin-top'>
                {
                    isDataLoading && (<Spinner></Spinner>)
                }

                <div class="table-responsive">
                    <table class="table table table--bordered table--loose" style={{ border: '1px solid #ced4da' }}>
                        <thead style={{ backgroundColor: '#64bbe3' }} >
                            <tr>
                                <th>Model ID</th>
                                <th>Trained By</th>
                                <th>Created At</th>
                                <th>Skill Classifier Accuracy</th>
                                <th>Intent Classifier Accuracy</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody style={{ position: "relative" }}>
                            {models && models.length ?
                                models.map((row, index) => (<>
                                    <tr style={{ "width": "100%" }} key={row.model_name} onClick={() => {
                                        expand == index ? setExpand() : setExpand(index);

                                    }}>
                                        <td style={{ "width": "30%" }}> {row.model_name}</td>
                                        <td style={{ "width": "20%" }}> {row.trained_by}</td>
                                        <td style={{ "width": "25%" }}> {formattedDate(row.created_time)}</td>
                                        <td style={{ "width": "10%" }}>{fetchAccurayData(row.accuracies)}</td>
                                        <td style={{ "width": "10%" }}>{roundToTwo(row.avg_ic_acc)}</td>
                                        <td style={{ "width": "5%" }}><span className="icon-delete icon-size-18 Modeldeleteicon" onClick={() => { deleteModeldata(row.model_name) }}></span></td>
                                    </tr>
                                    {expand == index && <tr className='' >
                                        <td colSpan={6} style={{ "width": "auto" }}>
                                            <div className='panel panel--bordered base-margin'>

                                                <span className='panel panel--bordered' style={{ backgroundColor: '#64bbe3' }}><b> Domain Classfication details</b></span>
                                                <table className="table table--bordered" style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Domain Classifier</th>
                                                            <th>{row.accuracies.domain_classifier_type}</th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                                <br />
                                                <span className='panel panel--bordered' style={{ backgroundColor: '#64bbe3' }}><b> Intent Classfication details</b></span>

                                                <table className="table table--bordered" style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Skill Name</th>
                                                            <th>Intent Classifier</th>
                                                            <th>Accuracy</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            row.accuracies.intent_classifiers && row.accuracies.intent_classifiers.map((data, index) => {
                                                                return <tr key={index}>
                                                                    <td style={{ "width": "30%" }}>{data.domain}</td>
                                                                    <td style={{ "width": "30%" }}>{data.intent_classifier_type}</td>
                                                                    <td style={{ "width": "20%" }}>{roundToTwo(data.accuracy)}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>


                                        </td>

                                    </tr>}
                                </>
                                )) : <tr><td><span className=''>No Models found!</span></td></tr>
                            }
                        </tbody>
                    </table>
                </div>

            </div >
        }
    }
    const ConfigBot = () => {
        console.log(botId)
        setDataLoading(true);
        let payload = {
            "agent_id": botId,
            "skillList": skillslist
        }

        //console.log("payload", payload)
        TrainingService.configBot(payload, botId).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setTrainFlag(true)
                setDataLoading(false);
                displayMessage('success', res['details'])
            } else {
                setTrainFlag(false)
                setDataLoading(false);
                displayMessage('error', res['details'])
            }
        }).catch(err => {
            console.log(err);
            setTrainFlag(false)
            setDataLoading(false);
            displayMessage('error', err)
        })
    }

    useEffect(() => {
        const addIntentClassifier = (index, value) => {
            console.log(index, value)
            let temp = skillslist
            temp[index]['intent_classifier'] = value
            setSkillslist(temp)
            console.log(temp)
        }
    }, [])

    const addIntentClassifier = (index, value) => {
        console.log(index, value)
        if (index != 'all') {
            let temp = skillslist
            temp[index]['intent_classifier'] = value
            setSkillslist(temp)
            console.log("setSkillslist", temp)
        }
        else {
            let temp = skillslist
            for (let i = 0; i < temp.length; i++) {
                temp[i]['intent_classifier'] = value
            }
            setSkillslist(temp)
        }
    }


    const renderAdvancedSetting = () => {
        return <div className={(layoutType === 'horizontal' ? 'advancedSetting-panel-horizontal' : 'advancedSetting-panel-vertical') + ' panel panel--bordered'} >
            <div className='panel panel--raised panel--bordered advancedSetting-panelHeading'>Intent Classifier Configuration</div>
            <div className='panel panel--bordered advancedSetting-panelBody'>
                {/* <div className='base-margin'>
                    <div className=''>Skill Classifier</div>
                    <div className='form-group col-sm-12 col-md-12 col-lg-12'>
                        <div className="form-group__text select" >
                            <select id="select-type-basic" onChange={(e) => { setSkillClassifierModel(e.target.value) }} disabled >
                                <option value="" disabled>Select a Classifier</option>
                                {
                                    mlModels.map((data, index) => {
                                        return (<option value={data} key={index}>{data}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <hr /> */}

                <div className=' base-margin'>
                    <div className='toggleSkills'>Apply for all skills
                        <label class="switch">
                            <input type="checkbox" onChange={() => { setSkillstoggle(!skilltoggle) }} />
                            <span class="switch__input"></span>
                        </label>
                    </div>
                    <br />
                    {skilltoggle && <> <div className=''>Intent Classifier</div>
                        <div className='form-group col-sm-12 col-md-12 col-lg-12'>
                            <div className="form-group__text select" >
                                <select id="select-type-basic" onChange={(e) => { setIntentClassifierModel(e.target.value); addIntentClassifier('all', e.target.value) }} >
                                    <option value="" disabled>Select a Classifier</option>
                                    {
                                        mlModels.map((data, index) => {
                                            return (<option value={data} key={index}>{data}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div></>}
                    {!skilltoggle &&
                        <div className='skillsLists'>
                            <table className="table table--bordered skilltable">
                                <thead className='accuracyTable-header skilltableHeader'>
                                    <tr>
                                        <td>Skills Name</td>
                                        <td>Intent Classifier</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        skillslist && skillslist.length > 0 && skillslist.map((row, index) => (
                                            <tr>
                                                <td style={{ "width": "40%" }}> {row.skill_name}</td>
                                                <td >
                                                    <div className='form-group col-sm-12 col-md-12 col-lg-12'>
                                                        <div className="form-group__text select"  >
                                                            <select id="select-type-basic" defaultValue={row.intent_classifier} onChange={(e) => { addIntentClassifier(index, e.target.value) }} >
                                                                <option value="" disabled>Select a Model</option>
                                                                {
                                                                    mlModels.map((data, index) => {
                                                                        return (<option value={data} key={index}>{data}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className=' base-margin scheduleAndAdvancePanel-button '>
                    {
                        layoutType === 'horizontal' ? (
                            <button class="btn btn--secondary" onClick={() => ConfigBot()}>Config</button>
                        ) : (
                            <button className="btn btn--secondary" style={{ "paddingRight": "46px", "paddingLeft": "46px" }} onClick={() => ConfigBot()}>
                                Config
                            </button>
                        )
                    }

                </div>
            </div>
        </div>
    }

    const renderScheduleTraining = () => {
        return <div className={(layoutType === 'horizontal' ? 'advancedSetting-panel-horizontal' : 'advancedSetting-panel-vertical') + ' panel panel--bordered'} style={{ "marginleft": "10px" }}>
            <div className='panel panel--raised panel--bordered advancedSetting-panelHeading'>Schedule Training</div>
            <div className='panel panel--bordered advancedSetting-panelBody'>
                <div className=' base-margin'>
                    <div className=''>Time Duration</div>
                    <div className='form-group col-sm-12 col-md-12 col-lg-12 '>
                        <div className="form-group__text select" value={timeDuration} >
                            <select id="select-type-basic" value={timeDuration} onChange={(e) => { setTimeDuration(e.target.value) }} >
                                <option value="" disabled>Select a time duration</option>
                                {
                                    timeDurations.map((data, index) => {
                                        return (<option value={data} key={index}>{data}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className=' base-margin'>
                    <div className=''>Frequnecy</div>
                    <div className='form-group col-sm-12 col-md-12 col-lg-12 '>
                        <div className="form-group__text select" value={frequency} >
                            <select id="select-type-basic" value={frequency} onChange={(e) => { setFrequency(e.target.value) }} >
                                <option value="" disabled>Select a frequency</option>
                                {
                                    frequencies.map((data, index) => {
                                        return (<option value={data} key={index}>{data}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                {
                    layoutType === 'horizontal' ? (
                        <div className=' base-margin scheduleAndAdvancePanel-button'>
                            <button className="btn btn--secondary">
                                Schedule
                            </button>
                            <button className="btn btn--secondary">
                                Stop Schedule
                            </button>
                        </div>
                    ) : (
                        <div className='base-margin scheduleAndAdvancePanel-button'>
                            <div className=''>
                                <button className="btn btn--secondary" style={{ "paddingRight": "40px", "paddingLeft": "40px" }}> Schedule</button>
                            </div>
                            <div className=' base-margin-top'>
                                <button className="btn btn--secondary">Stop Schedule</button>
                            </div>
                        </div>

                    )
                }

            </div>
        </div>
    }

    return (
        <div className='training-tab-wrapper '>
            {
                isDataLoading && (<Spinner></Spinner>)
            }
            <SplitScreenWrapper isSplitScreenEnabled={showAdvancedSetting} leftPanelElement={
                <div className="panel panel--raised panel--bordered base-margin" >
                    <h6 className="row">
                        <span className="col" style={{ marginTop: '0.5em' }}>Train Your Bot</span>
                        <span className="col flex flex-right">
                            <button className="btn btn--secondary" onClick={() => trainbotHandler()} disabled={!trainFlag}>Train</button>
                            <button className="btn" onClick={() => setShowAdvancedSetting(true)}>Advanced Options</button>
                        </span>
                    </h6>
                    <hr />
                    {renderModelTable(layoutType)}
                </div >}
                rightPanelElement={<div style={{ height: "calc(100% - 48px)" }}>
                    <div className='row'>
                        <h6 className="col" style={{ marginTop: '10px' }}>Advanced Options</h6>
                        <div className="col flex flex-right base-margin-right">
                            <button className="btn btn--icon btn--link btn--large " onClick={() => { setShowAdvancedSetting(false) }}><span className="icon-close"></span></button>
                        </div>
                    </div>
                    <hr />
                    <div style={{ height: "calc(100% - 68px)", overflowY: "auto" }}>
                        {renderAdvancedSetting()}
                        {/* <hr />
                        {renderScheduleTraining()} */}
                    </div>
                </div>}></SplitScreenWrapper>

        </div >
    )
}

export default ModelTrainingComponent;